import { AxiosRequestConfig } from 'axios';
import {
  ArticleCreateWithTemplateView,
  ArticleGraphView,
  ArticleListView,
  ArticleReadView,
  infinitePagination
} from '../types/types';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';

export const getArticles = async (config?: AxiosRequestConfig): Promise<ArticleListView> => {
  const { data } = await http.get<ArticleListView>(ENDPOINTS.articles, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
};

export const getArticleById = async (articleId: number, config?: AxiosRequestConfig): Promise<ArticleReadView> => {
  const { data } = await http.get<ArticleReadView>(`${ENDPOINTS.articles}/${articleId}`, {
    ...config
  });
  return data;
};

export const getArticleGraphById = async (
  articleId: number,
  config?: AxiosRequestConfig
): Promise<ArticleGraphView> => {
  const { data } = await http.get<ArticleGraphView>(`${ENDPOINTS.articleGraph}/${articleId}`, {
    ...config
  });
  return data;
};

export const cloneArticle = async (id: number, config?: AxiosRequestConfig): Promise<ArticleReadView> => {
  const { data } = await http.get<ArticleReadView>(`${ENDPOINTS.cloneArticle}/${id}`, {
    ...config
  });
  return data;
};

export const getArticleByUrl = async (url: string, config?: AxiosRequestConfig): Promise<ArticleListView> => {
  const { data } = await http.get<ArticleListView>(`${ENDPOINTS.articleByUrl}/${encodeURIComponent(url)}`, {
    ...config
  });
  return data;
};

export const createArticleByTemplate = async (
  templateId: number,
  payload: ArticleCreateWithTemplateView,
  config?: AxiosRequestConfig
): Promise<ArticleReadView> => {
  const { data } = await http.post<ArticleReadView>(`${ENDPOINTS.articleByTemplate}/${templateId}`, payload, {
    ...config
  });
  return data;
};

export const addAlternateIdToArticle = async (
  articleId: number,
  alternateId: string,
  config?: AxiosRequestConfig
): Promise<ArticleReadView> => {
  const { data } = await http.put<ArticleReadView>(`${ENDPOINTS.articles}/${articleId}/alternate-ids/${alternateId}`, {
    ...config
  });
  return data;
};

export const removeAlternateIdFromArticle = async (
  articleId: number,
  alternateId: string,
  config?: AxiosRequestConfig
): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.articles}/${articleId}/alternate-ids/${alternateId}`, {
    ...config
  });
  return status;
};
