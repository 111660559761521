import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from 'react';
import { UserReadView } from '../../types/types';
import { AxiosError, Canceler } from 'axios';
import { CancelToken, isCancel } from '../../http/http';
import { useGlobalState } from '../../context/GlobalState';
import { useNavigate } from 'react-router-dom';
import { useAppShellContext } from '../shell/AppShellContext';
import HeaderBackButton from '../common/HeaderBackButton';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { searchInUsers } from '../../utils/HelperFunctions';
import { toast } from 'react-toastify';
import { deleteUser, getUsers } from '../../services/Users';
import EditIcon from '@mui/icons-material/Edit';

type Props = {};
const Users = (props: Props) => {
  const globalState = useGlobalState();
  const appShellContext = useAppShellContext();
  const [users, setUsers] = useState<UserReadView[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<UserReadView[]>();
  const [reload, setReload] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | number>();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>();

  const removeUser = async () => {
    if (!itemToDelete) {
      toast.warn('Something went wrong! Try again later');
      return;
    }
    setLoading(true);
    try {
      const status = await deleteUser(itemToDelete);
      console.log('delete user status:', status);
      setItemToDelete(undefined);
      setReload((prev) => !prev);
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'isSuperUser',
      headerName: '',
      width: 50,

      renderCell: (params) =>
        params.value ? (
          <IconButton color='info'>
            <VerifiedUserIcon fontSize='small' />
          </IconButton>
        ) : (
          ''
        )
    },
    { field: 'email', headerName: 'e-post', minWidth: 225, flex: 1 },
    {
      field: 'actions1',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (params) => {
        return !params.row.isSuperUser ? (
          <IconButton color='error' onClick={() => setItemToDelete(params.id)}>
            <DeleteIcon />
          </IconButton>
        ) : null;
      },
      align: 'right'
    },
    {
      field: 'actions2',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (params) => {
        return !params.row.isSuperUser ? (
          <IconButton color='info' onClick={() => navigate(`${params.id}/edit`)}>
            <EditIcon />
          </IconButton>
        ) : null;
      },
      align: 'right'
    }
  ];

  useEffect(() => {
    appShellContext.setHeaderLeft(<HeaderBackButton onClick={() => navigate('/', { replace: true })} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let mounted: boolean = true;
    let cancelRequest: Canceler;
    (async () => {
      try {
        setLoading(true);
        const { records } = await getUsers({
          cancelToken: new CancelToken((executer) => (cancelRequest = executer))
        });
        if (mounted) {
          setUsers(records);
        }
      } catch (e) {
        if (!isCancel(e)) globalState.handleResponseError(e as AxiosError);
      } finally {
        if (mounted) setLoading(false);
      }
      return () => {
        if (cancelRequest) cancelRequest();
        mounted = false;
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (users && users.length > 0 && searchQuery.trim().length > 0) {
      const result = searchInUsers(users, searchQuery);
      setFilteredUsers(result);
    } else {
      setFilteredUsers(undefined);
    }
  }, [users, searchQuery]);

  return (
    <Box>
      <Tooltip title={'Add new organisation'}>
        <Fab
          color='primary'
          aria-label='add'
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          onClick={() => navigate('create')}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Box className='dataGridContainer'>
        <TextField
          id='search-input'
          label='Sök...'
          inputRef={searchInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                {searchQuery ? (
                  <IconButton
                    aria-label='clear'
                    onClick={() => {
                      setSearchQuery('');
                      searchInputRef.current && searchInputRef.current.blur();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            )
          }}
          variant='standard'
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
        <DataGrid
          rows={filteredUsers ?? users}
          columns={columns}
          rowsPerPageOptions={[]}
          disableSelectionOnClick
          loading={loading}
          isCellEditable={() => false}
          hideFooter={true}
          rowHeight={48}
        />
      </Box>
      <Dialog
        open={itemToDelete !== undefined}
        onClose={() => setItemToDelete(undefined)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Ta bort användare?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Vill ta bort användaren {itemToDelete}:{users.find((user) => user.id === itemToDelete)?.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={() => setItemToDelete(undefined)}
            disabled={loading}
          >
            Avbryt
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<DeleteIcon />}
            onClick={() => removeUser()}
            disabled={loading}
          >
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Users;
