import React, { useCallback, useEffect, useState } from 'react';
import { ClassifierReadView, GroupSummaryView, OrganisationSummaryView } from '../types/types';
import { useSearchParams } from 'react-router-dom';

type HomeCtxType = {
  filteredMainCategories: number[];
  filteredSubCategories: number[];
  filteredGroups: number[];
  classifiers: ClassifierReadView[];
  setClassifiers: React.Dispatch<React.SetStateAction<ClassifierReadView[]>>;
  resetCategoryFilters: () => void;
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  selectedOrganisation: OrganisationSummaryView | undefined;
  setSelectedOrganisation: React.Dispatch<React.SetStateAction<OrganisationSummaryView | undefined>>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  groups: GroupSummaryView[];
  setGroups: React.Dispatch<React.SetStateAction<GroupSummaryView[]>>;
  simpleIcons: Map<number, string>;
  setSimpleIcons: React.Dispatch<React.SetStateAction<Map<number, string>>>;
};

const initialContext: HomeCtxType = {
  filteredMainCategories: [],
  filteredSubCategories: [],
  filteredGroups: [],
  classifiers: [],
  setClassifiers: () => {},
  resetCategoryFilters: () => {},
  searchParams: new URLSearchParams(),
  setSearchParams: (params: URLSearchParams) => {},
  selectedOrganisation: undefined,
  setSelectedOrganisation: () => {},
  searchQuery: '',
  setSearchQuery: () => {},
  loading: false,
  setLoading: () => {},
  groups: [],
  setGroups: () => {},
  simpleIcons: new Map(),
  setSimpleIcons: () => {}
};

const homeCtx = React.createContext<HomeCtxType>(initialContext);

const HomeContext = (props: { children: any }) => {
  const [filteredMainCategories, setFilteredMainCategories] = useState<number[]>([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState<number[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<number[]>([]);
  const [classifiers, setClassifiers] = useState<ClassifierReadView[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationSummaryView>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [groups, setGroups] = useState<GroupSummaryView[]>([]);
  const [simpleIcons, setSimpleIcons] = useState<Map<number, string>>(new Map());

  useEffect(() => {
    const categoryIds = searchParams.getAll('category').map((id) => Number(id));

    setFilteredMainCategories(
      classifiers.filter((cat) => cat.type === 'CATEGORY' && categoryIds.includes(cat.id)).map((cat) => cat.id)
    );
    setFilteredSubCategories(
      classifiers.filter((cat) => cat.type === 'SUB_CATEGORY' && categoryIds.includes(cat.id)).map((cat) => cat.id)
    );
    setFilteredGroups(
      classifiers.filter((cat) => cat.type === 'GROUP' && categoryIds.includes(cat.id)).map((cat) => cat.id)
    );
  }, [searchParams, classifiers]);

  const resetCategoryFilters = useCallback(() => {
    const params = new URLSearchParams(searchParams);
    params.delete('category');
    setSearchParams(params);
    setSelectedOrganisation(undefined);
  }, [searchParams, setSearchParams]);

  return (
    <homeCtx.Provider
      value={{
        filteredMainCategories,
        filteredSubCategories,
        filteredGroups,
        classifiers,
        setClassifiers,
        resetCategoryFilters,
        searchParams,
        setSearchParams,
        selectedOrganisation,
        setSelectedOrganisation,
        searchQuery,
        setSearchQuery,
        loading,
        setLoading,
        groups,
        setGroups,
        simpleIcons,
        setSimpleIcons
      }}
    >
      {props.children}
    </homeCtx.Provider>
  );
};

export default HomeContext;
export const useHomeContext = () => React.useContext(homeCtx);
