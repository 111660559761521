import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import { ImportResponseView } from '../types/types';

export const exportOrganisations = async (config?: AxiosRequestConfig): Promise<Blob> => {
  const response = await http.get(ENDPOINTS.exportOrganisations, { ...config, responseType: 'blob' });
  return response.data;
};

export const uploadOrganisations = async (data: File, config?: AxiosRequestConfig): Promise<ImportResponseView> => {
  const formData = new FormData();
  formData.append('file', data);
  const response = await http.post(ENDPOINTS.importOrganisations, formData, {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response.data;
};
