import { Grid, IconButton, InputAdornment, List, ListItem, ListItemText, TextField, Tooltip } from '@mui/material';
import { ExternalLink } from '../../../types/types';
import PlusIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/RemoveCircle';
import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { isValidUrl } from '../../../utils/HelperFunctions';
import InfoIcon from '@mui/icons-material/Info';

const ExternalLinkListInput = (props: { externalLinks: ExternalLink[]; onChange: (links: ExternalLink[]) => void }) => {
  const [links, setLinks] = useState<ExternalLink[]>(props.externalLinks);
  const [urlToAdd, setUrlToAdd] = useState<string>('');
  const [displayTextToAdd, setDisplayTextToAdd] = useState<string>('');
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

  useEffect(() => {
    setLinks(props.externalLinks);
  }, [props.externalLinks]);

  useEffect(() => {
    if (shouldUpdate) {
      props.onChange(links);
      setShouldUpdate(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, shouldUpdate]);

  const addItem = useCallback(() => {
    setLinks([...links, { externalUrl: urlToAdd.trim(), displayText: displayTextToAdd.trim() }]);
    setUrlToAdd('');
    setDisplayTextToAdd('');
    setShouldUpdate(true);
  }, [links, urlToAdd, displayTextToAdd]);

  const removeItem = useCallback(
    (index: number) => {
      const updated = [...links];
      updated.splice(index, 1);
      setLinks(updated);
      setShouldUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [links]
  );

  const isValid = useCallback(() => {
    return urlToAdd.trim().length > 0 && displayTextToAdd.trim().length > 0 && isValidUrl(urlToAdd);
  }, [urlToAdd, displayTextToAdd]);

  return (
    <Box alignItems={'flex-start'}>
      <Grid container spacing={0}>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <TextField
            fullWidth
            label={'URL'}
            value={urlToAdd}
            onChange={(e) => setUrlToAdd(e.target.value)}
            InputProps={{
              endAdornment: (
                <Tooltip title={'Url must be valid'}>
                  <InputAdornment position='start'>
                    <InfoIcon fontSize='small' color='info' />
                  </InputAdornment>
                </Tooltip>
              )
            }}
          />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <TextField
            fullWidth
            label={'Display Text'}
            value={displayTextToAdd}
            onChange={(e) => setDisplayTextToAdd(e.target.value)}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} mt={1} alignItems={'center'}>
          <IconButton onClick={() => addItem()} disabled={!isValid()}>
            <PlusIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <List>
            {links.map((item, index) => {
              return (
                <Tooltip title={item.externalUrl} key={`${item.externalUrl}-${index}`}>
                  <ListItem
                    secondaryAction={
                      <IconButton onClick={() => removeItem(index)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText>{item.displayText}</ListItemText>
                  </ListItem>
                </Tooltip>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExternalLinkListInput;
