import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import HomeScreen from '../components/home/HomeScreen';
import RestaurantMapScreen from '../components/restaurants/RestaurantMapScreen';
import ScannerScreen from '../components/scanner/ScannerScreen';
import ItemsValidationScreen from '../components/transform/ItemsValidationScreen';
import LoginScreen from '../components/login/LoginScreen';
import AdminPanelScreen from '../components/adminPanel/AdminPanelScreen';
import { useGlobalState } from '../context/GlobalState';
import Organisations from '../components/adminPanel/Organisations';
import CreateOrganisation from '../components/adminPanel/CreateOrganisation';
import AppShellProvider from '../components/shell/AppShellContext';
import Users from '../components/adminPanel/Users';
import CreateUser from '../components/adminPanel/CreateUser';
import OrganisationTabs from '../components/adminPanel/editOrganisation/OrganisationTabs';
import QrCodeGenerator from '../components/adminPanel/QrCodeGenerator';
import CreateArticleTemplate from '../components/adminPanel/CreateArticleTemplate';
import EditArticleTemplate from '../components/adminPanel/editOrganisation/EditArticleTemplate';
import EditUser from '../components/adminPanel/editOrganisation/EditUser';
import AlternateIdScreen from '../components/transform/AlternateIdScreen';
import SettingsTabs from '../components/adminPanel/SettingsTabs';
import HomeContext from '../context/HomeContext';
import GroupMapScreen from '../components/map/GroupMapScreen';

const RequiresSysAdmin = (props: { children: any }) => {
  const globalState = useGlobalState();
  if (globalState.user && globalState.user.isSuperUser) {
    return props.children;
  }
  return <Navigate to={'/'} replace></Navigate>;
};

const RequiresAuth = (props: { children: any }) => {
  const globalState = useGlobalState();
  if (globalState.user) {
    return props.children;
  }
  return <Navigate to={'/'} replace></Navigate>;
};
const RequiresUnAuth = (props: { children: any }) => {
  const globalState = useGlobalState();
  if (globalState.user) {
    return <Navigate to={'/'} replace></Navigate>;
  }
  return props.children;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppShellProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <HomeContext>
            <HomeScreen />
          </HomeContext>
        )
      },
      {
        path: 'scanner',
        element: <ScannerScreen />
      },
      {
        path: 'scanner/transform',
        element: (
          <RequiresAuth>
            <ItemsValidationScreen />
          </RequiresAuth>
        )
      },
      {
        path: 'scanner/transform/alternate',
        element: (
          <RequiresAuth>
            <AlternateIdScreen />
          </RequiresAuth>
        )
      },
      {
        path: 'login',
        element: (
          <RequiresUnAuth>
            <LoginScreen />
          </RequiresUnAuth>
        )
      },

      {
        path: '/map/*',
        element: <RestaurantMapScreen />
      },
      {
        path: '/group/*',
        element: <GroupMapScreen />
      },
      {
        path: 'admin',
        element: (
          <RequiresSysAdmin>
            <AdminPanelScreen />
          </RequiresSysAdmin>
        ),
        children: [
          { index: true, element: <Navigate to={'/admin/organisations'} replace /> },
          { path: 'organisations', element: <Organisations /> },
          { path: 'organisations/create', element: <CreateOrganisation /> },
          { path: 'organisations/:orgId', element: <OrganisationTabs /> },
          { path: 'organisations/:orgId/article-templates/create', element: <CreateArticleTemplate /> },
          { path: 'organisations/:orgId/article-templates/:templateId', element: <EditArticleTemplate /> },
          { path: 'users', element: <Users /> },
          { path: 'users/create', element: <CreateUser /> },
          { path: 'users/:userId/edit', element: <EditUser /> },
          { path: 'qr-generator', element: <QrCodeGenerator /> },
          { path: 'system-settings', element: <SettingsTabs /> }
        ]
      }
    ]
  }
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};
export default AppRoutes;
