import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from 'react';
import { ImportResponseView, OrganisationReadView } from '../../types/types';
import { AxiosError, Canceler } from 'axios';
import { deleteOrganisation, getOrganisations } from '../../services/Organisations';
import { CancelToken, isCancel } from '../../http/http';
import { useGlobalState } from '../../context/GlobalState';
import { useNavigate } from 'react-router-dom';
import { useAppShellContext } from '../shell/AppShellContext';
import HeaderBackButton from '../common/HeaderBackButton';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { downloadBlobFile, useWindowSize } from '../../utils/HelperFunctions';
import { toast } from 'react-toastify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { exportOrganisations, uploadOrganisations } from '../../services/ImportExport';

type Props = {};
const Organisations = (props: Props) => {
  const globalState = useGlobalState();
  const appShellContext = useAppShellContext();
  const [organisations, setOrganisations] = useState<OrganisationReadView[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredOrganisations, setFilteredOrganisations] = useState<OrganisationReadView[]>();
  const [reload, setReload] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string | number>();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [exporting, setExporting] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const { isMobileDevice } = useWindowSize();
  const [selectingFile, setSelectingFile] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [uploadResult, setUploadResult] = useState<ImportResponseView | null>(null);
  const [failedRowsHref, setFailedRowsHref] = useState<string>();
  const [skippedRowsHref, setSkippedRowsHref] = useState<string>();
  const [fileName, setFileName] = useState<string>();

  const removeOrganisation = async () => {
    if (!itemToDelete) {
      toast.warn('Somethingwent wrong! Try again later');
      return;
    }
    setLoading(true);
    try {
      const status = await deleteOrganisation(itemToDelete);
      console.log('deleteOrganisation status:', status);
      setItemToDelete(undefined);
      setReload((prev) => !prev);
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Namn', minWidth: 165, flex: 1 },
    { field: 'displayName', headerName: 'Visningsnamn', minWidth: 165, flex: 1 },
    { field: 'identificationNumber', headerName: 'Organisationsnummer', minWidth: 165, flex: 1 },
    { field: 'vatNumber', headerName: 'VAT-nummer', minWidth: 165, flex: 1 },
    { field: 'streetAddress', headerName: 'Gatuadress', minWidth: 165, flex: 1 },
    { field: 'city', headerName: 'Ort', minWidth: 165, flex: 1 },
    { field: 'country', headerName: 'Land', minWidth: 165, flex: 1 },
    { field: 'postalCode', headerName: 'Postnummer', minWidth: 165, flex: 1 },
    { field: 'kommun', headerName: 'Kommun', minWidth: 165, flex: 1 },
    { field: 'region', headerName: 'Region', minWidth: 165, flex: 1 },
    { field: 'referralLink', headerName: 'Referenslänk', minWidth: 165, flex: 1 },
    { field: 'customUiColor', headerName: 'Anpassad färg', minWidth: 165, flex: 1 },
    { field: 'contactName', headerName: 'Kontaktnamn', minWidth: 165, flex: 1 },
    { field: 'contactPhoneNumber', headerName: 'Telefonnummer', minWidth: 165, flex: 1 },
    { field: 'contactEmail', headerName: 'E-postadress', minWidth: 165, flex: 1 },
    {
      field: 'keywords',
      headerName: 'Sökord/Produkter',
      minWidth: 165,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row?.keywords?.join(', ')}>
          <Typography>{params.row?.keywords?.join(', ')}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'articleTemplateCount',
      headerName: 'Antal artikelmallar',
      minWidth: 165,
      flex: 1
    },
    {
      field: 'actions1',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          color='primary'
          onClick={(e) => {
            navigate(`${params.id}`, { replace: true });
            e.stopPropagation();
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      ),
      align: 'right'
    }
  ];

  const downloadOrganisations = async () => {
    setExporting(true);
    try {
      const csvFile = await exportOrganisations();
      downloadBlobFile('organisations.csv', csvFile);
    } catch (e) {
    } finally {
      setExporting(false);
    }
  };

  const upload = async () => {
    if (!fileToUpload) return;
    setImporting(true);
    try {
      uploadOrganisations(fileToUpload).then((res) => {
        setUploadResult(res);
        setImporting(false);
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (!uploadResult) {
      setFailedRowsHref(undefined);
      setSkippedRowsHref(undefined);
      return;
    }
    const failedRowsBlob = new Blob(
      [
        uploadResult.failedRows
          .map((vals) => {
            return vals.map((val) => escapeCsvCell(val)).join(',');
          })
          .join('\n')
      ],
      {
        type: 'text/csv'
      }
    );
    const skippedRowsBlob = new Blob(
      [
        uploadResult.skippedRows
          .map((vals) => {
            return vals.map((val) => escapeCsvCell(val)).join(',');
          })
          .join('\n')
      ],
      {
        type: 'text/csv'
      }
    );
    setFailedRowsHref(URL.createObjectURL(failedRowsBlob));
    setSkippedRowsHref(URL.createObjectURL(skippedRowsBlob));
  }, [uploadResult]);

  const escapeCsvCell = (value: string) => {
    return value.includes(',') ? `"${value}"` : value;
  };

  useEffect(() => {
    appShellContext.setHeaderLeft(<HeaderBackButton onClick={() => navigate('/', { replace: true })} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted: boolean = true;
    let cancelRequest: Canceler;
    (async () => {
      try {
        setLoading(true);

        const { records } = await getOrganisations({
          cancelToken: new CancelToken((executer) => (cancelRequest = executer))
        });
        if (mounted) {
          setOrganisations(records);
        }
      } catch (e) {
        if (!isCancel(e)) globalState.handleResponseError(e as AxiosError);
      } finally {
        if (mounted) setLoading(false);
      }
      return () => {
        if (cancelRequest) cancelRequest();
        mounted = false;
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const searchInOrganisations = (arr: OrganisationReadView[], query: string): OrganisationReadView[] => {
    return arr.filter((obj) => {
      const lcQuery = query.toLowerCase();
      return (
        obj.name.toLowerCase().includes(lcQuery) ||
        obj.identificationNumber?.toLowerCase().includes(lcQuery) ||
        obj.streetAddress?.toLowerCase().includes(lcQuery) ||
        obj.city?.toLowerCase().includes(lcQuery) ||
        obj.country?.toLowerCase().includes(lcQuery) ||
        obj.postalCode?.toLowerCase().includes(lcQuery) ||
        obj.kommun?.toLowerCase().includes(lcQuery) ||
        obj.region?.toLowerCase().includes(lcQuery) ||
        obj.contactName?.toLowerCase().includes(lcQuery) ||
        obj.contactEmail?.toLowerCase().includes(lcQuery) ||
        obj.contactPhoneNumber?.toLowerCase().includes(lcQuery) ||
        obj.keywords?.join(', ').toLowerCase().includes(lcQuery) ||
        obj.displayName?.toLowerCase().includes(lcQuery) ||
        obj.vatNumber?.toLowerCase().includes(lcQuery)
      );
    });
  };

  useEffect(() => {
    if (organisations && organisations.length > 0 && searchQuery.trim().length > 0) {
      const result = searchInOrganisations(organisations, searchQuery);
      setFilteredOrganisations(result);
    } else {
      setFilteredOrganisations(undefined);
    }
  }, [organisations, searchQuery]);

  return (
    <Box>
      <Tooltip title={'Add new organisation'}>
        <Fab
          color='primary'
          aria-label='add'
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          onClick={() => navigate('create')}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Box className='dataGridContainer'>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <TextField
            id='search-input'
            label='Sök...'
            inputRef={searchInputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  {searchQuery ? (
                    <IconButton
                      aria-label='clear'
                      onClick={() => {
                        setSearchQuery('');
                        searchInputRef.current && searchInputRef.current.blur();
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              )
            }}
            variant='standard'
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
          <ButtonGroup>
            {globalState.user?.isSuperUser && !isMobileDevice ? (
              <>
                <Button
                  disabled={importing}
                  aria-label='clear'
                  variant='contained'
                  onClick={() => setSelectingFile(true)}
                  startIcon={<UploadIcon />}
                  sx={{ backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}
                >
                  <Typography>Ladda upp</Typography>
                </Button>
                <Button
                  disabled={exporting}
                  aria-label='clear'
                  variant='contained'
                  onClick={downloadOrganisations}
                  startIcon={<DownloadIcon />}
                  sx={{ backgroundColor: 'secondary.main', color: 'secondary.contrastText' }}
                >
                  <Typography>Ladda ner</Typography>
                </Button>
              </>
            ) : (
              <>
                <IconButton
                  disabled={importing}
                  aria-label='clear'
                  color='secondary'
                  onClick={() => setSelectingFile(true)}
                >
                  <UploadIcon />
                </IconButton>
                <IconButton disabled={exporting} aria-label='clear' color='secondary' onClick={downloadOrganisations}>
                  <DownloadIcon />
                </IconButton>
              </>
            )}
          </ButtonGroup>
        </Box>
        <DataGrid
          sx={{
            '& .RaDatagrid-clickableRow': { cursor: 'pointer' }
          }}
          rows={filteredOrganisations ?? organisations}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          loading={loading}
          isCellEditable={() => false}
          hideFooter={false}
          rowHeight={48}
          onRowClick={(params) => {
            navigate(`${params.id}`, { replace: true });
          }}
        />
      </Box>
      <Dialog
        open={itemToDelete !== undefined}
        onClose={() => setItemToDelete(undefined)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Delete organisation?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete the organisation {itemToDelete}:
            {organisations.find((org) => org.id === itemToDelete)?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button color='info' variant='contained' startIcon={<ClearIcon />} onClick={() => setItemToDelete(undefined)}>
            Avbryt
          </Button>
          <Button color='error' variant='contained' startIcon={<DeleteIcon />} onClick={() => removeOrganisation()}>
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={selectingFile}
        onClose={() => setSelectingFile(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Välj ett fil'}</DialogTitle>
        <DialogContent>
          <Button onClick={() => fileInputRef.current?.click()} variant='outlined'>
            {fileName ?? 'Välj fil'}
          </Button>
          <input
            ref={fileInputRef}
            style={{ display: 'none' }}
            type='file'
            onChange={(e) => {
              setFileName(e.target.files?.[0].name);
              setFileToUpload(e.target.files?.[0]);
            }}
          ></input>
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<UploadIcon />}
            onClick={() => {
              upload();
              setSelectingFile(false);
              setFileName(undefined);
            }}
          >
            Ladda upp
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={() => {
              setSelectingFile(false);
              setFileName(undefined);
            }}
          >
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={uploadResult !== null}
        onClose={() => setUploadResult(null)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Resultat för uppladdning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography>{`${uploadResult?.added} organisationer har skapats.`}</Typography>
            <Typography>{`${uploadResult?.updated} organisationer har uppdaterats.`}</Typography>
            <Typography>{`${uploadResult?.deleted} organisationer har makulerats.`}</Typography>
            {uploadResult?.failedRows && uploadResult.failedRows.length > 0 && (
              <>
                <Link
                  href={failedRowsHref}
                  download={`rejected_rows.csv`}
                >{`${uploadResult?.failedRows.length} organisationer misslyckades att uppdatera.`}</Link>
                <Typography>{'Vänligen klicka på länken för att åtgärda problemet'}</Typography>
              </>
            )}
            {uploadResult?.skippedRows && uploadResult.skippedRows.length > 0 && (
              <>
                <Link
                  href={skippedRowsHref}
                  download={`skipped_rows.csv`}
                >{`${uploadResult?.skippedRows.length} organisationer finns redan i databasen.`}</Link>
                <Typography>{'Vänligen klicka på länken för att se dubbletterna'}</Typography>
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Organisations;
