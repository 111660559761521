import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import './DnDFileInput.scss';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

type Props = {
  onFileChange: React.Dispatch<React.SetStateAction<string | null>>;
  text?: string;
  defaultIcon?: string;
  accept?: Accept;
};
const DnDFileInput = (props: Props) => {
  const [file, setFile] = useState<{ file: File; base64: string } | null>(null);

  useEffect(() => {
    if (props.defaultIcon) {
      fetch(props.defaultIcon).then((v) => {
        v.blob().then((blob) => {
          const file = new File([blob], 'icon.svg', { type: 'image/svg+xml' });
          setFile({ file, base64: props.defaultIcon! });
        });
      });
    }
  }, [props.defaultIcon]);

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      fileRejections.forEach((element) => {
        toast.warn(element.errors[0].message);
      });
    }
    if (acceptedFiles.length > 0) {
      const newFile = acceptedFiles[0];
      // Use FileReader to read the file as a data URL
      const reader = new FileReader();
      reader.readAsDataURL(newFile);

      // When the file is done loading, convert the data URL to a base64 string
      reader.onload = () => {
        if (reader.result) {
          setFile({ file: newFile, base64: reader.result.toString() });
          props.onFileChange(reader.result.toString());
        } else {
          toast.warn('Something went wrong. try again!');
          setFile(null);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    maxFiles: 1,
    maxSize: 1_000_000, // max size 1 MB
    accept: props.accept ?? {
      'image/png': ['.png'],
      'image/svg+xml': ['.svg']
    },
    onDrop
  });

  const onDelete = () => {
    setFile(null);
    props.onFileChange(null);
  };

  return (
    <div className='fileDnDMainContainer'>
      <div
        {...getRootProps({
          className: `dropAreaContainer ${isDragActive && 'activeDrag'}`
        })}
      >
        <CloudUploadOutlinedIcon sx={{ fontSize: 100, color: 'rgb(0, 162, 255)' }}></CloudUploadOutlinedIcon>
        <Typography variant='button' sx={{ textTransform: 'none' }}>
          {props.text ?? 'Släpp fil här'}
        </Typography>
        <input {...getInputProps({ multiple: false, accept: 'image/svg+png+xml', type: 'file' })} />
      </div>
      {file && (
        <List>
          <ListItem
            secondaryAction={
              <IconButton onClick={onDelete}>
                <DeleteOutlineOutlinedIcon color='error' />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar src={URL.createObjectURL(file.file)}></Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}
              primary={file.file.name}
              secondary={`${(file.file.size / 1024).toFixed(2)} Kb`}
            ></ListItemText>
          </ListItem>
        </List>
      )}
    </div>
  );
};
export default DnDFileInput;
