import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import './share.scss';
import CopyIcon from '@mui/icons-material/ContentCopy';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon
} from 'react-share';
import { PiCodeSimple } from 'react-icons/pi';
import { Button, FormControlLabel, InputLabel, Radio, RadioGroup, Slider, Typography } from '@mui/material';

interface ShareModalProps {
  shareUrl: string;
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ shareUrl, onClose }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentWidth = 100; // Width of each item
  const [visibleItems, setVisibleItems] = useState(4); // Number of visible items
  const containerRef = useRef<HTMLDivElement>(null);

  const [itemSize, setItemSize] = useState(70);
  const [itemSizeEmbed, setItemSizeEmbed] = useState(70);

  const [embedCode, setEmbedCode] = useState('');
  const [embedCodeVisible, setEmbedCodeVisible] = useState(false);
  const embedCodeInputRef = useRef<HTMLInputElement>(null);

  const [embedZoom, setEmbedZoom] = useState<number>(7);
  const [embedPinSize, setEmbedPinSize] = useState<string>('default');

  useEffect(() => {
    const handleResize = () => {
      // Calculate item size based on the window width or any specific logic
      const newSize = Math.min(Math.floor(window.innerWidth * 0.1), 70);
      const newSizeEmbed = Math.min(Math.floor(window.innerWidth * 0.1), 70);
      setItemSize(newSize);
      setItemSizeEmbed(newSizeEmbed);
    };

    handleResize(); // Set initial size

    window.addEventListener('resize', handleResize); // Update size on window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on component unmount
    };
  }, []);

  // Calculate number of visible items based on screen width
  useEffect(() => {
    const handleResizeItems = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setVisibleItems(2);
      } else if (screenWidth >= 600 && screenWidth < 900) {
        setVisibleItems(3);
      } else {
        setVisibleItems(4);
      }
    };

    handleResizeItems();
    window.addEventListener('resize', handleResizeItems);

    return () => {
      window.removeEventListener('resize', handleResizeItems);
    };
  }, []);

  const items = [
    <div className='item'>
      <FacebookShareButton url={shareUrl}>
        <FacebookIcon size={itemSize} round />
        <div className='name'>Facebook</div>
      </FacebookShareButton>
    </div>,

    <div className='item'>
      <FacebookMessengerShareButton url={shareUrl} appId='521270401588372' className='Demo__some-network__share-button'>
        <FacebookMessengerIcon size={itemSize} round />
        <div className='name'>Messenger</div>
      </FacebookMessengerShareButton>
    </div>,

    <div className='item'>
      <TwitterShareButton url={shareUrl} className='Demo__some-network__share-button'>
        <XIcon size={itemSize} round />
        <div className='name'>Twitter</div>
      </TwitterShareButton>
    </div>,

    <div className='item'>
      <WhatsappShareButton url={shareUrl} separator=':: ' className='Demo__some-network__share-button'>
        <WhatsappIcon size={itemSize} round />
        <div className='name'>WhatsApp</div>
      </WhatsappShareButton>
    </div>,

    <div className='item'>
      <LinkedinShareButton url={shareUrl} className='Demo__some-network__share-button'>
        <LinkedinIcon size={itemSize} round />
        <div className='name'>LinkedIn</div>
      </LinkedinShareButton>
    </div>,

    <div className='item'>
      <PinterestShareButton
        url={String(window.location)}
        media={`${String(window.location)}/`}
        className='Demo__some-network__share-button'
      >
        <PinterestIcon size={itemSize} round />
        <div className='name'>Pinterest</div>
      </PinterestShareButton>
    </div>,

    <div className='item'>
      <RedditShareButton
        url={shareUrl}
        windowWidth={660}
        windowHeight={460}
        className='Demo__some-network__share-button'
      >
        <RedditIcon size={itemSize} round />
        <div className='name'>Reddit</div>
      </RedditShareButton>
    </div>,

    <div className='item'>
      <TumblrShareButton url={shareUrl} className='Demo__some-network__share-button'>
        <TumblrIcon size={itemSize} round />
        <div className='name'>Tumblr</div>
      </TumblrShareButton>
    </div>,

    <div className='item'>
      <EmailShareButton url={shareUrl} body='body' className='Demo__some-network__share-button'>
        <EmailIcon size={itemSize} round />
        <div className='name'>Email</div>
      </EmailShareButton>
    </div>
  ];

  const handleScroll = (direction: string) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth / visibleItems;
      const maxScroll = container.scrollWidth - container.clientWidth;

      if (direction === 'left') {
        setScrollPosition(Math.max(scrollPosition - scrollAmount, 0));
      } else {
        setScrollPosition(Math.min(scrollPosition + scrollAmount, maxScroll));
      }
    }
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
  };

  const makeIFrameURL = useCallback(
    (zoom?: string, pinsize?: string) => {
      const urlObj = new URL(shareUrl);
      const searchParams = urlObj.searchParams;
      searchParams.set('zoom', zoom ?? '7');
      searchParams.set('pinsize', pinsize ?? 'default');
      const filteredParams = searchParams.toString();
      const queryString = filteredParams ? `?${filteredParams}` : '';
      return urlObj.origin + urlObj.pathname + queryString;
    },
    [shareUrl]
  );

  useEffect(() => {
    const generatedEmbedCode = `<iframe src="${makeIFrameURL(
      embedZoom.toString(),
      embedPinSize
    )}" width="600" height="400" frameborder="0" scrolling="no"></iframe>`;
    setEmbedCode(generatedEmbedCode);
  }, [shareUrl, embedPinSize, embedZoom, makeIFrameURL]);

  const toggleEmbedCode = () => {
    setEmbedCodeVisible(!embedCodeVisible);
  };

  const copyEmbedCodeToClipboard = () => {
    if (embedCodeInputRef.current) {
      embedCodeInputRef.current.select();
      document.execCommand('copy');
    }
  };

  const handleZoomChange = (_e: Event, newValue: number | number[]) => {
    if (typeof newValue == 'number') setEmbedZoom(newValue);
  };

  const handlePinSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmbedPinSize(e.target.value);
  };

  const EmbedCodeModal = () => {
    return (
      <div className='embed-code-modal'>
        <div className='embed-code-content'>
          <span className='close-btn' onClick={toggleEmbedCode}>
            X
          </span>
          <p className='title2'>BÄDDA IN</p>

          <InputLabel htmlFor='zoom-slider'>Anpassa kartans zoomnivå</InputLabel>
          <Slider
            id='zoom-slider'
            aria-label='Zoom'
            defaultValue={8}
            value={embedZoom}
            valueLabelDisplay='auto'
            step={1}
            marks
            min={1}
            max={15}
            onChange={handleZoomChange}
          />
          <InputLabel id='pin-size-radio-group-label'>Anpassa ikonstorlek</InputLabel>
          <RadioGroup
            aria-labelledby='pin-size-radio-group-label'
            defaultValue='default'
            name='radio-buttons-group'
            value={embedPinSize}
            onChange={handlePinSizeChange}
          >
            <FormControlLabel
              key='radio-xs'
              value='xs'
              control={<Radio />}
              label={<Typography sx={{ color: 'black' }}>XS</Typography>}
            />
            <FormControlLabel
              key='radio-s'
              value='s'
              control={<Radio />}
              label={<Typography sx={{ color: 'black' }}>S</Typography>}
            />
            <FormControlLabel
              key='radio-default'
              value='default'
              control={<Radio />}
              label={<Typography sx={{ color: 'black' }}>Standard</Typography>}
            />
          </RadioGroup>
          <input
            ref={embedCodeInputRef}
            type='text'
            value={embedCode}
            readOnly
            onClick={() => embedCodeInputRef.current?.select()}
          />
          <Button onClick={copyEmbedCodeToClipboard} startIcon={<CopyIcon />}>
            Kopiera kod
          </Button>
          <Typography
            sx={{
              color: '#777777',
              fontSize: '12pt'
            }}
          >
            Nedan visas en förhandsgranskning av kartan. Justera zoomnivå och ikonstorlek efter behov. Du kan även
            redigera kartans kod ovan för att anpassa den till din webbplats.
          </Typography>
          <iframe
            title='preview-iframe'
            src={makeIFrameURL(embedZoom.toString(), embedPinSize)}
            width='100%'
            height='400'
            className='preview-iframe'
          ></iframe>
        </div>
      </div>
    );
  };

  return (
    <div className='modal-overlay'>
      <div className='modal'>
        <div className='modal-header'>
          <div className='modal-title'>Dela</div>
          <button className='close-btn' onClick={onClose}>
            X
          </button>
        </div>
        <div className='modal-content'>
          <div className='share-buttons-container'>
            <div className='scroll-container'>
              <div className='btn-background'>
                <button className='scroll-buttons' onClick={() => handleScroll('left')} disabled={scrollPosition === 0}>
                  {'<'}
                </button>
              </div>
              <div className='content-wrapper' ref={containerRef}>
                <div
                  className='content'
                  style={{
                    transform: `translateX(-${scrollPosition}px)`,
                    width: `${contentWidth * items.length + 100}px`
                  }}
                >
                  {items.map((item, index) => (
                    <div key={index} className='item' style={{ width: `${contentWidth}px` }}>
                      {item}
                    </div>
                  ))}
                  <div className='icon2'>
                    <div className='icon' style={{ width: `${itemSize}px`, height: `${itemSize}px` }}>
                      <PiCodeSimple size={itemSizeEmbed - 20} onClick={toggleEmbedCode} />
                    </div>
                    <div className='name'>Embed</div>
                  </div>
                </div>
              </div>
              <div className='btn-background'>
                <button
                  className='scroll-buttons'
                  onClick={() => handleScroll('right')}
                  disabled={
                    !containerRef.current ||
                    scrollPosition >=
                      (containerRef.current?.scrollWidth ?? 0) - (containerRef.current?.offsetWidth ?? 0)
                  }
                >
                  {'>'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <div className='link-container'>
            <div className='page-link'>
              <span>{shareUrl}</span>
              <button className='copy-button' onClick={copyLinkToClipboard}>
                Kopiera
              </button>
            </div>
            <div className={`overlay ${embedCodeVisible ? 'active' : ''}`} />
          </div>
        </div>
      </div>
      {embedCodeVisible && <EmbedCodeModal />}
    </div>
  );
};
export default ShareModal;
