import { createContext, useContext, useState } from 'react';
import AppShell from './AppShell';

type AppShellContextType = {
  headerLeft: JSX.Element | null;
  setHeaderLeft: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
};
const initialValue: AppShellContextType = { headerLeft: null, setHeaderLeft: () => {} };
const AppShellContext = createContext<AppShellContextType>(initialValue);
const AppShellProvider = ({ children }: { children?: any }) => {
  const [headerLeft, setHeaderLeft] = useState<JSX.Element | null>(null);

  return (
    <AppShellContext.Provider value={{ headerLeft, setHeaderLeft }}>
      <AppShell />
    </AppShellContext.Provider>
  );
};

export default AppShellProvider;
export const useAppShellContext = () => useContext(AppShellContext);
