import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { ENV_VARIABLES } from '../config';
import { TokenReadView } from '../types/types';
import { ENDPOINTS } from './endpoints';

const headers = {};

export enum HttpStatusCode {
  Unauthorized = 401,
  Ok = 200,
  Created = 201,
  NoContent = 204,
  ServerError = 500
}

const injectToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem('tractech_token');
  if (token != null) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
};

const baseURL = `${ENV_VARIABLES.apiBaseUrl}/v1/`;

const HandleAuthError = (error: AxiosError): any => {
  const refreshToken = localStorage.getItem('tractech_refresh_token');
  if (refreshToken && error.config && error.response && error.response.status === HttpStatusCode.Unauthorized) {
    const refreshResponse = axios
      .post<TokenReadView>(`${baseURL}${ENDPOINTS.auth}`, { refreshToken })
      .then(({ data }) => {
        localStorage.setItem('tractech_token', data.token);
        error.config!.headers!.Authorization = `Bearer ${data.token}`;
        return axios.request(error.config!);
      })
      .catch((error) => {
        if (error.response?.status === HttpStatusCode.Unauthorized) {
          localStorage.removeItem('tractech_token');
          localStorage.removeItem('tractech_refresh_token');
        }
      });
    return refreshResponse;
  }

  return Promise.reject(error);
};

class Http {
  private HTTP: AxiosInstance | null = null;
  private reqInterceptId: number | null = null;
  private resInterceptId: number | null = null;
  private get http(): AxiosInstance {
    return this.HTTP ? this.HTTP : this.initHttp();
  }
  public initHttp() {
    const axiosHttp = axios.create({ baseURL, headers });
    this.reqInterceptId = axiosHttp.interceptors.request.use(injectToken, (error) => Promise.reject(error));
    this.resInterceptId = axiosHttp.interceptors.response.use(
      (response: AxiosResponse<any>) => response,
      HandleAuthError
    );
    this.HTTP = axiosHttp;
    return axiosHttp;
  }

  public request(config: AxiosRequestConfig) {
    return this.http.request(config);
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.http.get<T>(url, config);
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.post<T>(url, data, config);
  }

  public delete(url: string, config?: AxiosRequestConfig) {
    return this.http.delete(url, config);
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.put<T>(url, data, config);
  }

  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.patch<T>(url, data, config);
  }
}

export const http = new Http();
export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;
