import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from '../../../context/GlobalState';
import { useParams } from 'react-router-dom';
import { OrganisationDescriptionCardReadView, OrganisationReadView } from '../../../types/types';
import { toast } from 'react-toastify';
import { getOrganisationById } from '../../../services/Organisations';
import ComponentLoader from '../../common/componentLoader/ComponentLoader';
import { AxiosError } from 'axios';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {
  createDescriptionCard,
  getDescriptionCardByOrgId,
  updateDescriptionCard
} from '../../../services/DescriptionCards';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PreviewMapInfoBox from './PreviewMapInfoBox';
import FreeTextListInput from '../../common/FreeTextListInput';
import { arraysAreEqual } from '../../../utils/HelperFunctions';

const EditOrganisationDescriptionCard = () => {
  const globalState = useGlobalState();
  const params = useParams<{ orgId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [descriptionCard, setDescriptionCard] = useState<OrganisationDescriptionCardReadView>();
  const [displayName, setDisplayName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [buttonText, setButtonText] = useState<string>();
  const [link1, setLink1] = useState<string>();
  const [link2, setLink2] = useState<string>();
  const [link3, setLink3] = useState<string>();
  const [link3Text, setLink3Text] = useState<string>();
  const [bigButtonLinks, setBigButtonLinks] = useState<string[]>();

  useEffect(() => {
    if (!params.orgId) {
      toast.error('Something went wrong. Please refresh the page!');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        setOrganisation(_organisation);
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
  }, [globalState, params.orgId]);

  useEffect(() => {
    if (organisation) {
      (async () => {
        try {
          const _descriptionCard = await getDescriptionCardByOrgId(organisation.id);
          setDescriptionCard(_descriptionCard);
        } catch (e) {
          if (e instanceof AxiosError) {
            if (e.response?.data.code === 'E0015' && organisation) {
              const _descriptionCard = await createDescriptionCard({
                displayName: organisation.displayName ?? organisation.name,
                description: organisation.description ?? '',
                organisationId: organisation.id
              });
              setDescriptionCard(_descriptionCard);
            }
          }
          globalState.handleResponseError(e as AxiosError);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [organisation, globalState]);

  useEffect(() => {
    setDisplayName(descriptionCard?.displayName);
    setDescription(descriptionCard?.description);
    setButtonText(descriptionCard?.buttonText);
    setLink1((descriptionCard?.link1 || organisation?.referralLink) ?? '');
    setLink2(descriptionCard?.link2);
    setLink3(descriptionCard?.link3);
    setLink3Text(descriptionCard?.link3Text);
    setBigButtonLinks(descriptionCard?.bigButtonLinks);
  }, [descriptionCard, organisation]);

  const resolveForm = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!descriptionCard || !organisation) {
      toast.error('Something went wrong!');
      return;
    }
    setLoading(true);
    try {
      const _descriptionCard = await updateDescriptionCard(descriptionCard.id, {
        displayName,
        description,
        buttonText,
        link1,
        link2,
        link3,
        link3Text,
        bigButtonLinks,
        organisationId: organisation.id
      });
      setDescriptionCard(_descriptionCard);
      toast.success('Ändringarna har sparats');
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const revertChanges = useCallback(() => {
    setDisplayName(descriptionCard?.displayName);
    setDescription(descriptionCard?.description);
    setButtonText(descriptionCard?.buttonText);
    setLink1((descriptionCard?.link1 || organisation?.referralLink) ?? '');
    setLink2(descriptionCard?.link2);
    setLink3(descriptionCard?.link3);
    setLink3Text(descriptionCard?.link3Text);
    setBigButtonLinks(descriptionCard?.bigButtonLinks);
  }, [descriptionCard, organisation]);

  const changeHappened = useCallback(() => {
    return (
      displayName !== descriptionCard?.displayName ||
      description !== descriptionCard?.description ||
      buttonText !== descriptionCard?.buttonText ||
      link1 !== descriptionCard?.link1 ||
      link2 !== descriptionCard?.link2 ||
      link3 !== descriptionCard?.link3 ||
      link3Text !== descriptionCard?.link3Text ||
      !arraysAreEqual(bigButtonLinks ?? [], descriptionCard?.bigButtonLinks ?? [])
    );
  }, [
    bigButtonLinks,
    buttonText,
    description,
    descriptionCard?.bigButtonLinks,
    descriptionCard?.buttonText,
    descriptionCard?.description,
    descriptionCard?.displayName,
    descriptionCard?.link1,
    descriptionCard?.link2,
    descriptionCard?.link3,
    descriptionCard?.link3Text,
    displayName,
    link1,
    link2,
    link3,
    link3Text
  ]);

  return !loading && organisation ? (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, p: 1.5 }}>
        <Typography variant='h6'>Redigera Företagskort för {organisation.name}</Typography>
      </Box>
      <Box sx={{ p: 1.5 }}>
        <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <PreviewMapInfoBox
              descriptionCard={{
                id: descriptionCard?.id ?? 0,
                displayName: displayName,
                description: description,
                buttonText,
                link1,
                link2,
                link3,
                link3Text,
                bigButtonLinks,
                organisationId: organisation.id
              }}
              organisation={{
                ...organisation,
                displayName:
                  displayName ||
                  (organisation.displayName?.trim() !== '' ? organisation.displayName : organisation.name),
                description: description || organisation.description
              }}
              onClose={() => {}}
              isMobileDevice={false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <form onSubmit={resolveForm}>
              <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Om din organisation inte har en uppladdad logotyp kan du ändra visningsnamnet på ditt företagskort här.'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='display-name'>Visningsnamn</InputLabel>
                      <OutlinedInput
                        id='display-name'
                        type='text'
                        required
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Visningsnamn'
                        onChange={(e) => setDisplayName(e.target.value)}
                        value={displayName ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Beskrivningen som visas här är AI-genererad. Vill du göra ändringar kan du göra det här.'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='description'>Beskrivning</InputLabel>
                      <OutlinedInput
                        id='description'
                        type='text'
                        required
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Beskrivning'
                        onChange={(e) => setDescription(e.target.value)}
                        value={description ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Ändra namnet till den stora kartknappen här.'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='buttonText'>Kartbeskrivning</InputLabel>
                      <OutlinedInput
                        id='buttonText'
                        type='text'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Kartbeskrivning'
                        onChange={(e) => setButtonText(e.target.value)}
                        value={buttonText ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Vill du ändra länk till hemsida för ditt företagskort kan du göra det här.'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='link1'>Hemsida</InputLabel>
                      <OutlinedInput
                        id='link1'
                        type='text'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Hemsida'
                        onChange={(e) => setLink1(e.target.value)}
                        value={link1 ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Hitta hit är en automatisk länk för vägbeskrivning på Google- och Apple kartor. Vill du ange en annan länk kan du göra det här.'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='link2'>Hitta Hit</InputLabel>
                      <OutlinedInput
                        id='link2'
                        type='text'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Hitta Hit'
                        onChange={(e) => setLink2(e.target.value)}
                        value={link2 ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Vill du ha en tredje länk på ditt företagskort kan du ange länken här.'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='link3'>Extra Länk</InputLabel>
                      <OutlinedInput
                        id='link3'
                        type='text'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Extra Länk'
                        onChange={(e) => setLink3(e.target.value)}
                        value={link3 ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Tooltip title='Här anger du visningtexten för den tredje länken'>
                    <FormControl variant='outlined' fullWidth>
                      <InputLabel htmlFor='link3Text'>Visningstext för Extra Länk</InputLabel>
                      <OutlinedInput
                        id='link3Text'
                        type='text'
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton edge='end'>
                              <DriveFileRenameOutlineIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Visningstext för Extra Länk'
                        onChange={(e) => setLink3Text(e.target.value)}
                        value={link3Text ?? ''}
                      />
                    </FormControl>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FreeTextListInput
                    limit={1}
                    onChange={setBigButtonLinks}
                    initialValues={bigButtonLinks ?? []}
                    label={'Länk till karta'}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              onClick={() => resolveForm()}
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading || !changeHappened()}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => revertChanges()}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : loading ? (
    <ComponentLoader bgColor='#fff' />
  ) : (
    <Alert severity='error'>Failed loading the organisation content!</Alert>
  );
};

export default EditOrganisationDescriptionCard;
