import React, { Component, ReactNode } from 'react';
import QrScanner from 'qr-scanner';

type Props = {
  onDecode: (result: QrScanner.ScanResult) => void;
  onDecodeError?: ((error: string | Error) => void) | undefined;
  containerHeight: number;
};

const _onDecodeError: (error: string | Error) => void = (error) => {};

class Scanner extends Component<Props> {
  private scannerRef: QrScanner | null;
  private scannerState: 'STARTED' | null;
  constructor(props: Props) {
    super(props);
    this.scannerRef = null;
    this.scannerState = null;
  }
  async componentDidMount(): Promise<void> {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      console.log("Let's get this party started");
    }
    const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
    console.log('mediaStream:', mediaStream);
    const videoElement = document.getElementById('qr-video');
    if (videoElement) {
      this.scannerRef = new QrScanner(videoElement as HTMLVideoElement, this.props.onDecode, {
        preferredCamera: 'environment',
        maxScansPerSecond: 1,
        onDecodeError: this.props.onDecodeError ?? _onDecodeError,
        highlightScanRegion: true,
        highlightCodeOutline: true
      });
      if (this.scannerRef && this.scannerState === null) {
        this.scannerRef
          .start()
          .then((value) => {
            this.scannerState = 'STARTED';
          })
          .catch((error) => console.log('Scanner start ERROR:', error));
      }
    }
  }
  componentWillUnmount(): void {
    if (this.scannerRef && this.scannerState === 'STARTED') this.scannerRef.stop();
  }
  render(): ReactNode {
    return (
      <video
        id='qr-video'
        style={{
          width: '100%',
          maxWidth: '550px',
          height: this.props.containerHeight,
          maxHeight: '550px'
        }}
      ></video>
    );
  }
}

export default React.memo(Scanner);
