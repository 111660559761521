import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import { ClassifierCreateView, ClassifierListView, ClassifierReadView, infinitePagination } from '../types/types';

export const getClassifiers = async (config?: AxiosRequestConfig): Promise<ClassifierListView> => {
  const { data } = await http.get<ClassifierListView>(ENDPOINTS.categories, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
};

export const createClassifier = async (category: ClassifierCreateView): Promise<ClassifierReadView> => {
  const { data } = await http.post<ClassifierReadView>(ENDPOINTS.categories, category);
  return data;
};

export const updateClassifier = async (
  id: string | number,
  category: ClassifierCreateView
): Promise<ClassifierReadView> => {
  const { data } = await http.patch<ClassifierReadView>(`${ENDPOINTS.categories}/${id}`, category);
  return data;
};

export const deleteClassifier = async (id: string | number): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.categories}/${id}`);
  return status;
};
