import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import {
  OrganisationBasicInfoView,
  OrganisationCreateView,
  OrganisationListView,
  OrganisationMembershipCreateView,
  OrganisationMembershipReadView,
  OrganisationReadView,
  OrganisationSummaryListView,
  OrganisationSummaryView,
  UserReadView,
  infinitePagination
} from '../types/types';

export const getOrganisations = async (config?: AxiosRequestConfig): Promise<OrganisationListView> => {
  const { data } = await http.get<OrganisationListView>(ENDPOINTS.organisations, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyOrganisations;
};

export const getOrganisationsSummary = async (config?: AxiosRequestConfig): Promise<OrganisationSummaryListView> => {
  const { data } = await http.get<OrganisationSummaryListView>(ENDPOINTS.organisationsSummary, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
};

export const getOrganisationSummary = async (
  id: number,
  config?: AxiosRequestConfig
): Promise<OrganisationSummaryView> => {
  const { data } = await http.get<OrganisationSummaryView>(`${ENDPOINTS.organisationsSummary}/${id}`, {
    ...config
  });
  return data;
};

export const createOrganisation = async (organisation: OrganisationCreateView): Promise<OrganisationReadView> => {
  const { data } = await http.post<OrganisationReadView>(ENDPOINTS.organisations, organisation);
  return data;
  //await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyOrganisations.records[0];
};

export const getBasicOrganisationInfo = async (
  id: number,
  config?: AxiosRequestConfig
): Promise<OrganisationBasicInfoView> => {
  const { data } = await http.get<OrganisationBasicInfoView>(`${ENDPOINTS.organisationsInfo}/${id}`, config);
  return data;
};

export const getOrganisationById = async (id: string, config?: AxiosRequestConfig): Promise<OrganisationReadView> => {
  const { data } = await http.get<OrganisationReadView>(`${ENDPOINTS.organisations}/${id}`, config);
  return data;
  //  await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyOrganisations.records.find((r) => r.id === Number(id)) ?? dummyOrganisations.records[0];
};
export const getOrganisationMapInfo = async (
  id: string,
  config?: AxiosRequestConfig
): Promise<OrganisationReadView> => {
  const { data } = await http.get<OrganisationReadView>(`${ENDPOINTS.organisationsMapInfo}/${id}`, config);
  return data;
};

export const updateOrganisation = async (
  id: string | number,
  organisation: OrganisationCreateView
): Promise<OrganisationReadView> => {
  const { data } = await http.patch<OrganisationReadView>(`${ENDPOINTS.organisations}/${id}`, organisation);
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyOrganisations.records[0];
};

export const deleteOrganisation = async (id: string | number): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.organisations}/${id}`);
  return status;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return 200;
};

export const deleteUserFromOrganisation = async (orgId: string | number, userId: string | number): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.organisations}/${orgId}/users/${userId}`);
  return status;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return 200;
};

export const updateUserInOrganisation = async (
  orgId: string | number,
  userId: string | number
): Promise<UserReadView> => {
  const { data } = await http.put<UserReadView>(`${ENDPOINTS.organisations}/${orgId}/users/${userId}`);
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return { email: 'SummyUser@test.com', id: 111, isSuperUser: false };
};

export const addUserToOrganisation = async (
  orgId: string | number,
  userId: string | number,
  payload: OrganisationMembershipCreateView
): Promise<OrganisationMembershipReadView> => {
  const { data } = await http.put<OrganisationMembershipReadView>(
    `${ENDPOINTS.organisations}/${orgId}/users/${userId}`,
    payload
  );
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return { email: 'SummyUser@test.com', id: 111, isSuperUser: false };
};

/* const dummyOrganisations: OrganisationListView = {
  limit: Number.MAX_SAFE_INTEGER,
  offset: 0,
  totalCount: 35,
  records: Array.from({ length: 35 }, () => {
    const id = Math.floor(Math.random() * 1000);
    return { id, name: 'OrgName' + id, identificationNumber: '556588-2965', lat: 57.8558, lng: 14.7584 };
  })
}; */
