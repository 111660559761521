import { useEffect, useState } from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { createPortal } from 'react-dom';

type MapControlProps = {
  position: google.maps.ControlPosition;
  children: React.ReactNode;
};

const MapControl = ({ position, children }: MapControlProps) => {
  const map = useGoogleMap();
  const [container] = useState(() => {
    const div = document.createElement('div');
    div.style.pointerEvents = 'none'; // Add this line
    return div;
  });

  useEffect(() => {
    if (!map) return;

    map.controls[position].push(container);

    return () => {
      // Clean up the control when unmounted
      const index = map.controls[position].getArray().indexOf(container);
      if (index !== -1) {
        map.controls[position].removeAt(index);
      }
    };
  }, [map, position, container]);

  return createPortal(children, container);
};

export default MapControl;
