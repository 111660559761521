import React from 'react';
import { Box, Divider, List, ListItem, Paper, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

type Props<T> = {
  items: T[];
  onReorder: (reorderedItems: T[]) => void;
  renderItem: (item: T, index?: number) => React.ReactNode;
  keyExtractor: (item: T) => number;
  title?: string;
};
const DnDListContainer = <T,>(props: Props<T>) => {
  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(props.items, result.source.index, result.destination.index);
    props.onReorder(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='droppable' direction='vertical'>
        {(provided) => (
          <Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography flex={0.1}>Ordning</Typography>
              <Divider orientation='vertical' flexItem sx={{ paddingLeft: '.5em' }} />
              <Typography flex={1} sx={{ paddingLeft: '1em' }}>
                {props.title ?? 'Kategori'}
              </Typography>
            </Box>
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {props.items.map((item, index) => (
                <Draggable
                  key={props.keyExtractor(item)}
                  draggableId={props.keyExtractor(item).toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div style={{ display: 'flex' }}>
                      <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div style={{ flex: 0.3, paddingLeft: '0.5rem' }} className='dndListIndex'>
                          {snapshot.isDragging ? '' : index + 1}
                        </div>
                        <Paper style={{ flex: 1 }} elevation={snapshot.isDragging ? 24 : 0}>
                          {props.renderItem(item, index)}
                        </Paper>
                      </ListItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDListContainer;
