import { AxiosRequestConfig } from 'axios';
import {
  ArticleTemplateCreateView,
  ArticleTemplateListView,
  ArticleTemplateReadView,
  ArticleTemplateUpdateView,
  infinitePagination
} from '../types/types';
import { http } from '../http/http';
import { ENDPOINTS } from '../http/endpoints';

export const getArticleTemplates = async (config?: AxiosRequestConfig): Promise<ArticleTemplateListView> => {
  const { data } = await http.get<ArticleTemplateListView>(ENDPOINTS.articleTemplates, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
};

export const createArticleTemplate = async (artTemp: ArticleTemplateCreateView): Promise<ArticleTemplateReadView> => {
  const { data } = await http.post<ArticleTemplateReadView>(ENDPOINTS.articleTemplates, artTemp);
  return data;
};

export const updateArticleTemplate = async (
  templateId: number,
  payload: ArticleTemplateUpdateView
): Promise<ArticleTemplateReadView> => {
  const { data } = await http.patch<ArticleTemplateReadView>(`${ENDPOINTS.articleTemplates}/${templateId}`, payload);
  return data;
};

export const updateSourceTemplates = async (templateId: number, idsToDelete: number[], idsToPut: number[]) => {
  const deleteResult = await Promise.all(
    idsToDelete.map(
      async (id) => await http.delete(`${ENDPOINTS.articleTemplates}/${templateId}/source-templates/${id}`)
    )
  );
  const putResult = await Promise.all(
    idsToPut.map(
      async (sourceTemplateId) =>
        await http.put(`${ENDPOINTS.articleTemplates}/${templateId}/source-templates`, { sourceTemplateId })
    )
  );
  return { deleteResult, putResult };
};

export const getArticleTemplateById = async (
  id: string | number,
  config?: AxiosRequestConfig
): Promise<ArticleTemplateReadView> => {
  const { data } = await http.get<ArticleTemplateReadView>(`${ENDPOINTS.articleTemplates}/${id}`, config);
  return data;
};

export const getArticleTemplatesByOrganisationId = async (
  organisationId: string | number,
  config?: AxiosRequestConfig
): Promise<ArticleTemplateReadView[]> => {
  const { data } = await http.get<ArticleTemplateListView>(`${ENDPOINTS.articleTemplates}`, {
    params: { organisationId, ...infinitePagination },
    ...config
  });
  return data.records;
};

export const deleteArticleTemplate = async (id: string | number): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.articleTemplates}/${id}`);
  return status;
};
