import axios, { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import { TokenReadView, UserCreateView, UserListView, UserReadView, infinitePagination } from '../types/types';
import { ENV_VARIABLES } from '../config';

export const getUsers = async (config?: AxiosRequestConfig): Promise<UserListView> => {
  const { data } = await http.get<UserListView>(ENDPOINTS.users, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
  //await new Promise((resolve) => setTimeout(resolve, 800));
  //return dummyUsers;
};

export const createUser = async (user: UserCreateView): Promise<UserReadView> => {
  const { data } = await http.post<UserReadView>(ENDPOINTS.users, user);
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyUsers.records[0];
};

export const updateUser = async (
  userId: string | number,
  user: { email?: string; password?: string }
): Promise<UserReadView> => {
  const { data } = await http.patch<UserReadView>(`${ENDPOINTS.users}/${userId}`, user);
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyUsers.records[0];
};

export const getUserById = async (id: string, config?: AxiosRequestConfig): Promise<UserReadView> => {
  const { data } = await http.get<UserReadView>(`${ENDPOINTS.users}/${id}`, config);
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyUsers.records.find((r) => r.id === Number(id)) ?? dummyUsers.records[0];
};

export const deleteUser = async (id: string | number): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.users}/${id}`);
  return status;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return 200;
};

export const getUsersByOrganisationId = async (organisationId: string | number): Promise<UserReadView[]> => {
  const { data } = await http.get<UserListView>(ENDPOINTS.users, {
    params: { organisationId: Number(organisationId), ...infinitePagination }
  });
  return data.records;
  //await new Promise((resolve) => setTimeout(resolve, 800));
  // return dummyUsers.records.slice(0, 5);
};

export const getSelf = async (config?: AxiosRequestConfig): Promise<UserReadView> => {
  const { data } = await http.get<UserReadView>(`/self`, config);
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return { id: 0, email: 'admin@test.com', isSuperUser: true };
};

export const getAuth = async (email: string, password: string): Promise<TokenReadView> => {
  const { data } = await axios.post<TokenReadView>(`${ENV_VARIABLES.apiBaseUrl}/v1/auth`, { email, password });
  return data;
  // await new Promise((resolve) => setTimeout(resolve, 800));
  // return { token: 'some-dummy-token-value', refreshToken: 'some-dummy-refresh-token-value' };
};

export const logout = async (sessionId: string): Promise<void> => {
  await http.post(`${ENDPOINTS.logout}`, { sessionId: sessionId });
  return;
};

/* const dummyUsers: UserListView = {
  limit: Number.MAX_SAFE_INTEGER,
  offset: 0,
  totalCount: 35,
  records: Array.from({ length: 35 }, () => {
    const id = Math.floor(Math.random() * 10000);
    return { id, email: 'user' + id + 'id@org-name.com', isSuperUser: false };
  })
}; */
