import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppShellContext } from '../../shell/AppShellContext';
import HeaderBackButton from '../../common/HeaderBackButton';
import { UserReadView } from '../../../types/types';
import { useGlobalState } from '../../../context/GlobalState';
import { getUsers, updateUser } from '../../../services/Users';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import KeyIcon from '@mui/icons-material/Key';
import { isEmail } from '../../../utils/HelperFunctions';

const EditUser = () => {
  const globalState = useGlobalState();
  const params = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const appShellContext = useAppShellContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserReadView>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    appShellContext.setHeaderLeft(
      <HeaderBackButton
        onClick={() => {
          navigate('/admin/users', { replace: true });
        }}
      ></HeaderBackButton>
    );
    if (!params.userId) {
      toast.error('Something went wrong. Please refresh the page');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const users = await getUsers();
        if (!users) {
          toast.error('Something went wrong. Please refresh the page');
          setLoading(false);
          return;
        }
        const theUser = users.records.find((record) => record.id === Number(params.userId));
        if (!theUser) {
          toast.error('Something went wrong. Please refresh the page');
          setLoading(false);
          return;
        }
        setUser(theUser);
        setEmail(theUser.email);
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidity = (): { email?: string; password?: string } | null => {
    if (email.trim() === '' || !isEmail(email)) return null;
    if (password.trim() === '') return { email };
    return { email, password };
  };

  const editUser = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!params.userId) {
      toast.error('Something went wrong. Please refresh the page');
      return;
    }
    const userObject = checkValidity();
    if (!userObject) {
      toast.warn('Something went wrong. Please refresh the page');
      return;
    }
    setLoading(true);
    try {
      const theUSer = await updateUser(params.userId, userObject);
      toast.success('Användaren uppdaterad!');
      setUser(theUSer);
      setEmail(theUSer.email);
      setPassword('');
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const changeHappened = user && (user.email !== email.trim() || password.trim() !== '');

  return (
    <Box sx={{ p: '2rem 1rem 0 1rem', width: '100%' }}>
      <Box>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Redigera användaren
        </Typography>
      </Box>
      <form onSubmit={(e) => editUser(e)}>
        <Grid container justifyContent={'flex-start'} spacing={4} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl variant='outlined' required fullWidth>
              <InputLabel htmlFor='user-email-input'>E-post</InputLabel>
              <OutlinedInput
                id='user-email-input'
                type='email'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end'>
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='E-post'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel htmlFor='user-password-input'>Lösenord</InputLabel>
              <OutlinedInput
                id='user-password-input'
                type='text'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end'>
                      <KeyIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='Lösenord'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} spacing={5} sx={{ mt: '3rem' }}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setEmail(user!.email);
                setPassword('');
              }}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'ångra'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default EditUser;
