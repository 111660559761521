import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import './LoginScreen.scss';
import { LoginOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { useGlobalState } from '../../context/GlobalState';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { isEmail } from '../../utils/HelperFunctions';
import { getAuth, getSelf } from '../../services/Users';

const LoginScreen = () => {
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    if (!isEmail(email) || password.trim().length === 0) {
      toast.error('kontrollera  e-post och lösenord');
      return;
    }
    setLoading(true);
    try {
      const data = await getAuth(email, password);
      if (data) {
        localStorage.setItem('tractech_token', data.token);
        localStorage.setItem('tractech_refresh_token', data.refreshToken ?? '');
        localStorage.setItem('tractech_session_id', data.sessionId);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const user = await getSelf();
        globalState.setUser(user);
        localStorage.setItem('tractech_current_user', JSON.stringify(user));
        setLoading(false);
        navigate('/', { replace: true });
      }
    } catch (error) {
      setLoading(false);
      console.log('ERROR:', error);
      globalState.handleResponseError(error as AxiosError);
    }
  };

  return (
    <Grid container className='login-screen-container' justifyContent={'center'}>
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <form onSubmit={() => handleLogin()}>
          <FormControl fullWidth sx={{ marginTop: '4rem' }} variant='outlined'>
            <InputLabel htmlFor='outlined-adornment-email'>E-postadress</InputLabel>
            <OutlinedInput
              id='outlined-adornment-email'
              type='email'
              label='E-postadress'
              autoComplete='off'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: '2rem' }} variant='outlined'>
            <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
            <OutlinedInput
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label='Lösenord'
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </FormControl>
          <Button
            fullWidth
            startIcon={loading ? <CircularProgress color='primary' /> : <LoginOutlined />}
            variant='contained'
            color='secondary'
            sx={{ marginTop: '3rem', height: '3rem', textTransform: 'none' }}
            onClick={handleLogin}
            disabled={loading}
            type='submit'
          >
            Logga in
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
