import AppRoutes from './router/AppRoutes';
//____ROBOTO FONTS_____
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//_____MUI THEME_____
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
//____Toastify_____
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGlobalState } from './context/GlobalState';

function App() {
  const globalState = useGlobalState();

  return (
    <ThemeProvider theme={globalState.muiTheme}>
      <CssBaseline />
      <AppRoutes />
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar
        closeOnClick
        pauseOnHover={false}
        transition={Bounce}
        theme={'colored'}
      />
    </ThemeProvider>
  );
}
export default App;
