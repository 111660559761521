import { Alert, Box, Button, CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../../context/GlobalState';
import { useParams } from 'react-router-dom';
import { OrganisationReadView } from '../../../types/types';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { getOrganisationById, updateOrganisation } from '../../../services/Organisations';
import ComponentLoader from '../../common/componentLoader/ComponentLoader';
import { AxiosError } from 'axios';

const OrganisationSettings = () => {
  const globalState = useGlobalState();
  const params = useParams<{ orgId: string }>();
  const [disabled, setDisabled] = useState<boolean>();
  const [customUiEnabled, setCustomUiEnabled] = useState<boolean>();
  const [homeScreenEnabled, setHomeScreenEnabled] = useState<boolean>();
  const [customerCardEnabled, setFullDescriptionEnabled] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<OrganisationReadView>();

  useEffect(() => {
    if (!params.orgId) {
      toast.error('Something went wrong. Please refresh the page!');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        setOrganisation(_organisation);
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
  }, [globalState, params.orgId]);

  useEffect(() => {
    setCustomUiEnabled(organisation?.customUiEnabled);
    setHomeScreenEnabled(organisation?.homeScreenEnabled);
    setFullDescriptionEnabled(organisation?.customerCardEnabled);
    setDisabled(organisation?.disabled);
  }, [organisation]);

  const changeHappened =
    organisation?.disabled !== disabled ||
    organisation?.homeScreenEnabled !== homeScreenEnabled ||
    organisation?.customUiEnabled !== customUiEnabled ||
    organisation?.customerCardEnabled !== customerCardEnabled;

  const resolveForm = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!organisation) {
      toast.error('Something went wrong!');
      return;
    }
    setLoading(true);
    try {
      const { id, articleTemplateCount, ...data } = organisation;
      const _organisation = await updateOrganisation(id, {
        ...data,
        customUiEnabled: customUiEnabled ?? false,
        homeScreenEnabled: homeScreenEnabled,
        customerCardEnabled: customerCardEnabled,
        disabled: disabled ?? false
      });
      setOrganisation(_organisation);
      toast.success('Organisationen är uppdaterad');
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const revertChanges = () => {
    setCustomUiEnabled(organisation?.customUiEnabled);
    setHomeScreenEnabled(organisation?.homeScreenEnabled);
    setFullDescriptionEnabled(organisation?.customerCardEnabled);
    setDisabled(organisation?.disabled);
  };

  return !loading && organisation ? (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, p: 1.5 }}>
        <Typography variant='h6'>Hantera inställningar för {organisation.name}</Typography>
      </Box>

      <form onSubmit={resolveForm}>
        <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              value='Inaktivera organisationen'
              control={
                <Switch color='secondary' checked={disabled ?? false} onChange={() => setDisabled((prev) => !prev)} />
              }
              label='Inaktivera organisationen'
              labelPlacement='start'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              value='Aktivera custom theme'
              control={
                <Switch
                  color='secondary'
                  checked={customUiEnabled ?? false}
                  onChange={() => setCustomUiEnabled((prev) => !prev)}
                />
              }
              label='Aktivera custom theme'
              labelPlacement='start'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              value='Visa på hemskärmen'
              control={
                <Switch
                  color='secondary'
                  checked={homeScreenEnabled ?? false}
                  onChange={() => setHomeScreenEnabled((prev) => !prev)}
                />
              }
              label='Visa på hemskärmen'
              labelPlacement='start'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              value='Aktivera företagskort'
              control={
                <Switch
                  color='secondary'
                  checked={customerCardEnabled ?? false}
                  onChange={() => setFullDescriptionEnabled((prev) => !prev)}
                />
              }
              label='Aktivera företagskort'
              labelPlacement='start'
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => revertChanges()}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  ) : loading ? (
    <ComponentLoader bgColor='#fff' />
  ) : (
    <Alert severity='error'>Failed loading the organisation content!</Alert>
  );
};

export default OrganisationSettings;
