import React, { useCallback, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';

type StateToggle = {
  current: boolean;
  reset: () => void;
};
type Props<T> = {
  options: T[];
  selected: T[];
  equalityFunction: (item1: T, item2: T) => boolean;
  display: (item: T) => string;
  onSelectedChange: (selectedItems: T[]) => void;
  clearState: StateToggle;
  sx?: SxProps<Theme>;
  placeholder: string;
};
const DropdownListSelector = <T,>(props: Props<T>) => {
  const [itemToAdd, setItemToAdd] = useState<T | null>(null);

  const handleRemoveItem = useCallback(
    (index: number) => {
      const updatedItems = [...props.selected];
      updatedItems.splice(index, 1);
      props.onSelectedChange(updatedItems);
    },
    [props]
  );

  const addItem = useCallback(() => {
    if (itemToAdd) {
      props.onSelectedChange([...props.selected, itemToAdd]);
    }
    setItemToAdd(null);
  }, [itemToAdd, props]);

  return (
    <div style={{ paddingRight: '0px' }}>
      <div style={{ display: 'flex' }}>
        <Autocomplete
          options={props.options.filter((option) => !props.selected.includes(option))}
          getOptionLabel={(option) => props.display(option)}
          renderOption={(properties, option) => <ListItem {...properties}>{props.display(option)}</ListItem>}
          renderInput={(params) => <TextField {...params} variant='standard' placeholder={props.placeholder} />}
          value={itemToAdd}
          isOptionEqualToValue={props.equalityFunction}
          onChange={(e, value) => {
            if (value !== itemToAdd) {
              setItemToAdd(value);
            }
          }}
          sx={{ ...props.sx, flex: '0.9' }}
        />
        <IconButton color='success' onClick={addItem} disabled={!itemToAdd} sx={{ flex: '0.1' }}>
          <AddCircleIcon />
        </IconButton>
      </div>
      <List>
        {props.selected.map((item, index) => (
          <ListItem key={index}>
            <ListItemText primary={props.display(item)} />
            <IconButton onClick={() => handleRemoveItem(index)} color='secondary'>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DropdownListSelector;
