import { Box, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppShellContext } from '../../shell/AppShellContext';
import { useEffect, useState } from 'react';
import HeaderBackButton from '../../common/HeaderBackButton';

import { useTheme } from '@mui/material/styles';
import WorkIcon from '@mui/icons-material/Work';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import EditOrganisation from './EditOrganisation';
import EditOrganisationUser from './EditOrganisationUser';
import { useWindowSize } from '../../../utils/HelperFunctions';
import ArticleTemplates from '../ArticleTemplates';
import OrganisationLinks from './OrganisationLinks';
import LinkIcon from '@mui/icons-material/Link';
import SettingsIcon from '@mui/icons-material/Settings';
import OrganisationSettings from './OrganisationSettings';
import CategoryIcon from '@mui/icons-material/Category';
import EditOrganisationCategories from './EditOrganisationCategories';
import GroupIcon from '@mui/icons-material/AccountTree';
import EditOrganisationGroups from './EditOrganisationGroups';
import EditOrganisationDescriptionCard from './EditOrganisationDescriptionCard';
import DescriptionCardIcon from '@mui/icons-material/ViewQuilt';

type Props = {};
type LocationState = { tabIndex: number };
const OrganisationTabs = (props: Props) => {
  const appShellContext = useAppShellContext();
  const navigate = useNavigate();
  const location = useLocation();
  const tabIndex = location.state ? (location.state as LocationState).tabIndex : null;
  const theme = useTheme();
  const { isMobileDevice } = useWindowSize();

  const [tabValue, setTabValue] = useState(tabIndex ?? 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    appShellContext.setHeaderLeft(
      <HeaderBackButton onClick={() => navigate('/admin/organisations', { replace: true })} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className='organisationTabsMainContainer'>
      <Box sx={{ width: '100%', borderBottom: 0.5, borderColor: 'divider', boxShadow: '0px 2px 12px 0px  grey' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='secondary'
          variant={isMobileDevice ? 'scrollable' : 'fullWidth'}
          aria-label='Organisation sections'
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            icon={<WorkIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Organisation'}
            {...a11yProps(0)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<DescriptionCardIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Företagskort'}
            {...a11yProps(0)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<CategoryIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Kategorier'}
            {...a11yProps(1)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<GroupIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Tillhörigheter'}
            {...a11yProps(2)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<PeopleAltIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Användare'}
            {...a11yProps(3)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<LinkIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Länkar'}
            {...a11yProps(4)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<DescriptionIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Artikelmallar'}
            {...a11yProps(5)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<SettingsIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Inställningar'}
            {...a11yProps(6)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} dir={theme.direction}>
        <EditOrganisation />
      </TabPanel>
      <TabPanel value={tabValue} index={1} dir={theme.direction}>
        <EditOrganisationDescriptionCard />
      </TabPanel>
      <TabPanel value={tabValue} index={2} dir={theme.direction}>
        <EditOrganisationCategories />
      </TabPanel>
      <TabPanel value={tabValue} index={3} dir={theme.direction}>
        <EditOrganisationGroups />
      </TabPanel>
      <TabPanel value={tabValue} index={4} dir={theme.direction}>
        <EditOrganisationUser />
      </TabPanel>
      <TabPanel value={tabValue} index={5} dir={theme.direction}>
        <OrganisationLinks />
      </TabPanel>
      <TabPanel value={tabValue} index={6} dir={theme.direction}>
        <ArticleTemplates />
      </TabPanel>
      <TabPanel value={tabValue} index={7} dir={theme.direction}>
        <OrganisationSettings />
      </TabPanel>
    </Box>
  );
};

export default OrganisationTabs;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ overflow: 'auto' }}
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}
