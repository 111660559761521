import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Stack
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';
import { CustomNavigateOptions, ScanMode } from '../../types/types';
import Switch from '@mui/material/Switch';
import ClassifierSelector, { CategorySelectorHorizontal } from './ClassifierSelector';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { searchEvent } from '../../services/Analytics';
import { useHomeContext } from '../../context/HomeContext';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MapControl from './MapControl';
import { grey } from '@mui/material/colors';
import './Map.scss';

type Props = {
  map: google.maps.Map | null;
  satellite: boolean;
  setSatellite: React.Dispatch<React.SetStateAction<boolean>>;
  displayCounter: { num: number; max: number };
  isMobileDevice?: boolean;
};
const HomeScreenOverlay = (props: Props) => {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const homeCtx = useHomeContext();

  const searchBarDivRef = useRef<HTMLDivElement>(null);

  const [resetCategoryFilters, setResetCategoryFilters] = useState<boolean>(false);
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>();
  const [showFilters, setShowFilters] = useState<boolean>(false); // Manage filter visibility
  const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(true);

  useEffect(() => {
    if (homeCtx.selectedOrganisation) {
      setShowGlobalSearch(false);
    }
  }, [homeCtx.selectedOrganisation]);

  useEffect(() => {
    const div = props.map?.controls[google.maps.ControlPosition.RIGHT_CENTER].pop();
    if (div) props.map?.controls[google.maps.ControlPosition.RIGHT_CENTER].push(div);
  }, [homeCtx.classifiers, props.map?.controls]);

  const _onFabClick = () => {
    globalState.setScanMode(ScanMode.SINGLE);
    globalState.setMenuMode(undefined);
    navigate('/scanner', { state: { prevPage: '/' }, replace: true } as CustomNavigateOptions);
  };

  const _clearFilters = useCallback(() => {
    homeCtx.setSearchQuery('');
    homeCtx.resetCategoryFilters();
  }, [homeCtx]);

  useEffect(() => {
    clearTimeout(searchTimer);

    if (homeCtx.searchQuery.length > 3) {
      setSearchTimer(
        setTimeout(() => {
          searchEvent(homeCtx.searchQuery);
        }, 2000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeCtx.searchQuery]);

  return (
    <>
      <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
        <Fab
          size='large'
          color='primary'
          aria-label='scan'
          sx={{ bottom: '1.5rem', pointerEvents: 'auto' }}
          onClick={_onFabClick}
          disabled={homeCtx.loading}
        >
          {homeCtx.loading ? (
            <CircularProgress color='secondary' />
          ) : (
            <QrCodeScannerIcon color='inherit' fontSize='large' />
          )}
        </Fab>
      </MapControl>
      <MapControl position={google.maps.ControlPosition.TOP_LEFT}>
        <Box
          sx={{
            backgroundColor: 'rgba(255,255,255,0.5)',
            marginTop: '1rem',
            marginLeft: '1.5rem',
            paddingLeft: '1rem',
            borderRadius: '1rem'
          }}
        >
          <FormGroup sx={{}}>
            <FormControlLabel
              control={
                <Switch size='medium' checked={props.satellite} onChange={() => props.setSatellite(!props.satellite)} />
              }
              label={props.satellite ? 'Satellite' : 'Map'}
              sx={{ pointerEvents: 'auto' }}
            />
          </FormGroup>
        </Box>
      </MapControl>

      {showGlobalSearch ? (
        <Box
          sx={{
            position: 'absolute',
            top: '25%',
            left: props.isMobileDevice ? 0 : '50%',
            transform: props.isMobileDevice ? undefined : 'translate(-50%, -50%)',
            textAlign: 'center',
            p: 1,
            borderRadius: '1rem',
            bgcolor: grey[200],
            zIndex: 9999,
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
          }}
        >
          <div className='mapSearchBar'>
            <Box className='filterControlsBox'>
              <FormGroup sx={{ width: '100%' }}>
                <FormControlLabel
                  sx={{}}
                  control={
                    <>
                      <input
                        type='text'
                        style={{ fontSize: '16pt' }}
                        placeholder='Sök...'
                        className='searchField'
                        onChange={(e) => {
                          if (!homeCtx.loading) homeCtx.setSearchQuery(e.target.value);
                        }}
                        value={homeCtx.searchQuery}
                      />
                      {homeCtx.searchQuery ? (
                        <IconButton
                          aria-label='clear'
                          onClick={() => {
                            homeCtx.setSearchQuery('');
                            searchBarDivRef.current && searchBarDivRef.current.blur();
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </>
                  }
                  label={null}
                />
              </FormGroup>
            </Box>
          </div>
          <CategorySelectorHorizontal
            sx={{ textAlign: 'center' }}
            clearState={{
              current: resetCategoryFilters,
              reset: () => {
                setResetCategoryFilters(false);
              }
            }}
          />
          <Stack spacing={2} direction='row' sx={{ mt: 3 }}>
            <Button
              variant='contained'
              onClick={() => setShowGlobalSearch(false)}
              sx={{ textTransform: 'none', width: '100%' }}
            >
              {`Se ${props.displayCounter && props.displayCounter.num && `${props.displayCounter.num}`} företag `}
            </Button>
            <Button onClick={_clearFilters} sx={{ textTransform: 'none', width: '100%' }} variant='contained'>
              Rensa filter
            </Button>
          </Stack>
        </Box>
      ) : props.isMobileDevice ? (
        <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
            className='fadeInComponent'
          >
            <IconButton
              size='large'
              sx={{
                mr: 2,
                mt: 2,
                bgcolor: 'primary.main',
                color: 'white',
                alignSelf: 'flex-end',
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: 'white'
                },
                pointerEvents: 'auto'
              }}
              onClick={() => setShowFilters((prev) => !prev)}
            >
              {showFilters ? <ChevronRightIcon /> : <ManageSearchIcon />}
            </IconButton>
            {showFilters && (
              <>
                <div className='mapSearchBar'>
                  <Box className='filterControlsBox'>
                    <FormGroup sx={{ maxWidth: '50vw' }}>
                      <FormControlLabel
                        sx={{ width: '20rem' }}
                        control={
                          <>
                            <input
                              type='text'
                              style={{ fontSize: '16pt', maxWidth: '40vw', pointerEvents: 'auto' }}
                              placeholder='Sök...'
                              className='searchField'
                              onChange={(e) => {
                                if (!homeCtx.loading) homeCtx.setSearchQuery(e.target.value);
                              }}
                              value={homeCtx.searchQuery}
                            />
                            {homeCtx.searchQuery ? (
                              <IconButton
                                aria-label='clear'
                                sx={{ pointerEvents: 'auto' }}
                                onClick={() => {
                                  homeCtx.setSearchQuery('');
                                  searchBarDivRef.current && searchBarDivRef.current.blur();
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            ) : (
                              <SearchIcon />
                            )}
                          </>
                        }
                        label={<></>}
                      />
                      {props.displayCounter && (
                        <FormLabel>{`${props.displayCounter.num} av ${props.displayCounter.max} visas`}</FormLabel>
                      )}
                      <Button
                        onClick={_clearFilters}
                        sx={{ width: '100%', alignContent: 'center', pointerEvents: 'auto' }}
                      >
                        Rensa filter
                      </Button>
                    </FormGroup>
                  </Box>
                </div>
                <div>
                  <ClassifierSelector
                    sx={{
                      backgroundColor: 'rgba(255,255,255,0.5)',
                      marginRight: '1rem',
                      borderRadius: '1rem',
                      pointerEvents: 'auto'
                    }}
                    clearState={{
                      current: resetCategoryFilters,
                      reset: () => {
                        setResetCategoryFilters(false);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </Box>
        </MapControl>
      ) : (
        <>
          <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
            <Box className='filterControlsBox fadeInComponent'>
              <FormGroup sx={{ maxWidth: '50vw' }}>
                <FormControlLabel
                  sx={{ width: '20rem' }}
                  control={
                    <>
                      <input
                        type='text'
                        style={{ fontSize: '16pt', maxWidth: '40vw', pointerEvents: 'auto' }}
                        placeholder='Sök...'
                        className='searchField'
                        onChange={(e) => {
                          if (!homeCtx.loading) homeCtx.setSearchQuery(e.target.value);
                        }}
                        value={homeCtx.searchQuery}
                      />
                      {homeCtx.searchQuery ? (
                        <IconButton
                          aria-label='clear'
                          sx={{ pointerEvents: 'auto' }}
                          onClick={() => {
                            homeCtx.setSearchQuery('');
                            searchBarDivRef.current && searchBarDivRef.current.blur();
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </>
                  }
                  label={<></>}
                />
                {props.displayCounter && (
                  <FormLabel>{`${props.displayCounter.num} av ${props.displayCounter.max} visas`}</FormLabel>
                )}
                <Button onClick={_clearFilters} sx={{ width: '100%', alignContent: 'center', pointerEvents: 'auto' }}>
                  Rensa filter
                </Button>
              </FormGroup>
            </Box>
          </MapControl>
          <MapControl position={google.maps.ControlPosition.RIGHT_CENTER}>
            <Box className='fadeInComponent'>
              <ClassifierSelector
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  marginLeft: '1.5rem',
                  borderRadius: '1rem',
                  pointerEvents: 'auto'
                }}
                clearState={{
                  current: resetCategoryFilters,
                  reset: () => {
                    setResetCategoryFilters(false);
                  }
                }}
              />
            </Box>
          </MapControl>
        </>
      )}
    </>
  );
};

export default HomeScreenOverlay;
