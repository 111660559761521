export enum ENDPOINTS {
  auth = 'auth',
  logout = 'logout',
  articleByAlternateId = 'article-by-alternate-id',
  categories = 'category',
  cloneArticle = 'clone-article',
  articleTemplates = 'article-templates',
  articleByTemplate = 'article-by-template',
  articleByUrl = 'article-by-url',
  articles = 'articles',
  descriptionCards = 'organisation-description-cards',
  descriptionCardByOrgId = 'organisation-description-cards/by-organisation',
  exportOrganisations = 'export/organisations',
  importOrganisations = 'import/organisations',
  locations = 'locations',
  organisations = 'organisations',
  organisationsInfo = 'organisations-info',
  organisationsMapInfo = 'organisation-map-info',
  organisationsSummary = 'organisations-summary',
  users = 'users',
  articleGraph = 'article-graph',
  urls = 'urls',
  urlByUrl = 'url-by-url',
  alternateId = 'alternate-id',
  groups = 'group',
  groupsSummary = 'group-summaries',
  groupByShortName = 'group-short'
}
