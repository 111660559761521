import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import {
  OrganisationDescriptionCardCreateView,
  OrganisationDescriptionCardReadView,
  OrganisationDescriptionCardUpdateView
} from '../types/types';

export const createDescriptionCard = async (
  descriptionCard: OrganisationDescriptionCardCreateView
): Promise<OrganisationDescriptionCardReadView> => {
  const { data } = await http.post<OrganisationDescriptionCardReadView>(ENDPOINTS.descriptionCards, descriptionCard);
  return data;
};

export const readDescriptionCard = async (cardId: number | string): Promise<OrganisationDescriptionCardReadView> => {
  const { data } = await http.get<OrganisationDescriptionCardReadView>(`${ENDPOINTS.descriptionCards}/${cardId}`);
  return data;
};

export const updateDescriptionCard = async (
  cardId: number | string,
  descriptionCard: OrganisationDescriptionCardUpdateView
): Promise<OrganisationDescriptionCardReadView> => {
  const { data } = await http.patch<OrganisationDescriptionCardReadView>(
    `${ENDPOINTS.descriptionCards}/${cardId}`,
    descriptionCard
  );
  return data;
};

export const deleteDescriptionCard = async (cardId: number | string): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.descriptionCards}/${cardId}`);
  return status;
};

export const getDescriptionCardByOrgId = async (
  organisationId: number | string
): Promise<OrganisationDescriptionCardReadView> => {
  const { data } = await http.get<OrganisationDescriptionCardReadView>(
    `${ENDPOINTS.descriptionCardByOrgId}/${organisationId}`
  );
  return data;
};
