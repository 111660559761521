import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import { UrlCreateView, UrlListView, UrlReadView, UrlUpdateView, infinitePagination } from '../types/types';

export const getUrls = async (config?: AxiosRequestConfig): Promise<UrlReadView[]> => {
  const { data } = await http.get<UrlListView>(ENDPOINTS.urls, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data.records;
};

export const getUrlsByOrganisationId = async (
  organisationId: number,
  config?: AxiosRequestConfig
): Promise<UrlReadView[]> => {
  const { data } = await http.get<UrlListView>(ENDPOINTS.urls, {
    ...config,
    params: { ...config?.params, ...infinitePagination, organisationId }
  });
  return data.records;
};

export const getUrlByUrl = async (url: string, config?: AxiosRequestConfig): Promise<UrlReadView> => {
  const { data } = await http.get<UrlReadView>(`${ENDPOINTS.urlByUrl}/${encodeURIComponent(url)}`, {
    ...config
  });
  return data;
};

export const getUrl = async (id: number): Promise<UrlReadView> => {
  const { data } = await http.get<UrlReadView>(`${ENDPOINTS.urls}/${id}`);
  return data;
};

export const createUrl = async (
  organisationId: number,
  payload: UrlCreateView,
  config?: AxiosRequestConfig
): Promise<UrlReadView> => {
  const { data } = await http.post<UrlReadView>(ENDPOINTS.urls, payload, config);
  return data;
};

export const patchUrl = async (
  organisationId: number,
  urlId: number,
  payload: UrlUpdateView,
  config?: AxiosRequestConfig
): Promise<UrlReadView> => {
  const { data } = await http.patch<UrlReadView>(`${ENDPOINTS.urls}/${urlId}`, payload, config);
  return data;
};

export const deleteUrl = async (urlId: number, config?: AxiosRequestConfig) => {
  const { status } = await http.delete(`${ENDPOINTS.urls}/${urlId}`, config);
  return status;
};
