import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MembershipRole, OrganisationReadView, UserReadView } from '../../../types/types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { toast } from 'react-toastify';
import {
  addUserToOrganisation,
  deleteUserFromOrganisation,
  getOrganisationById
} from '../../../services/Organisations';
import { getUsers, getUsersByOrganisationId } from '../../../services/Users';
import { AxiosError } from 'axios';
import { useGlobalState } from '../../../context/GlobalState';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';

const EditOrganisationUser = () => {
  const globalState = useGlobalState();
  const params = useParams<{ orgId: string }>();
  const [userToRemove, setUserToRemove] = useState<UserReadView | null>(null);
  const [userToAdd, setUserToAdd] = useState<UserReadView | null>(null);
  const [allUsers, setAllUsers] = useState<UserReadView[]>([]);
  const [orgUsers, setOrgUsers] = useState<UserReadView[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<OrganisationReadView>();

  useEffect(() => {
    if (!params.orgId) {
      //toast.error('Something went wrong! please refresh the page');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        const _usersByOrg = await getUsersByOrganisationId(params.orgId!);
        const _allUsers = await getUsers();
        setOrganisation(_organisation);
        setOrgUsers(_usersByOrg);
        setAllUsers(_allUsers.records);
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId]);

  const removeUser = async () => {
    if (!userToRemove || !params.orgId) {
      toast.warn('Something went wrong please try again later!');
      return;
    }
    setLoading(true);
    try {
      const status = await deleteUserFromOrganisation(params.orgId, userToRemove.id);
      setUserToRemove(null);
      console.log('Remove user status:', status);
      toast.success('Användare borttagen');
      const users = await getUsersByOrganisationId(params.orgId);
      setOrgUsers(users);
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };
  const addUser = async () => {
    if (!userToAdd || !params.orgId) {
      toast.warn('Something went wrong please try again later!');
      return;
    }
    setLoading(true);
    try {
      const data = await addUserToOrganisation(params.orgId, userToAdd.id, {
        role: userToAdd.isSuperUser ? MembershipRole.ADMIN : MembershipRole.USER
      });
      toast.success(`Användare ${data.userId}:${userToAdd.email} har lagts till`);
      setUserToAdd(null);
      const users = await getUsersByOrganisationId(params.orgId);
      setOrgUsers(users);
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{ p: 1.5 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        Hantera användare för {organisation?.name}
      </Typography>

      <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <React.Fragment>
            <ListItem alignItems='center' sx={{ marginBottom: 2 }}>
              <Autocomplete
                id='size-small-standard'
                options={allUsers}
                getOptionLabel={(option) => option.email}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='standard'
                    label={'Lägg till en användare'}
                    placeholder='Välj användare'
                  />
                )}
                onChange={(e, value) => {
                  setUserToAdd(value);
                }}
                value={userToAdd}
                disabled={organisation?.disabled}
              />
              <IconButton
                color='success'
                onClick={() => addUser()}
                disabled={!userToAdd || loading || organisation?.disabled}
              >
                {loading ? <CircularProgress size={15} color='secondary' /> : <AddCircleIcon />}
              </IconButton>
            </ListItem>
          </React.Fragment>
          <List dense sx={{ width: '100%' }}>
            {orgUsers.map((user, index) => (
              <React.Fragment key={`${user.id}${index}`}>
                <ListItem key={index} alignItems='center'>
                  <ListItemText primary={user.email} secondary={`id: ${user.id}`}></ListItemText>
                  <IconButton
                    color='error'
                    onClick={() => setUserToRemove(user)}
                    disabled={loading || organisation?.disabled}
                  >
                    {loading ? <CircularProgress size={15} color='secondary' /> : <RemoveCircleIcon />}
                  </IconButton>
                </ListItem>
                <Divider component='li' />
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
      {userToRemove && (
        <Dialog
          open={userToRemove !== null}
          onClose={() => setUserToRemove(null)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Ta bort användare?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Vill du ta bort användaren {userToRemove.id}: {userToRemove.email} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button color='info' variant='contained' startIcon={<ClearIcon />} onClick={() => setUserToRemove(null)}>
              Avbryt
            </Button>
            <Button color='error' variant='contained' startIcon={<DeleteIcon />} onClick={() => removeUser()}>
              Ta bort
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};
export default EditOrganisationUser;
