import { useCallback, useEffect, useState } from 'react';
import { GroupMembershipReadView, GroupReadView, OrganisationReadView } from '../../../types/types';
import { useParams } from 'react-router';
import { getOrganisationById } from '../../../services/Organisations';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  Grid,
  CircularProgress,
  Typography,
  Tooltip,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  ButtonGroup,
  Box
} from '@mui/material';
import { DataGrid, GridSelectionModel } from '@mui/x-data-grid';
import { isMobileDevice } from '../../../utils/HelperFunctions';
import ClearIcon from '@mui/icons-material/Clear';

import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {
  deleteGroupMembership,
  getGroups,
  getGroupsForOrganisation,
  joinGroup,
  updateGroupMembership
} from '../../../services/Groups';
import DnDFileInput from '../../common/DnDFileInput';
import { toast } from 'react-toastify';

const EditOrganisationGroups = () => {
  const params = useParams<{ orgId: string }>();

  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [allGroups, setAllGroups] = useState<GroupReadView[]>([]);
  const [joinedGroups, setJoinedGroups] = useState<number[]>([]);
  const [memberships, setMemberships] = useState<GroupMembershipReadView[]>([]);
  const [updatedMemberships, setUpdatedMemberships] = useState<GroupMembershipReadView[]>([]);
  const [uploadingIcon, setUploadingIcon] = useState<number>();
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const reset = useCallback(() => {
    if (params.orgId) {
      setLoading(true);
      setUpdatedMemberships([]);
      getGroups().then((res) => setAllGroups(res.records));
      getOrganisationById(params.orgId)
        .then((res) => {
          setOrganisation(res);
          getGroupsForOrganisation(res.id).then((res) => {
            setMemberships(res);
            setJoinedGroups(res.map((membership) => membership.groupId));
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.orgId]);

  useEffect(() => {
    reset();
  }, [params.orgId, reset]);

  const changedHappened = useCallback(() => {
    const current = memberships.map((membership) => membership.id);
    if (current.length !== joinedGroups.length) {
      return true;
    }
    return updatedMemberships.length > 0;
  }, [memberships, joinedGroups, updatedMemberships]);

  const handleSelectionChange = useCallback(
    (selectionModel: GridSelectionModel) => {
      if (organisation) {
        const currentGroups = memberships.map((mem) => mem.groupId);
        const newMemberships: GroupMembershipReadView[] = selectionModel
          .filter((id) => !currentGroups.includes(Number(id)))
          .map((id) => {
            return {
              id: -1,
              groupId: Number(id),
              organisationId: organisation?.id,
              icon: undefined
            };
          });
        setUpdatedMemberships((prev) => {
          // remove unselected ones
          const included = prev.filter((membership) => selectionModel.includes(membership.groupId));

          return [...included, ...newMemberships];
        });
        setJoinedGroups(selectionModel.map(Number));
      }
    },
    [memberships, organisation]
  );

  const getIcon = useCallback(
    (id: number) => {
      return (
        updatedMemberships.find((mem) => mem.groupId === id)?.icon ??
        memberships.find((mem) => mem.groupId === id)?.icon
      );
    },
    [updatedMemberships, memberships]
  );

  const save = useCallback(async () => {
    if (organisation) {
      setLoading(true);
      const currentGroups = memberships.map((mem) => mem.groupId);
      Promise.all(
        updatedMemberships.map(async (membership) => {
          if (currentGroups.includes(membership.groupId)) {
            // update
            updateGroupMembership(membership.groupId, organisation?.id, { icon: membership.icon });
          } else {
            joinGroup(membership.groupId, organisation.id, { icon: membership.icon });
          }
        })
      )
        .then(() => {
          Promise.all(
            currentGroups
              .filter((id) => !joinedGroups.includes(id))
              .map(async (toDelete) => {
                deleteGroupMembership(toDelete, organisation.id);
              })
          );
        })
        .catch((reason) => {
          toast.error('Ett fel inträffade');
        })
        .then(() => {
          toast.success('Ändringar sparades');
        })
        .finally(() => reset());
    }
  }, [organisation, memberships, updatedMemberships, joinedGroups, reset]);

  const uploadIcon = useCallback(
    (groupId: number | undefined, icon: string | null) => {
      if (groupId && icon) {
        const membership = memberships.find((membership) => membership.groupId === groupId);
        if (membership) {
          setUpdatedMemberships((prev) => [...prev, { ...membership, icon }]);
        } else {
          setUpdatedMemberships((prev) =>
            prev.map((membership) => (membership.groupId === groupId ? { ...membership, icon } : membership))
          );
        }
        setUploadingIcon(undefined);
        setUploadedFile(null);
      }
    },
    [memberships]
  );

  const copyToClipBoard = (linkName: string) => {
    const link = `${window.location.origin}/group/${linkName}`;
    navigator.clipboard.writeText(link);
  };

  return (
    <Box sx={{ p: 1.5 }}>
      <Typography variant='h6'>{organisation?.name} Tillhörigheter</Typography>

      <Grid container direction='row' padding={2} display={'flex'} rowSpacing={isMobileDevice() ? '2.5rem' : '0'}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          alignContent={'center'}
          className='categoryDataGridContainer'
        >
          <DataGrid
            rows={allGroups ?? []}
            columns={[
              { field: 'name', headerName: 'Tillhörighetsnamn', flex: 1, align: 'center', headerAlign: 'center' },
              {
                field: 'logo',
                headerName: 'Logotyp',
                renderCell: (params) => (
                  <img src={params.row.logo} alt='Logotyp' style={{ maxHeight: '90%', maxWidth: '90%' }} />
                ),
                flex: 1,
                align: 'center',
                headerAlign: 'center'
              },
              {
                field: 'icon',
                headerName: 'Organisationsikon',
                renderCell: (params) => (
                  <Tooltip
                    title='Ladda upp en ny organisationsikon som representerar vad du erbjuder'
                    onClick={() => {
                      if (joinedGroups.includes(params.row.id)) setUploadingIcon(params.row.id);
                    }}
                  >
                    <img
                      src={getIcon(params.row.id) ?? params.row.icon}
                      alt='Organisationsikon'
                      style={{ maxHeight: '90%', maxWidth: '90%' }}
                    />
                  </Tooltip>
                ),
                flex: 1,
                align: 'center',
                headerAlign: 'center'
              },
              {
                field: 'actions',
                headerName: '',
                renderCell: (params) => (
                  <ButtonGroup>
                    <Tooltip title='kopiera länken till urklipp'>
                      <Button variant='contained' color='success' onClick={() => copyToClipBoard(params.row.shortName)}>
                        <ContentCopyIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                )
              }
            ]}
            selectionModel={joinedGroups}
            checkboxSelection
            disableSelectionOnClick
            rowsPerPageOptions={[100]}
            keepNonExistentRowsSelected
            onSelectionModelChange={handleSelectionChange}
          />
        </Grid>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }} padding={2}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={save}
              disabled={loading || !changedHappened()}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={reset}
              disabled={loading || !changedHappened()}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={uploadingIcon !== undefined} onClose={() => setUploadingIcon(undefined)}>
        <DialogTitle id='upload-icon-title'>{'Upload icon'}</DialogTitle>
        <DialogContent>
          <DnDFileInput
            onFileChange={setUploadedFile}
            text='Välj ett tillhörighetikon'
            accept={{ 'image/png': ['.png'] }}
          />
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<SaveIcon />}
            onClick={() => {
              uploadIcon(uploadingIcon, uploadedFile);
            }}
          >
            UPPDATERA
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={async () => {
              setUploadedFile(null);
              setUploadingIcon(undefined);
            }}
          >
            Ångra
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default EditOrganisationGroups;
