import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import qrCodeHeader from '../../utils/img/qrCodeHeader.png';
import qrCodeFooter from '../../utils/img/qrCodeFooter.png';
import './AdminPanelScreen.scss';
import { chain, logo } from '../shell/PrintModal';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { downloadTextFile } from '../../utils/HelperFunctions';

const QrCodeGenerator = () => {
  const [value, setValue] = useState<number>();
  const [qrCodes, setQrCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const _onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.value === '') {
      setValue(undefined);
      return;
    }
    let n = Number(e.target.value);

    if (n > 2000) n = 2000;
    if (n < 0) n = 0;
    setValue(n);
  };

  const generate = async () => {
    if (value && value > 0) {
      setLoading(true);
      const codes: string[] = [];

      for (let index = 0; index < value; index++) {
        await new Promise((resolve) => setTimeout(resolve, 2));
        const now = new Date().getTime();
        const rand = Math.floor(Math.random() * 10000);
        codes.push(`${now}${rand}`);
      }
      setQrCodes(codes);
      setLoading(false);
    }
  };

  const print = () => {
    if (qrCodes.length < 1) {
      return;
    }
    const qrLabelsArray: string[] = [];
    qrCodes.forEach((qrCode) => {
      const canvas = document.getElementById(qrCode);
      if (canvas) {
        const content = `<div style="display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color:rgb(21, 188, 196);
        width:48mm;
        margin:3mm">
        <p style="color:white;">
        Skanna & spåra
        </p>
        ${canvas.outerHTML}
        <p  style="color:white;">
         Följ hela kedjan
        </p>
        ${chain}
        ${logo}
      </div>`;
        qrLabelsArray.push(content.trim());
      }
    });

    let container = `<div style="
    display: -ms-flexbox;     
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 210mm;
    justify-content: center;
    align-items: center;
    ">`;
    qrLabelsArray.forEach((el) => {
      container += el;
    });
    container += `</div>`;

    const win = window.open();
    if (win) {
      const style = document.createElement('style');
      style.innerHTML = `@page {
        size:    A4 portrait;
        margin: 20mm;
      }
      body {
        width:   210mm;
        height:  297mm;
      }`.trim();

      win.document.body.innerHTML = style.outerHTML.trim() + container.trim();
      win.focus();
      win.print();
    }
  };

  const exportCSV = () => {
    const codes = qrCodes.map((code) => `${window.location.origin}/?epc=alternate-${code}`);
    let string = 'QR\n';
    string += codes.join('\n');
    downloadTextFile('tracTechnology_QRcodes.csv', string);
  };

  return (
    <Box className='dataGridContainer'>
      <Typography variant='h6'>Generera QR-koder</Typography>
      <Alert severity='info'>
        Ange hur många unika QR-koder du vill generera. Max=2000
        <p>
          QR-kodens länk blir följande:{' '}
          <i style={{ textDecoration: 'underline', color: 'blue' }}>{window.location.origin}/?epc=****</i>
        </p>
      </Alert>
      <Box sx={{ mt: 2 }}>
        <TextField
          id='filled-number'
          label='hur många?'
          type='number'
          variant='outlined'
          onChange={_onChange}
          value={value}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <QrCode2Icon />
              </InputAdornment>
            )
          }}
        />
        <div>
          <Button
            variant='contained'
            startIcon={loading ? <CircularProgress size={15} /> : <QrCode2Icon />}
            sx={{ textTransform: 'none', mt: 1.5, width: '227px' }}
            color='secondary'
            disabled={loading}
            onClick={() => {
              generate();
            }}
          >
            Generera
          </Button>
        </div>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {qrCodes.length > 0 && (
          <Box className='qrcodes-buttons-container'>
            <ButtonGroup variant='contained' color='info'>
              <Button
                startIcon={<PrintIcon />}
                onClick={() => {
                  print();
                }}
              >
                Skriv ut
              </Button>
              <Button startIcon={<ListAltIcon />} onClick={() => exportCSV()}>
                CSV
              </Button>
            </ButtonGroup>
          </Box>
        )}
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {qrCodes.map((q, index) => (
            <Grid key={index} item>
              <Box id='link-qr-container' className='print-container'>
                <img src={qrCodeHeader} alt='print-area-header' />
                <div id={q} style={{ padding: '.25rem .75rem', backgroundColor: 'white' }}>
                  <QRCodeSVG value={`${window.location.origin}/?epc=alternate-${q}`} />
                </div>
                <img src={qrCodeFooter} alt='print-area-footer' />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default QrCodeGenerator;
