//import { useState } from 'react';
import '../scanner/ScannerScreen.scss';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { createSearchParams, useNavigate } from 'react-router-dom';
import QrScanner from 'qr-scanner';
import { ArticleReadView, ScanMode } from '../../types/types';
import { useGlobalState } from '../../context/GlobalState';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Scanner from '../scanner/Scanner';
import { toast } from 'react-toastify';
import { isCustomUrl, retrieveEpc, useWindowSize } from '../../utils/HelperFunctions';
import { useAppShellContext } from '../shell/AppShellContext';
import { useEffect, useRef, useState } from 'react';
import HeaderBackButton from '../common/HeaderBackButton';
import { AxiosError } from 'axios';
import { createArticleByTemplate } from '../../services/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const AlternateIdScreen = () => {
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const appShellContext = useAppShellContext();
  const [alternateIds, setAlternateIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const scannerContainerRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { windowSize } = useWindowSize();
  const alterNateIdsRef = useRef<string[]>([]);

  useEffect(() => {
    if (globalState.scanMode === undefined) {
      toast.error('Something went wrong. Try again');
      navigate('/', { replace: true });
      if (globalState.scanMode === ScanMode.TRANSFORM && globalState.menuMode === undefined) {
        toast.error('Something went wrong. Select item from menu again');
        navigate('/', { replace: true });
      }
    }
    appShellContext.setHeaderLeft(
      <HeaderBackButton
        onClick={() =>
          navigate(globalState.scannedItems.length > 0 ? '/scanner/transform' : '/scanner', { replace: true })
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    alterNateIdsRef.current = alternateIds;
  }, [alternateIds]);

  const _onDecode: (result: QrScanner.ScanResult) => void = (result) => {
    console.log('Scann result:', result.data);
    const epc = retrieveEpc(result.data);
    if (epc && !isCustomUrl(epc)) {
      if (!alterNateIdsRef.current.includes(epc)) {
        setAlternateIds([...alterNateIdsRef.current, epc]);
        toast.success('Ny fysisk QR-kod har lagts till', { icon: <QrCode2Icon /> });
      } else {
        const lastItem =
          alterNateIdsRef.current.length > 0 ? alterNateIdsRef.current[alterNateIdsRef.current.length - 1] : 'N/A';
        if (lastItem !== epc) {
          toast.info('Fysisk QR-kod redan tillagd!', { icon: <QrCode2Icon /> });
        }
      }
    } else {
      toast.warn('Ogiltig fysisk QR-kod!');
    }
  };

  const createArticle = async () => {
    if (globalState.menuMode === undefined) {
      toast.error('Something went wrong');
      return;
    }

    setLoading(true);
    try {
      let result: ArticleReadView[] = [];

      if (alternateIds.length < 1) {
        const data = await createArticleByTemplate(globalState.menuMode!.id, {
          sourceArticles: globalState.transformSourceItems
        });
        result = [data];
      } else {
        result = await Promise.all(
          alternateIds.map(async (alternateId) => {
            const data = await createArticleByTemplate(globalState.menuMode!.id, {
              alternateId,
              sourceArticles: globalState.transformSourceItems
            });
            return data;
          })
        );
      }

      setLoading(false);
      globalState.setMenuMode(undefined);
      globalState.setScanMode(undefined);
      globalState.setScannedItems([]);
      navigate(
        {
          pathname: '/',
          search: createSearchParams({
            epc:
              result[0].alternateIds && result[0].alternateIds.length > 0
                ? `${result[0].alternateIds[0]}`
                : `${result[0].id}`
          }).toString()
        },
        { replace: true }
      );
    } catch (e) {
      setLoading(false);
      globalState.handleResponseError(e as AxiosError);
    }
  };

  // Set/unset blinker className. For grabbing users attention.
  useEffect(() => {
    const element = document.getElementById('blinker_text');
    if (element) {
      element.classList.add('blink_me');
      setTimeout(() => {
        element.classList.remove('blink_me');
      }, 1200);
    }
  }, []);

  return (
    <Box className='scanner-screen-main-container'>
      <Typography
        id='blinker_text'
        variant='body2'
        sx={{ color: grey[100], margin: '0 1.5rem 1rem 1.5rem' }}
        align='center'
      >
        Vill du koppla den här artikeln till en fysisk QR-kod? Skanna QR-koden.
      </Typography>
      <div className='scanner-container' ref={scannerContainerRef}>
        {scannerContainerRef.current && (
          <Scanner
            onDecode={_onDecode}
            containerHeight={scannerContainerRef.current.getBoundingClientRect().height - 48}
          />
        )}
      </div>

      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        className='footer'
        sx={{ width: '100%' }}
      >
        <Button color='secondary' onClick={() => createArticle()} disabled={loading} sx={{ width: '100%' }}>
          {loading ? (
            <CircularProgress size={'1.5rem'} />
          ) : (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='button' sx={{ textTransform: 'none' }}>
                  {alternateIds.length > 0 ? 'Skapa' : 'Skapa utan koppling till fysisk QR-kod'}
                </Typography>
              </Box>
            </>
          )}
        </Button>
        {alternateIds.length > 0 && (
          <Button
            color='secondary'
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='listbox'
            onClick={() => setOpen((prevOpen) => !prevOpen)}
            startIcon={<QrCode2Icon />}
            endIcon={<ArrowDropUpIcon />}
            sx={{ padding: '0 1rem' }}
          >
            {alternateIds.length}
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 99,
          maxHeight: windowSize[1] - 150,
          overflow: 'hidden',
          overflowY: 'scroll'
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper sx={{ width: '100%' }}>
              <List>
                {alternateIds.map((alternateId) => (
                  <ListItem
                    key={alternateId}
                    sx={{ height: '48px', maxWidth: '550px' }}
                    secondaryAction={
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        color='error'
                        onClick={() => {
                          setAlternateIds(alternateIds.filter((a) => a !== alternateId));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <QrCode2Icon color='secondary' />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={alternateId} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default AlternateIdScreen;
