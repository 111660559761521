import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../context/GlobalState';
import { ArticleReadView, CustomLocationState, UrlReadView } from '../../types/types';
import DeleteIcon from '@mui/icons-material/Delete';
import './ItemsValidationScreen.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { cloneArticle, getArticleById } from '../../services/Article';
import { useAppShellContext } from '../shell/AppShellContext';
import HeaderBackButton from '../common/HeaderBackButton';
import { isCustomUrl } from '../../utils/HelperFunctions';
import { getAlternateId } from '../../services/AlternateID';
import { getUrl, getUrlByUrl } from '../../services/URLs';
import ChooseIcon from '@mui/icons-material/Queue';

const ItemsValidationScreen = () => {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const appShellContext = useAppShellContext();
  const { scannedItems, menuMode, setScannedItems } = useGlobalState();
  const [loading, setLoading] = useState<boolean>(true);
  const itemsMapRef = useRef<Map<string, number[]>>(new Map());
  const itemsToResolveMapRef = useRef<Map<string, number[]>>(new Map());
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [itemLabelsMap, setItemLabelsMap] = useState<Map<number, string>>(new Map());

  useEffect(() => {
    appShellContext.setHeaderLeft(
      <HeaderBackButton
        onClick={() => {
          navigate('/scanner', { replace: true });
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const itemsMap = new Map<string, number[]>();
    itemsMapRef.current = itemsMap;
    Promise.all(
      globalState.scannedItems.map(async (item) => {
        try {
          let _data: ArticleReadView | UrlReadView | null = null;
          const isAlternateId = item.split('-')[0] === 'alternate';
          if (isAlternateId) {
            const alternateId = await getAlternateId(item);
            switch (alternateId.entityType) {
              case 'Article':
                _data = await getArticleById(alternateId.entityId);
                itemsMapRef.current.set(item, [_data.id]);
                break;
              case 'Custom URL':
                const url = await getUrl(alternateId.entityId);
                if (url.articleIds.length > 1) {
                  itemsToResolveMapRef.current.set(item, url.articleIds);
                  fetchArticles(url.articleIds);
                } else {
                  _data = await cloneArticle(url.articleIds[0]);
                  itemsMapRef.current.set(item, [_data.id]);
                }
                break;
            }
          } else if (isCustomUrl(item)) {
            const path = new URL(item).pathname;
            const url = await getUrlByUrl(path);
            if (url.articleIds.length > 1) {
              itemsToResolveMapRef.current.set(item, url.articleIds);
              fetchArticles(url.articleIds);
            } else {
              _data = await cloneArticle(url.articleIds[0]);
              itemsMapRef.current.set(item, [_data.id]);
            }
          } else {
            _data = await getArticleById(Number(item));
            itemsMapRef.current.set(item, [_data.id]);
          }
        } catch (e) {}
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Promise.all failed:', error);
      });
  }, [globalState.scannedItems]);

  const fetchArticles = (ids: number[]) => {
    Promise.all(ids.map((id) => getArticleById(id)))
      .then((res) =>
        res.forEach(({ id, displayName }) => setItemLabelsMap((prev) => new Map(prev).set(id, displayName)))
      )
      .catch((e) => {
        console.log('Fetching article names failed:', e);
      });
  };

  const transform = async () => {
    let isValid = true;
    if ([...itemsMapRef.current].filter((k, v) => v === undefined).length > 0) {
      toast.warn('Makulera artiklar som inte existerar i TracTechnology!', { autoClose: 3000 });
      isValid = false;
    }
    if (anyNeedSelection()) {
      toast.error(
        <>
          Det saknas val för scannade produkter. För att kunna gå vidare behöver du välja en eller flera artiklar från
          rader med följande ikon:
          <ChooseIcon />
        </>,
        { autoClose: 10000 }
      );
      isValid = false;
    }
    if (isValid) {
      // map scanned items to source articles for transformation before navigating
      setLoading(true);
      Promise.all(
        // clone articles from URL
        selectedItems.map(async (id) => {
          try {
            const clone = await cloneArticle(id);
            return clone.id;
          } catch (e) {
            console.error(`Failed to clone article: ${id}`, e);
            throw e;
          }
        })
      )
        .then((res) => {
          // concat already fetched articles
          let sourceIds: number[];
          if (itemsMapRef.current.size !== 0) {
            sourceIds = [...itemsMapRef.current.values()]
              .reduce((accumulator, current) => [...accumulator, ...current])
              .concat(res);
          } else {
            sourceIds = res;
          }
          globalState.setTransformSourceItems(sourceIds);
          setLoading(false);
          navigate('alternate', { replace: true });
        })
        .catch((error) => {
          setLoading(false);
          console.log('Promise.all failed:', error);
        });
    }
  };
  const isValidItem = (item: string): boolean => {
    const _item = itemsMapRef.current.get(item);
    return _item !== undefined || itemsToResolveMapRef.current.get(item) !== undefined;
  };
  const needsSelection = useCallback(
    (item: string): boolean => {
      const possibleIds = itemsToResolveMapRef.current.get(item);
      if (possibleIds === undefined) {
        return false;
      }
      return selectedItems.filter((selected) => possibleIds.includes(selected)).length === 0;
    },
    [selectedItems]
  );

  const navigateToScannedItem = (scannedItem: string) => {
    if (isCustomUrl(scannedItem)) {
      const pathPattern = /(\/map\/[^/]+\/[^/]+)/;
      const match = scannedItem.match(pathPattern);
      if (match)
        navigate(
          { pathname: match[0] },
          { replace: false, state: { prevPage: '/scanner/transform' } as CustomLocationState }
        );
    } else {
      navigate(
        { pathname: '/', search: createSearchParams({ epc: scannedItem }).toString() },
        { replace: false, state: { prevPage: '/scanner/transform' } as CustomLocationState }
      );
    }
  };

  const removeScannedItem = useCallback(
    (item: string) => {
      // remove item from scanned items list
      setScannedItems(scannedItems.filter((i) => i !== item));
      // find if it was a multiple article item
      const multiSelectItem = itemsToResolveMapRef.current.get(item);
      if (multiSelectItem !== undefined) {
        // if so, remove all selected items which correspond with the removed item
        setSelectedItems(selectedItems.filter((id) => !multiSelectItem.includes(id)));
        itemsToResolveMapRef.current.delete(item);
      }
    },
    [scannedItems, setScannedItems, selectedItems]
  );

  const anyNeedSelection = useCallback(() => {
    return scannedItems.filter(needsSelection).length > 0;
  }, [needsSelection, scannedItems]);

  useEffect(() => {
    (async () => await fetchData())();
  }, [fetchData]);

  const ScannedItem = (props: { scannedItem: string }) => (
    <ListItem
      sx={{ borderBottom: '1px solid #fff', padding: '1rem', cursor: 'pointer' }}
      secondaryAction={
        <IconButton
          edge='end'
          aria-label='delete'
          sx={{ color: grey[100] }}
          onClick={(e) => {
            e.stopPropagation();
            removeScannedItem(props.scannedItem);
          }}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: grey[200] }}>
          {loading ? (
            <CircularProgress size={'1.5rem'} color='secondary' />
          ) : needsSelection(props.scannedItem) ? (
            <ChooseIcon fontSize='large' color='warning' />
          ) : isValidItem(props.scannedItem) ? (
            <CheckIcon fontSize='large' color='success' />
          ) : (
            <CloseIcon fontSize='large' color='error' />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        onClick={() => {
          navigateToScannedItem(props.scannedItem);
        }}
        primary={props.scannedItem}
        sx={{ color: grey[100] }}
      />
    </ListItem>
  );

  const _handleCheckbox = useCallback((scannedItem: string, id: number) => {
    setSelectedItems((prev) => (prev.includes(id) ? prev.filter((selected) => selected !== id) : [...prev, id]));
  }, []);

  return (
    <>
      <Box className='transform-screen-container'>
        <Box sx={{ padding: '1rem' }}>
          <Typography variant='h5' color={grey[100]}>
            Skapa {menuMode ? ` från ${menuMode.displayName}` : 'meny'}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <List sx={{ marginLeft: ' 0.5rem', marginRight: '0.5rem' }}>
            {scannedItems.map((scannedItem, index) => (
              <Accordion
                key={index}
                sx={{ backgroundColor: 'primary.main', ':hover': { backgroundColor: 'secondary.main' } }}
              >
                <AccordionSummary>
                  <ScannedItem scannedItem={scannedItem} />
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component='fieldset'>
                    <FormGroup>
                      {itemsToResolveMapRef.current &&
                        itemsToResolveMapRef.current?.get(scannedItem)?.map((id: number, index: number) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                className='transform-select-checkbox'
                                color='default'
                                checked={selectedItems.includes(id)}
                                onChange={(e) => _handleCheckbox(scannedItem, id)}
                              />
                            }
                            label={
                              <Tooltip title={id} enterDelay={500}>
                                <Typography>{itemLabelsMap.get(id)}</Typography>
                              </Tooltip>
                            }
                            className='transform-select-option'
                          />
                        ))}
                    </FormGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
        </Box>
      </Box>
      <Box className='footer' sx={{ backgroundColor: 'primary.main' }}>
        <Button
          variant='contained'
          color='secondary'
          disabled={loading || scannedItems.length === 0}
          fullWidth
          disableElevation
          onClick={async () => {
            transform();
          }}
        >
          {loading ? <CircularProgress size={'1.5rem'} sx={{ color: grey[100] }} /> : 'Skapa'}
        </Button>
      </Box>
    </>
  );
};

export default ItemsValidationScreen;
