import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import { MAP_LIBRARIES, OrganisationSummaryView } from '../../types/types';

import markerPerson from '../../utils/img/markerPerson.png';
import markerPersonWhite from '../../utils/img/markerPersonWhite.png';
import {
  convertOrganisationName,
  getPinSize,
  prepareOrgNameForLink,
  searchInOrganisationSummaries,
  useWindowSize
} from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import ComponentLoader, { componentLoaderStyles } from '../common/componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';
import HomeScreenOverlay from './HomeScreenOverlay';
import { selectContent } from '../../services/Analytics';
import { useHomeContext } from '../../context/HomeContext';
import LoaderImg from '../../utils/img/AppTrac.svg';
import { useLocation } from 'react-router-dom';
import MapInfoBox from '../common/MapInfoBox';

type Props = {
  data?: OrganisationSummaryView[];
  disableOverLay?: boolean;
  initialSearch?: string;
};

const OrganisationMap = (props: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [satellite, setSatellite] = useState<boolean>(false);
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();
  const [isStreetView, setIsStreetView] = useState<boolean>(false);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [filteredOrganisations, setFilteredOrganisations] = useState<OrganisationSummaryView[]>([]);
  const [visibleMarkerCount, setVisibleMarkerCount] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | undefined>();
  const [showingUserTooltip, setShowingUserTooltip] = useState<boolean>(false);
  const mapCenterInitRef = useRef<boolean>(false);

  const homeCtx = useHomeContext();
  const location = useLocation();

  const randomZIndexListRef = useRef<number[]>([]);

  const { isMobileDevice } = useWindowSize();

  const ICON_SIZE = 17;
  const VERIFIED_ICON_SIZE = 28;
  const MARKER_THRESHOLD = 50;

  useEffect(() => {
    const array: number[] = Array.from({ length: props.data?.length ?? 0 }, (_, index) => index);
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    randomZIndexListRef.current = array;
  }, [props.data]);

  useEffect(() => {
    const bounds = map?.getBounds();
    if (bounds && filteredOrganisations !== undefined) {
      setVisibleMarkerCount(filteredOrganisations.filter((org) => bounds.contains(org)).length);
    }
    if (homeCtx.selectedOrganisation && !filteredOrganisations.includes(homeCtx.selectedOrganisation)) {
      homeCtx.setSelectedOrganisation(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds, filteredOrganisations]);

  // Effect to set the selected organisation based on the "company" search param on mount

  useEffect(() => {
    const _company = homeCtx.searchParams.get('company');
    if (
      _company &&
      homeCtx.selectedOrganisation &&
      convertOrganisationName(homeCtx.selectedOrganisation.name) === convertOrganisationName(_company)
    )
      return;
    if (_company && filteredOrganisations.length > 0) {
      const _summary = filteredOrganisations.find(
        (org) => convertOrganisationName(org.name) === convertOrganisationName(_company)
      );
      if (_summary && (!homeCtx.selectedOrganisation || homeCtx.selectedOrganisation?.id !== _summary.id)) {
        homeCtx.setSelectedOrganisation(_summary);
      }
    }
  }, [filteredOrganisations, homeCtx]);

  const handleMarkerClicked = (summary: OrganisationSummaryView) => {
    const newParams = new URLSearchParams(homeCtx.searchParams);
    newParams.set('company', `${convertOrganisationName(summary.name)}`);
    homeCtx.setSearchParams(newParams);
    homeCtx.setSelectedOrganisation(summary);
  };

  const handleOnClose = () => {
    const newParams = new URLSearchParams(homeCtx.searchParams);
    newParams.delete('company');
    homeCtx.setSearchParams(newParams);
    homeCtx.setSelectedOrganisation(undefined);
  };

  useEffect(() => {
    const drawCircle = (
      context: CanvasRenderingContext2D,
      color: string | CanvasGradient,
      x: number,
      y: number,
      radius: number,
      fill: boolean = true
    ) => {
      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI);
      if (fill) {
        context.fillStyle = color;
        context.fill();
      } else {
        context.strokeStyle = color;
        context.stroke();
      }
      context.closePath();
    };

    const drawOuterRing = (ctx: CanvasRenderingContext2D, x: number, y: number, radius: number) => {
      // Draw the outer blue ring
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, Math.PI * 2, false);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#05c3f7';
      ctx.stroke();
      ctx.closePath();
    };

    /**CANVAS FOR SIMPLE ICONS */
    const icons = new Map<number, string>();
    const canvas1 = document.createElement('canvas');
    const ctx1 = canvas1.getContext('2d');
    canvas1.width = ICON_SIZE;
    canvas1.height = ICON_SIZE;
    const radius = ICON_SIZE / 2;
    /**CANVAS FOR VERIFIED SIMPLE ICONS */
    const canvas2 = document.createElement('canvas');
    const ctx2 = canvas2.getContext('2d');
    canvas2.width = VERIFIED_ICON_SIZE;
    canvas2.height = VERIFIED_ICON_SIZE;

    homeCtx.classifiers.forEach((cat) => {
      if (ctx1) {
        drawCircle(ctx1, cat.color, radius, radius, radius * 0.5);
        drawCircle(ctx1, 'white', radius, radius, radius * 0.5, false);
        icons.set(cat.id, canvas1.toDataURL());
        ctx1.clearRect(0, 0, ICON_SIZE, ICON_SIZE);
      }
      if (ctx2) {
        drawOuterRing(ctx2, radius, radius, radius - 2);
        drawCircle(ctx2, cat.color, radius, radius, radius * 0.5);

        icons.set(cat.id * 1000, canvas2.toDataURL());
        ctx2.clearRect(0, 0, VERIFIED_ICON_SIZE, VERIFIED_ICON_SIZE);
      }
    });
    homeCtx.setSimpleIcons(icons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeCtx.classifiers]);

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries: MAP_LIBRARIES
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );
      const params = new URLSearchParams(location.search);

      const zoomString = params.get('zoom');
      var zoom;
      if (zoomString !== null) {
        zoom = parseInt(zoomString);
      }

      map.setOptions({
        zoom: Number.isNaN(zoom) ? 7 : zoom ?? 7,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyle
      });
      map.setCenter({ lat: 59.2781126, lng: 15.1674353 });
      map.setTilt(45);

      map.addListener('bounds_changed', () => {
        setMapBounds(map.getBounds());
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      setMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [streetView]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  const getRelevantIconId = useCallback(
    (categoryIds: number[]) => {
      const filteredCategories = [
        ...homeCtx.filteredMainCategories,
        ...homeCtx.filteredSubCategories,
        ...homeCtx.filteredGroups
      ];
      if (categoryIds !== null) {
        const options = categoryIds.filter((id) => filteredCategories.includes(id));
        return filteredCategories.length > 0 ? options[0] : categoryIds[0];
      }
    },
    [homeCtx.filteredMainCategories, homeCtx.filteredSubCategories, homeCtx.filteredGroups]
  );

  const getIcon = useCallback(
    (ids: number[], customerCardEnabled: boolean) => {
      const relevantIconId = getRelevantIconId(ids);

      const icons = homeCtx.classifiers.find((category) => category.id === relevantIconId);
      if (!customerCardEnabled) return icons?.icon;
      return icons?.verifiedIcon ?? icons?.icon;
    },
    [getRelevantIconId, homeCtx.classifiers]
  );

  const applyCategoryFilter = useCallback(
    (categoryIds: number[]): boolean => {
      const filteredCategories = [
        ...homeCtx.filteredMainCategories,
        ...homeCtx.filteredSubCategories,
        ...homeCtx.filteredGroups
      ];
      return filteredCategories.every(
        (filteredCategory) => categoryIds !== null && categoryIds.includes(filteredCategory)
      );
    },
    [homeCtx.filteredMainCategories, homeCtx.filteredSubCategories, homeCtx.filteredGroups]
  );

  useEffect(() => {
    const searchOrg = props.data?.find((org) => prepareOrgNameForLink(org.name) === props.initialSearch);
    if (searchOrg && map) {
      selectContent({ content_type: 'organisation_url', content_id: `${searchOrg.id}` });
      homeCtx.setSearchQuery(searchOrg.name);
      map.setCenter(searchOrg);
      mapCenterInitRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialSearch, props.data, map]);

  useEffect(() => {
    const filteredCategories = [
      ...homeCtx.filteredMainCategories,
      ...homeCtx.filteredSubCategories,
      ...homeCtx.filteredGroups
    ];
    if (homeCtx.searchQuery || filteredCategories.length > 0) {
      const filtered = searchInOrganisationSummaries(props.data ?? [], homeCtx.searchQuery ?? '').filter(
        (organisation) => applyCategoryFilter(organisation.classifierIds)
      );

      setFilteredOrganisations(filtered);
    }
  }, [
    homeCtx.searchQuery,
    homeCtx.filteredMainCategories,
    homeCtx.filteredSubCategories,
    homeCtx.filteredGroups,
    props.data,
    applyCategoryFilter
  ]);

  useEffect(() => {
    if (map) {
      map.setOptions({ mapTypeId: satellite ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satellite]);

  useEffect(() => {
    let streetViewChangeListener: google.maps.MapsEventListener | null = null;
    if (map) {
      streetViewChangeListener = google.maps.event.addListener(map.getStreetView(), 'visible_changed', () => {
        setIsStreetView(map.getStreetView().getVisible());
      });
    }
    return () => {
      if (streetViewChangeListener && map) google.maps.event.removeListener(streetViewChangeListener);
    };
  }, [map]);

  useEffect(() => {
    if (map !== null && !homeCtx.loading) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && !props.initialSearch) {
            const currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
            if (!mapCenterInitRef.current) {
              map.setCenter(currentLocation);
              mapCenterInitRef.current = true;
            }
          }
        },
        (error) => console.warn('Browser location error', error)
      );
    }
    const filtered = searchInOrganisationSummaries(props.data ?? [], homeCtx.searchQuery ?? '').filter((organisation) =>
      applyCategoryFilter(organisation.classifierIds)
    );

    setFilteredOrganisations(filtered);
  }, [map, props.data, props.initialSearch, homeCtx.searchQuery, applyCategoryFilter, homeCtx.loading]);

  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded || homeCtx.loading ? (
    <ComponentLoader bgColor='#02636e' bgImg={LoaderImg} />
  ) : (
    <>
      {isMobileDevice && homeCtx.selectedOrganisation && (
        <MapInfoBox
          organisationSummary={homeCtx.selectedOrganisation}
          onClose={() => handleOnClose()}
          isMobileDevice={isMobileDevice}
        />
      )}
      <GoogleMap
        id='react-google-maps'
        mapContainerClassName={'google-map-container-style'}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {!isMobileDevice && homeCtx.selectedOrganisation && (
          <MapInfoBox
            organisationSummary={homeCtx.selectedOrganisation}
            onClose={() => handleOnClose()}
            isMobileDevice={isMobileDevice}
          />
        )}
        {userPosition && (
          <Marker
            position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
            icon={{
              url: satellite || isStreetView ? markerPersonWhite : markerPerson,
              scaledSize: new google.maps.Size(40, 40)
            }}
            onClick={() => {
              setShowingUserTooltip((prev) => !prev);
            }}
          >
            {showingUserTooltip && (
              <InfoBox
                options={{
                  enableEventPropagation: true,
                  closeBoxURL: '',
                  boxStyle: {
                    boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                    backgroundColor: 'rgba(255,255,255,0)'
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '1.5rem .5rem',
                    borderRadius: '1rem',
                    minWidth: isMobileDevice ? 'calc(window.innerWidth - 2rem)' : '25%'
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='body2' align='left' noWrap>
                      Du är här
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      aria-label='close button'
                      onClick={() => setShowingUserTooltip(false)}
                      sx={{ padding: 0, marginLeft: 2 }}
                    >
                      <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </InfoBox>
            )}
          </Marker>
        )}
        {filteredOrganisations &&
          filteredOrganisations
            .filter(
              (summary) =>
                summary.classifierIds !== null &&
                summary.classifierIds.length > 0 &&
                homeCtx.simpleIcons.get(getRelevantIconId(summary.classifierIds) ?? 0) !== undefined
            )
            .map((summary, index) => {
              const fullIcon = getIcon(summary.classifierIds, summary.customerCardEnabled) ?? '';
              const dotIcon =
                homeCtx.simpleIcons.get(
                  getRelevantIconId(summary.classifierIds)
                    ? summary.customerCardEnabled
                      ? getRelevantIconId(summary.classifierIds)! * 1000
                      : getRelevantIconId(summary.classifierIds)!
                    : 0
                ) ?? '';
              return (
                <Marker
                  key={`${summary.id}-${index}`}
                  position={{ lat: summary.lat, lng: summary.lng }}
                  zIndex={summary.customerCardEnabled ? 9999999 : randomZIndexListRef.current[index]}
                  icon={
                    visibleMarkerCount <= MARKER_THRESHOLD
                      ? {
                          scaledSize: isStreetView
                            ? new google.maps.Size(65, 75)
                            : getPinSize(location, summary.customerCardEnabled),
                          url: fullIcon
                        }
                      : {
                          url: dotIcon
                        }
                  }
                  onClick={() => handleMarkerClicked(summary)}
                  options={{ map: map }}
                />
              );
            })}
        <HomeScreenOverlay
          map={map}
          satellite={satellite}
          setSatellite={setSatellite}
          displayCounter={{ num: filteredOrganisations.length, max: props.data?.length ?? 0 }}
          isMobileDevice={isMobileDevice}
        />
      </GoogleMap>
    </>
  );
};

export default OrganisationMap;
