import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { NavLink, Outlet } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SettingsIcon from '@mui/icons-material/Settings';
import './AdminPanelScreen.scss';
import { useWindowSize } from '../../utils/HelperFunctions';

const AdminPanelScreen = () => {
  const { isMobileDevice } = useWindowSize();
  const [open, setOpen] = useState(true);
  useEffect(() => {
    isMobileDevice ? setOpen(false) : setOpen(true);
  }, [isMobileDevice]);
  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box className={'adminPanelMainContainer'}>
      <Box className={open ? 'adminSideNavOpen' : 'adminSideNavClose'}>
        <Box className={'adminSideNavHeader'}>
          <IconButton onClick={() => toggleOpen()}>
            {open ? (
              <KeyboardDoubleArrowLeftIcon sx={{ color: 'white' }} />
            ) : (
              <KeyboardDoubleArrowRightIcon sx={{ color: 'white' }} />
            )}
          </IconButton>
        </Box>
        <List>
          <NavLink to={'/admin/organisations'}>
            {({ isActive }) => (
              <ListItem disablePadding disableGutters sx={{ bgcolor: isActive ? 'secondary.main' : undefined }}>
                <ListItemButton>
                  <ListItemIcon>{<ListIcon sx={{ color: 'white' }} />}</ListItemIcon>
                  {open && <ListItemText primary={'Organisation'} sx={{ color: 'white' }} />}
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>

          <NavLink to={'/admin/users'}>
            {({ isActive }) => (
              <ListItem disablePadding disableGutters sx={{ bgcolor: isActive ? 'secondary.main' : undefined }}>
                <ListItemButton>
                  <ListItemIcon>{<SwitchAccountIcon sx={{ color: 'white' }} />}</ListItemIcon>
                  {open && <ListItemText primary={'Användare'} sx={{ color: 'white' }} />}
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>
          <NavLink to={'/admin/qr-generator'}>
            {({ isActive }) => (
              <ListItem disablePadding disableGutters sx={{ bgcolor: isActive ? 'secondary.main' : undefined }}>
                <ListItemButton>
                  <ListItemIcon>{<QrCode2Icon sx={{ color: 'white' }} />}</ListItemIcon>
                  {open && <ListItemText primary={'QR-kod'} sx={{ color: 'white' }} />}
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>
          <NavLink to={'/admin/system-settings'}>
            {({ isActive }) => (
              <ListItem disablePadding disableGutters sx={{ bgcolor: isActive ? 'secondary.main' : undefined }}>
                <ListItemButton>
                  <ListItemIcon>{<SettingsIcon sx={{ color: 'white' }} />}</ListItemIcon>
                  {open && <ListItemText primary={'Inställningar'} sx={{ color: 'white' }} />}
                </ListItemButton>
              </ListItem>
            )}
          </NavLink>
        </List>
      </Box>
      <Box className='adminOutletContainer'>
        <Outlet />
      </Box>
    </Box>
  );
};
export default AdminPanelScreen;
