import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import {
  GroupCreateView,
  GroupListView,
  GroupMembershipCreateView,
  GroupMembershipReadView,
  GroupMembershipUpdateView,
  GroupReadView,
  GroupSummaryView,
  GroupUpdateView,
  infinitePagination
} from '../types/types';

export const getGroupSummaries = async (config?: AxiosRequestConfig): Promise<GroupSummaryView[]> => {
  const { data } = await http.get<GroupSummaryView[]>(ENDPOINTS.groupsSummary, {
    ...config
  });
  return data;
};

export const getGroupByShortName = async (
  shortName: string,
  config?: AxiosRequestConfig
): Promise<GroupSummaryView> => {
  const { data } = await http.get<GroupSummaryView>(`${ENDPOINTS.groupByShortName}/${shortName}`, {
    ...config
  });
  return data;
};

export const getOrganisationsForGroup = async (
  id: number,
  config?: AxiosRequestConfig
): Promise<GroupMembershipReadView[]> => {
  const { data } = await http.get<GroupMembershipReadView[]>(`${ENDPOINTS.groups}/${id}/memberships`, {
    ...config
  });
  return data;
};

export const getGroupsForOrganisation = async (
  organisationId: number,
  config?: AxiosRequestConfig
): Promise<GroupMembershipReadView[]> => {
  const { data } = await http.get<GroupMembershipReadView[]>(`${ENDPOINTS.organisations}/groups/${organisationId}`, {
    ...config
  });
  return data;
};

// **************************************
//          CRUD operations
// **************************************

export const getGroups = async (config?: AxiosRequestConfig): Promise<GroupListView> => {
  const { data } = await http.get<GroupListView>(ENDPOINTS.groups, {
    ...config,
    params: { ...config?.params, ...infinitePagination }
  });
  return data;
};

export const createGroup = async (group: GroupCreateView): Promise<GroupReadView> => {
  const { data } = await http.post<GroupReadView>(ENDPOINTS.groups, group);
  return data;
};

export const updateGroup = async (id: string | number, group: GroupUpdateView): Promise<GroupReadView> => {
  const { data } = await http.patch<GroupReadView>(`${ENDPOINTS.groups}/${id}`, group);
  return data;
};

export const deleteGroup = async (id: string | number): Promise<number> => {
  const { status } = await http.delete(`${ENDPOINTS.groups}/${id}`);
  return status;
};

export const joinGroup = async (
  groupId: number,
  organisationId: number,
  joinView: GroupMembershipCreateView,
  config?: AxiosRequestConfig
): Promise<GroupMembershipReadView> => {
  const { data } = await http.put<GroupMembershipReadView>(
    `${ENDPOINTS.groups}/${groupId}/memberships/${organisationId}`,
    joinView
  );
  return data;
};

export const updateGroupMembership = async (
  groupId: number,
  organisationId: number,
  updateView: GroupMembershipUpdateView,
  config?: AxiosRequestConfig
): Promise<GroupMembershipReadView> => {
  const { data } = await http.patch<GroupMembershipReadView>(
    `${ENDPOINTS.groups}/${groupId}/memberships/${organisationId}`,
    updateView
  );
  return data;
};

export const deleteGroupMembership = async (
  groupId: number,
  organisationId: number,
  config?: AxiosRequestConfig
): Promise<void> => {
  await http.delete(`${ENDPOINTS.groups}/${groupId}/memberships/${organisationId}`);
  return;
};
