import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TransformIcon from '@mui/icons-material/Transform';

import PrintIcon from '@mui/icons-material/Print';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ArticleTemplateReadView, CustomNavigateFunction, ScanMode } from '../../types/types';
import { useGlobalState } from '../../context/GlobalState';

import { isMobileDevice } from '../../utils/HelperFunctions';
import { Map, QrCode2, Security } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PrintModal from './PrintModal';
import { getArticleTemplates } from '../../services/ArticleTemplates';
import { AxiosError } from 'axios';
import ProfileMenu from './ProfileMenu';

type Props = {
  anchor?: 'right' | 'left' | 'top' | 'bottom' | undefined;
  open: boolean;
  toggleMenu: (b?: boolean) => void;
};

const SideMenu = (props: Props) => {
  const navigate = useNavigate() as CustomNavigateFunction;
  const location = useLocation();
  const globalState = useGlobalState();
  const [searchParams] = useSearchParams();

  const [epcCode, setEpcCode] = useState<string>();
  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);
  const [articleTemplates, setArticleTemplates] = useState<ArticleTemplateReadView[]>([]);

  useEffect(() => {
    const epc = searchParams.get('epc');
    if (epc) {
      setEpcCode(epc);
    } else {
      setEpcCode(undefined);
    }
  }, [searchParams]);

  const printMap = () => {
    toast.info(
      <p>
        Fungerar inte <br /> använd Ctr/⌘ + p
      </p>,
      { autoClose: 3500 }
    );
  };

  const userIsSystemAdmin = globalState.user && globalState.user.isSuperUser;
  const userIsOrganisation = globalState.user && !globalState.user.isSuperUser;

  useEffect(() => {
    if (userIsOrganisation) {
      getArticleTemplates()
        .then((data) => {
          setArticleTemplates(data.records);
        })
        .catch((error) => globalState.handleResponseError(error as AxiosError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsOrganisation]);

  const handleArticleTemplateClick = (artTemplate: ArticleTemplateReadView) => {
    globalState.setScanMode(ScanMode.TRANSFORM);
    globalState.setMenuMode(artTemplate);
    props.toggleMenu(false);
    if (location.pathname !== '/scanner') {
      navigate('/scanner');
    }
  };

  const handleAdminItemClick = () => {
    props.toggleMenu(false);
    if (location.pathname !== '/admin/organisations') {
      navigate('/admin/organisations');
    }
  };

  return (
    <SwipeableDrawer
      anchor={props.anchor}
      open={props.open}
      onClose={() => props.toggleMenu(false)}
      onOpen={() => props.toggleMenu(true)}
      PaperProps={isMobileDevice() ? { sx: { width: '65%' } } : {}}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary.main',
          height: '3.5rem'
        }}
      >
        <Box sx={{ flexGrow: 1, marginLeft: 3 }}>
          <img
            src={globalState.currentTheme.logo}
            alt='brand'
            style={{ maxHeight: '3rem', maxWidth: '100%', marginTop: '.5rem' }}
          />
        </Box>
        <IconButton onClick={() => props.toggleMenu(false)} size='large' edge='end' aria-label='menu' sx={{ mr: 1 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <List>
          {userIsSystemAdmin && <SystemAdminListItems handleAdminItemClick={handleAdminItemClick} />}
          {userIsOrganisation && (
            <TransformListItems
              handleArticleTemplateClick={handleArticleTemplateClick}
              articleTemplates={articleTemplates}
            />
          )}
          {epcCode && (
            <PrintListItems
              epcCode={epcCode}
              printModalOpen={printModalOpen}
              setPrintModalOpen={setPrintModalOpen}
              printMap={printMap}
            />
          )}
        </List>
      </Box>

      <ProfileMenu toggleMenu={props.toggleMenu} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '10rem'
        }}
      >
        <a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>
          Privacy Policy
        </a>
        <a href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>
          Terms of Service
        </a>
      </Box>
    </SwipeableDrawer>
  );
};

export default SideMenu;

const SystemAdminListItems = ({ handleAdminItemClick }: { handleAdminItemClick: () => void }) => (
  <ListItem disablePadding key={'SystemAdmin-items'}>
    <ListItemButton onClick={() => handleAdminItemClick()}>
      <ListItemIcon>
        <Security />
      </ListItemIcon>
      <ListItemText primary='Adminpanel' />
    </ListItemButton>
  </ListItem>
);

const TransformListItems = ({
  handleArticleTemplateClick,
  articleTemplates
}: {
  handleArticleTemplateClick: (artTemplate: ArticleTemplateReadView) => void;
  articleTemplates: ArticleTemplateReadView[];
}) => (
  <ListItem disablePadding key={'Transform-items'}>
    <Accordion elevation={0} sx={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
        <ListItemIcon>{<TransformIcon />}</ListItemIcon>
        <Typography>Skapa</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {articleTemplates.map((template) => (
          <ListItemButton key={template.id} onClick={() => handleArticleTemplateClick(template)}>
            <ListItemIcon sx={{ width: 35, height: 35 }}>
              <img src={template.menuIcon} alt='menu-icon'></img>
            </ListItemIcon>
            <ListItemText primary={template.templateName} />
          </ListItemButton>
        ))}
      </AccordionDetails>
    </Accordion>
  </ListItem>
);

type PrintProps = {
  setPrintModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  printModalOpen: boolean;
  printMap: () => void;
  epcCode: string;
};
const PrintListItems = ({ setPrintModalOpen, printModalOpen, printMap, epcCode }: PrintProps) => (
  <>
    <ListItem disablePadding key={'Print-items'}>
      <Accordion elevation={0} sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
          <ListItemIcon>{<PrintIcon />}</ListItemIcon>
          <Typography>Skriv ut</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListItemButton
            onClick={() => {
              setPrintModalOpen(true);
            }}
          >
            <ListItemIcon>
              <QrCode2 />
            </ListItemIcon>
            <ListItemText primary={'Skriv ut QR-Kod'} />
          </ListItemButton>
          <ListItemButton onClick={() => printMap()}>
            <ListItemIcon>
              <Map />
            </ListItemIcon>
            <ListItemText primary={'Skriv ut karta'} />
          </ListItemButton>
        </AccordionDetails>
      </Accordion>
    </ListItem>
    <PrintModal printModalOpen={printModalOpen} setPrintModalOpen={setPrintModalOpen} epcCode={epcCode} />
  </>
);
