import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ArticleTemplateReadView, OrganisationReadView } from '../../types/types';
import { deleteArticleTemplate, getArticleTemplatesByOrganisationId } from '../../services/ArticleTemplates';
import { toast } from 'react-toastify';
import { useGlobalState } from '../../context/GlobalState';
import { AxiosError } from 'axios';
import './AdminPanelScreen.scss';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CreateArticleIcon from '@mui/icons-material/AddToPhotos';

import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { searchInArticleTemplates } from '../../utils/HelperFunctions';
import { getOrganisationById } from '../../services/Organisations';
import { createArticleByTemplate } from '../../services/Article';

const ArticleTemplates = () => {
  const globalSate = useGlobalState();
  const params = useParams<{ orgId: string }>();
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>();
  const [loading, setLoading] = useState<boolean>(false);
  const [articleTemplates, setArticleTemplates] = useState<ArticleTemplateReadView[]>([]);
  const [filteredArticleTemplates, setFilteredArticleTemplates] = useState<ArticleTemplateReadView[]>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [itemToDelete, setItemToDelete] = useState<number | string | null>(null);
  const [reload, setReload] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<OrganisationReadView>();

  useEffect(() => {
    if (!params.orgId) {
      toast.error('Something went wrong. Please refresh the page!');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        const _articleTemplates = await getArticleTemplatesByOrganisationId(params.orgId!);
        setOrganisation(_organisation);
        setArticleTemplates(_articleTemplates);
      } catch (e) {
        globalSate.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId, reload]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'templateName', headerName: 'Artikelmallsnamn', minWidth: 165, flex: 1 },
    { field: 'organisationName', headerName: 'Organisation', minWidth: 165, flex: 1 },
    {
      field: 'actions3',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (rowParams) => (
        <Tooltip title='Genom att klicka på länken kommer du automatiskt att öppna en ny länk med en ny artikel skapad från din mall'>
          <IconButton
            color='primary'
            onClick={async () => {
              try {
                const data = await createArticleByTemplate(Number(rowParams.id), { sourceArticles: [] });
                window.open(`${window.location.origin}/?epc=${data.id}`);
              } catch (e) {
                toast.error('Ett fel inträffade');
              }
            }}
          >
            <CreateArticleIcon />
          </IconButton>
        </Tooltip>
      ),
      align: 'right'
    },
    {
      field: 'actions1',
      headerName: '',
      width: 80,
      sortable: false,

      renderCell: (rowParams) => (
        <IconButton color='error' onClick={() => setItemToDelete(rowParams.id)}>
          <DeleteIcon />
        </IconButton>
      ),
      align: 'right'
    },
    {
      field: 'actions2',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (rowParams) => (
        <IconButton color='secondary' onClick={() => navigate(`article-templates/${rowParams.id}`, { replace: true })}>
          <EditIcon />
        </IconButton>
      ),
      align: 'right'
    }
  ];

  useEffect(() => {
    if (articleTemplates && articleTemplates.length > 0 && searchQuery.trim().length > 0) {
      const result = searchInArticleTemplates(articleTemplates, searchQuery);
      setFilteredArticleTemplates(result);
    } else {
      setFilteredArticleTemplates(undefined);
    }
  }, [articleTemplates, searchQuery]);

  const removeArticleTemplate = async () => {
    if (!itemToDelete) {
      toast.warn('Something went wrong. Please try again!');
      return;
    }
    try {
      const result = await deleteArticleTemplate(itemToDelete);
      if (result) {
        toast.success(`Artikelmallen ${itemToDelete} har tagits bort`);
        setReload((prev) => !prev);
        setItemToDelete(null);
      }
    } catch (e) {
      globalSate.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Tooltip title={'Lägg till ny mall'}>
        <Fab
          color='primary'
          aria-label='add'
          sx={{ position: 'absolute', bottom: 16, right: 16 }}
          onClick={() => navigate('article-templates/create')}
          disabled={organisation?.disabled}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Box sx={{ height: '45rem', p: 1.5 }}>
        <Typography variant='h6'>Hantera artikelmallar för {organisation?.name}</Typography>
        {organisation?.disabled && <Typography variant='h6'>(Organisationen är inaktiverad)</Typography>}
        <TextField
          id='search-input'
          label='Sök...'
          inputRef={searchInputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>
                {searchQuery ? (
                  <IconButton
                    aria-label='clear'
                    onClick={() => {
                      setSearchQuery('');
                      searchInputRef.current && searchInputRef.current.blur();
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            )
          }}
          variant='standard'
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
        <DataGrid
          rows={filteredArticleTemplates ?? articleTemplates}
          columns={columns}
          rowsPerPageOptions={[]}
          disableSelectionOnClick
          loading={loading}
          isCellEditable={() => false}
          hideFooter={true}
          rowHeight={48}
          /*           onRowClick={(rowParams) => {
            navigate(`article-templates/${rowParams.id}`, { replace: true });
          }} */
        />
      </Box>
      {itemToDelete && (
        <Dialog
          open={itemToDelete !== null}
          onClose={() => setItemToDelete(null)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Ta bort mall?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Vill du ta bort artikelmallen {itemToDelete}:{' '}
              {articleTemplates.find((i) => i.id === Number(itemToDelete))?.displayName} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button color='info' variant='contained' startIcon={<ClearIcon />} onClick={() => setItemToDelete(null)}>
              Avbryt
            </Button>
            <Button
              color='error'
              variant='contained'
              startIcon={<DeleteIcon />}
              onClick={() => removeArticleTemplate()}
            >
              Ta bort
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ArticleTemplates;
