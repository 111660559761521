import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = { onClick: () => void };
const HeaderBackButton = (props: Props) => (
  <Tooltip title='Go back'>
    <IconButton
      size='large'
      edge='start'
      color='inherit'
      aria-label='back'
      sx={{ flexGrow: 0 }}
      onClick={props.onClick}
    >
      <ArrowBackIcon />
    </IconButton>
  </Tooltip>
);

export default HeaderBackButton;
