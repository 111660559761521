import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { ClassifierReadView, OrganisationReadView } from '../../../types/types';
import { useCallback, useEffect, useState } from 'react';
import { getOrganisationById, updateOrganisation } from '../../../services/Organisations';
import { useParams } from 'react-router-dom';
import { getClassifiers } from '../../../services/Classifier';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import DnDListContainer from '../../common/DnDListContainer';
import { arraysAreEqual, isMobileDevice } from '../../../utils/HelperFunctions';
import { AxiosError } from 'axios';
import { useGlobalState } from '../../../context/GlobalState';
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

const EditOrganisationCategories = () => {
  const globalState = useGlobalState();
  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [allCategories, setAllCategories] = useState<ClassifierReadView[]>([]);
  const params = useParams<{ orgId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<ClassifierReadView[]>([]);
  const [mainCategories, setMainCategories] = useState<ClassifierReadView[]>([]);
  const [dataGridHeight, setDataGridHeight] = useState<number>(0);

  const ids = useCallback((categories: ClassifierReadView[]) => {
    return categories.map((cat) => cat.id);
  }, []);

  useEffect(() => {
    (async () => {
      if (params.orgId) {
        try {
          setLoading(true);
          const org = await getOrganisationById(params.orgId);
          if (!org) {
            return;
          }
          const allCategories = (await getClassifiers()).records;
          setAllCategories(allCategories);
          setOrganisation(org);
        } catch (e) {
          globalState.handleResponseError(e as AxiosError);
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId, globalState]);

  const setMainCategoriesById = useCallback(
    (ids: number[]) => {
      const categories: ClassifierReadView[] = [];
      ids.forEach((id) => {
        const category = allCategories.find((cat) => cat.id === id);
        if (category) {
          categories.push(category);
        }
      });
      setMainCategories(categories);
    },
    [allCategories]
  );

  const setSubCategoriesById = useCallback(
    (ids: number[]) => {
      const categories: ClassifierReadView[] = [];
      ids.forEach((id) => {
        const category = allCategories.find((cat) => cat.id === id);
        if (category) {
          categories.push(category);
        }
      });
      setSubCategories(categories);
    },
    [allCategories]
  );

  useEffect(() => {
    const ids = organisation?.classifierIds;
    if (ids) {
      const categoryIds = ids.filter((id) => allCategories.find((cat) => cat.id === id)?.type === 'CATEGORY');
      setMainCategoriesById(categoryIds);

      const subCategoryIds = ids.filter((id) => allCategories.find((cat) => cat.id === id)?.type === 'SUB_CATEGORY');
      setSubCategoriesById(subCategoryIds);
    }
    setDataGridHeight(calculateDataGridHeight());
  }, [organisation, allCategories, setSubCategoriesById, setMainCategoriesById]);

  const calculateDataGridHeight = (): number => {
    const dataGrid = document.querySelector('.categoryDataGridContainer');
    return dataGrid?.clientHeight ?? 0;
  };

  const mainOrderChange = (items: ClassifierReadView[]) => {
    setMainCategories(items);
  };

  const subOrderChange = (items: ClassifierReadView[]) => {
    setSubCategories(items);
  };

  const baseColDef: GridColDef = {
    field: '',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    hideable: false,
    flex: 1
  };

  const columnDefs: GridColDef[] = [
    { ...baseColDef, field: 'name', headerName: 'Kategorinamn' },
    {
      ...baseColDef,
      field: 'icon',
      headerName: 'Kategorikon',
      renderCell: (params) => {
        return <img src={params.row.icon} alt='Kategorikon' style={{ maxHeight: '90%', maxWidth: '90%' }} />;
      }
    }
  ];

  const _mainSelectionModel = ids(mainCategories);

  const _subSelectionModel = ids(subCategories);

  const _onMainSelectionModelChange = (selectionModel: GridSelectionModel) => {
    setMainCategoriesById(selectionModel.map(Number));
  };

  const _onSubSelectionModelChange = (selectionModel: GridSelectionModel) => {
    setSubCategoriesById(selectionModel.map(Number));
  };

  const revertChanges = () => {
    if (params.orgId) {
      getOrganisationById(params.orgId).then(setOrganisation);
    }
  };

  const saveChanges = () => {
    if (!organisation) {
      return;
    }
    const { id, articleTemplateCount, ...data } = organisation;
    updateOrganisation(id, {
      ...data,
      classifierIds: [...ids(mainCategories), ...ids(subCategories)]
    }).then((response) => {
      setOrganisation(response);
      toast.success('Ändringar sparades');
    });
  };

  const changeHappened = !arraysAreEqual(
    [...mainCategories, ...subCategories],
    organisation?.classifierIds?.map((id) => allCategories.find((cat) => cat.id === id)) ?? []
  );

  const renderClassifierCard = (
    category: ClassifierReadView,
    index?: number,
    draggableProps?: DraggableProvidedDraggableProps,
    dragHandleProps?: DraggableProvidedDragHandleProps
  ) => {
    return (
      <div
        style={{
          border: 'solid black 1px',
          margin: -5,
          padding: '0.4rem',
          borderRadius: '1em',
          backgroundColor: globalState.muiTheme.palette.primary.light
        }}
      >
        <Typography color={globalState.muiTheme.palette.primary.contrastText}>{` ${category.name}`}</Typography>
      </div>
    );
  };

  return (
    <Box sx={{ p: 1.5 }}>
      <Typography variant='h6'>{organisation?.name} kategorier</Typography>
      <Grid container direction='row' padding={2} rowSpacing={isMobileDevice() ? '2.5rem' : '0'}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} alignContent={'center'} className='categoryDataGridContainer'>
          <Typography variant='h6'>Huvudkategorier</Typography>
          <DataGrid
            rows={allCategories.filter((cat) => cat.type === 'CATEGORY')}
            columns={columnDefs}
            selectionModel={_mainSelectionModel}
            checkboxSelection
            rowsPerPageOptions={[100]}
            keepNonExistentRowsSelected
            onSelectionModelChange={_onMainSelectionModelChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} alignContent={'center'} className='categoryDataGridContainer'>
          <Typography variant='h6'>Underkategorier</Typography>
          <DataGrid
            rows={allCategories.filter((cat) => cat.type === 'SUB_CATEGORY')}
            columns={columnDefs}
            selectionModel={_subSelectionModel}
            rowsPerPageOptions={[100]}
            checkboxSelection
            keepNonExistentRowsSelected
            onSelectionModelChange={_onSubSelectionModelChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          alignContent={'center'}
          className='categoryDataGridContainer'
          height={dataGridHeight}
        >
          <Typography variant='h6'>Ordning</Typography>
          <Grid container direction='column' height={dataGridHeight}>
            <Grid item overflow='auto' height={'100%'} width={'50%'}>
              <Typography variant='h6'>Huvudkategorier</Typography>
              <DnDListContainer
                items={mainCategories}
                onReorder={mainOrderChange}
                keyExtractor={(cat) => cat.id}
                renderItem={renderClassifierCard}
              />
            </Grid>
            <Grid item overflow='auto' height={'100%'} width={'50%'}>
              <Typography variant='h6'>Underkategorier</Typography>
              <DnDListContainer
                items={subCategories}
                onReorder={subOrderChange}
                keyExtractor={(cat) => cat.id}
                renderItem={renderClassifierCard}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }} padding={2}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => saveChanges()}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => revertChanges()}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditOrganisationCategories;
