import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { RelativeRoutingType } from 'react-router-dom';

export type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
export const MAP_LIBRARIES: Libraries = ['drawing'];
export type ThemeInfo = {
  primaryColor: string;
  logo: string;
};

export enum ScanMode {
  TRANSFORM = 'TRANSFORM',
  SINGLE = 'SINGLE'
}

export interface CustomLocationState {
  prevPage?: string;
}
export interface CustomNavigateOptions {
  replace?: boolean;
  state?: CustomLocationState;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}
export type pathTo = '/' | '/scanner' | '/admin' | '/admin/organisations';
export interface CustomNavigateFunction {
  (to: pathTo, options?: CustomNavigateOptions): void;
  (delta: number): void;
}

export interface Graph {
  edges: [string, string][];
  nodes: { id: string; created: string }[];
}

export type ResponseType<T> = {
  data: T;
  headers?:
    | AxiosResponseHeaders
    | Partial<
        Record<string, string> & {
          'set-cookie'?: string[] | undefined;
        }
      >;
};
export type Location = Record<string, [number, number]>;
export type Locations = {
  reducedLocations: Location;
  allLocations: Location;
};

export enum IDs {
  CU = 'CU',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  C5 = 'C5',
  C6 = 'C6',
  C7 = 'C7',
  C8 = 'C8',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  PO = 'PO',
  PI1 = 'PI1',
  PI2 = 'PI2',
  PI3 = 'PI3',
  P1 = 'P1',
  PP = 'PP',
  MB = 'MB',
  CA = 'CA',
  O = 'O',
  CH = 'CH',
  PA = 'PA',
  E = 'E',
  SL = 'SL',
  PE = 'PE'
}

export const EPCToText: Record<IDs, { what: string; org: string; link?: string }> = {
  B1: { what: 'Nötkött', org: 'Bjursunds Slakteri AB', link: 'http://www.bjursundsslakteri.se/' },
  B2: { what: 'Nötkött', org: 'A J Dahlbergs Slakteri AB', link: 'https://dahlbergskott.se/' },
  B3: { what: 'Nötkött', org: 'Bjursunds Slakteri AB', link: 'http://www.bjursundsslakteri.se/' },
  B4: { what: 'Nötkött', org: 'A J Dahlbergs Slakteri AB', link: 'https://dahlbergskott.se/' },
  B5: { what: 'Nötkött', org: 'Bjursunds Slakteri AB', link: 'http://www.bjursundsslakteri.se/' },
  C1: { what: 'Ko', org: 'Bjursunds Säteri Lantbruks AB' },
  C2: { what: 'Ko', org: 'Murelo AB' },
  C3: { what: 'Ko', org: 'Hökfors Gård' },
  C4: { what: 'Ko', org: 'Norrmem Förvaltning AB' },
  C5: { what: 'Ko', org: 'Årberg Djur AB' },
  C6: { what: 'Ko', org: 'Framnäs Gård' },
  C7: { what: 'Ko', org: 'Karl & Erik Larsson' },
  C8: { what: 'Ko', org: 'Gert Berg' },
  CU: { what: 'Gurka', org: 'Järna Gurkodling AB', link: 'https://jarnagurkan.se/' },
  MB: { what: 'Köttbullar', org: 'Den Svenska Matfabriken AB', link: 'https://svenskamatfabriken.se/' },
  PI1: { what: 'Gris', org: 'Hilmers Lantbruk' },
  PI2: { what: 'Gris', org: 'Hagsgårds Lantbruk' },
  PI3: { what: 'Gris', org: 'Hans Rudell' },
  P1: { what: 'Potatis', org: 'Mårtorps gård' },
  PO: { what: 'Fläskkött', org: 'A J Dahlbergs Slakteri AB', link: 'https://dahlbergskott.se/' },
  PP: { what: 'Potatis', org: 'Skalerian AB', link: 'https://skaleriet.se/index.asp' },
  CA: { what: 'Morot', org: 'Morot' },
  CH: { what: 'Kyckling', org: 'Kyckling' },
  E: { what: 'Ägg', org: 'Hönseri' },
  O: { what: 'Lök', org: 'Lökodlare' },
  PA: { what: 'Packeri', org: 'Packeri' },
  PE: { what: 'Skaleri', org: 'Skaleri' },
  SL: { what: 'Slakteri', org: 'Slakteri' }
};

export const infinitePagination: BaseListParams = { limit: Number.MAX_SAFE_INTEGER, offset: 0 };

export interface TokenReadView {
  token: string;
  refreshToken: string | null; // in case of renewing token with refreshToken, the refreshToken value is null
  sessionId: string;
}

export interface SessionEndView {
  sessionId: string;
}

export interface CustomAxiosResponse extends AxiosResponse {
  data: { message: string; servlet: string; status: number | string; url: string };
}

export interface BaseListView<T> {
  limit: number;
  offset: number;
  totalCount: number;
  records: T[];
}

export interface BaseListParams {
  limit?: number;
  offset?: number;
}
//__________ Organisations types ____________
export interface OrganisationCreateView {
  name: string;
  lat: number | null;
  lng: number | null;
  identificationNumber: string;
  disabled: boolean;
  customUiEnabled: boolean;
  customUiColor: string | null;
  customUiIcon: string | null;
  streetAddress: string | null;
  city: string | null;
  country: string | null;
  postalCode: string | null;
  kommun: string | null;
  region: string | null;
  referralLink: string | null;
  homeScreenEnabled?: boolean;
  classifierIds?: number[];
  keywords?: string[];
  contactName: string | null;
  contactEmail: string | null;
  contactPhoneNumber: string | null;
  displayName: string | null;
  vatNumber: string | null;
  description?: string;
  customerCardEnabled?: boolean;
}
export interface OrganisationReadView extends OrganisationCreateView {
  id: number;
  articleTemplateCount: number;
}
export interface OrganisationListView extends BaseListView<OrganisationReadView> {}

export interface OrganisationSummaryView {
  id: number;
  name: string;
  lat: number;
  lng: number;
  disabled: boolean;
  icon: string | null;
  referralLink: string | null;
  keywords: string[];
  classifierIds: number[];
  customerCardEnabled: boolean;
}

export interface OrganisationSummaryListView extends BaseListView<OrganisationSummaryView> {}

export interface OrganisationBasicInfoView {
  id: number;
  name: string;
  customUiIcon: string | null;
  customUiEnabled: boolean;
  customUiColor: string;
}

//_________ Category types _____________
export interface ClassifierCreateView {
  name: string;
  icon: string;
  type: 'CATEGORY' | 'SUB_CATEGORY' | 'GROUP';
  priority: number;
  color: string;
}

export interface ClassifierReadView extends ClassifierCreateView {
  id: number;
  verifiedIcon?: string;
}

export interface ClassifierUpdateView extends ClassifierCreateView {}

export interface ClassifierListView extends BaseListView<ClassifierReadView> {}

//_________ Users types _____________
export interface UserReadView {
  id: number;
  email: string;
  isSuperUser: boolean;
}

export interface UserCreateView {
  email: string;
  password: string;
}

export interface UserListView extends BaseListView<UserReadView> {}

// _____ ARTICLE TEMPLATES ________

export interface ArticleTemplateUpdateView {
  templateName: string;
  displayName: string;
  mapIcon: string;
  menuIcon: string;
  organisationId: number;
  referralLink: string;
}

export interface ArticleTemplateCreateView extends ArticleTemplateUpdateView {
  sourceTemplates: number[]; // array of other templates IDs
}

export interface ArticleTemplateReadView extends ArticleTemplateCreateView {
  id: number;
  organisationName: string;
  lat: number;
  lng: number;
}

export interface ArticleTemplateListView extends BaseListView<ArticleTemplateReadView> {}

//_____OrganisationMembership_____
export enum MembershipRole {
  ADMIN = 'ADMIN',
  USER = 'USER'
}
export interface OrganisationMembershipCreateView {
  role: MembershipRole;
}

export interface OrganisationMembershipReadView {
  organisationId: string | number;
  userId: string | number;
  role: MembershipRole;
}

export interface ArticleCreateWithTemplateView {
  alternateId?: string;
  sourceArticles: number[];
}

export interface ArticleReadView {
  alternateIds: string[];
  displayName: string;
  id: number;
  lat: number;
  lng: number;
  mapIcon: string;
  menuIcon: string;
  organisationId: number;
  referralLink: string;
  sourceArticleIds: number[];
  organisationName: string;
  disabled: boolean;
  customUiColor: string | null;
  customIcon: string | null;
  urlAlias: string | null;
}

export interface ArticleCreateView {
  alternateId: string;
  displayName: string;
  organisationId: number;
  referralLink: string;
}

export interface ArticleListView extends BaseListView<ArticleReadView> {}

export interface ArticleGraphView {
  id: number;
  articles: ArticleReadView[];
}

export interface UrlReadView extends UrlCreateView {
  id: number;
  articleIds: number[];
}

export interface UrlListView extends BaseListView<UrlReadView> {}

export interface UrlCreateView {
  articleId?: number;
  articleIds: number[];
  url: string;
  epc: string;
  alternateId: string | null;
  externalLinks: ExternalLink[];
}

export interface UrlUpdateView extends UrlCreateView {}

export interface ExternalLink {
  externalUrl: string;
  displayText: string;
}

export interface ImportResponseView {
  added: number;
  updated: number;
  deleted: number;
  skipped: number;
  failedRows: string[][];
  skippedRows: string[][];
}

export interface AlternateIdReadView {
  alternateId: string;
  entityType: 'Article' | 'Custom URL';
  entityId: number;
}

export interface GroupCreateView {
  name: string;
  shortName: string;
  icon: string;
  logo: string;
  themeColor: string;
}

export interface GroupReadView extends GroupCreateView {
  id: number;
}

export interface GroupUpdateView extends GroupCreateView {}

export interface GroupSummaryView {
  id: number;
  name: string;
  icon: string;
  themeColor: string;
  logo: string;
}

export interface GroupListView extends BaseListView<GroupReadView> {}

export interface GroupMembershipReadView {
  id: number;
  icon?: string;
  groupId: number;
  organisationId: number;
}

export interface GroupMembershipCreateView {
  icon?: string;
}

export interface GroupMembershipUpdateView extends GroupMembershipCreateView {}

export interface OrganisationDescriptionCardCreateView {
  displayName?: string;
  description?: string;
  buttonText?: string;
  link1?: string;
  link2?: string;
  link3?: string;
  link3Text?: string;
  bigButtonLinks?: string[];
  organisationId: number;
}

export interface OrganisationDescriptionCardReadView extends OrganisationDescriptionCardCreateView {
  id: number;
}

export interface OrganisationDescriptionCardUpdateView extends OrganisationDescriptionCardCreateView {}
