import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppShellContext } from '../../shell/AppShellContext';
import HeaderBackButton from '../../common/HeaderBackButton';
import { useGlobalState } from '../../../context/GlobalState';
import { getOrganisationById } from '../../../services/Organisations';
import { toast } from 'react-toastify';
import { ArticleTemplateReadView, ArticleTemplateUpdateView, OrganisationReadView } from '../../../types/types';
import { AxiosError } from 'axios';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DnDFileInput from '../../common/DnDFileInput';
import {
  getArticleTemplateById,
  getArticleTemplates,
  updateArticleTemplate,
  updateSourceTemplates
} from '../../../services/ArticleTemplates';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridInputSelectionModel,
  GridSelectionModel
} from '@mui/x-data-grid';
import { searchInArticleTemplates } from '../../../utils/HelperFunctions';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import InfoIcon from '@mui/icons-material/Info';

const EditArticleTemplate = () => {
  const globalState = useGlobalState();
  const params = useParams<{ orgId: string; templateId: string }>();
  const appShellContext = useAppShellContext();
  const navigate = useNavigate();
  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [loading, setLoading] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [referralLink, setReferralLink] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [mapIcon, setMapIcon] = useState<string | null>(null);
  const [menuIcon, setMenuIcon] = useState<string | null>(null);
  const [sourceTemplates, setSourceTemplates] = useState<number[]>([]);
  const [switchState, setSwitchState] = useState<boolean>(false);
  const [articleTemplates, setArticleTemplates] = useState<ArticleTemplateReadView[]>([]);
  const [filteredArticleTemplates, setFilteredArticleTemplates] = useState<ArticleTemplateReadView[]>();
  const [originalTemplate, setOriginalTemplate] = useState<ArticleTemplateReadView>();
  const [originalMenuIcon, setOriginalMenuIcon] = useState<string>();
  const [originalMapIcon, setOriginalMapIcon] = useState<string>();

  const searchInputRef = useRef<HTMLInputElement>();
  const [searchQuery, setSearchQuery] = useState<string>('');

  // The onMount effect hook for fetching data. Also triggers on organisation Id or template Id changes
  useEffect(() => {
    if (!params.orgId || !params.templateId) {
      toast.error('Something went wrong. Please reload the page!');
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        setOrganisation(_organisation);
        const _articleTemplate = await getArticleTemplateById(params.templateId!);
        setOriginalTemplate(_articleTemplate);
        const _articleTemplates = await getArticleTemplates();
        setArticleTemplates(_articleTemplates.records.filter((i) => i.id !== _articleTemplate.id));
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.orgId, params.templateId]);

  useEffect(() => {
    if (originalTemplate) {
      initValues(originalTemplate);
    }
  }, [originalTemplate]);

  const initValues = (_values: ArticleTemplateReadView) => {
    setDisplayName(_values.displayName);
    setTemplateName(_values.templateName);
    setMenuIcon(_values.menuIcon);
    setMapIcon(_values.mapIcon);
    setOriginalMenuIcon(undefined);
    setOriginalMapIcon(undefined);
    setSourceTemplates(_values.sourceTemplates);
    setReferralLink(_values.referralLink);
    if (_values.sourceTemplates.length > 0) {
      setSwitchState(true);
    }
    // Hackish way to reload  the delete image
    setTimeout(() => {
      setOriginalMenuIcon(`${_values.menuIcon}`);
      setOriginalMapIcon(`${_values.mapIcon}`);
    }, 500);
  };

  // Effect hook for setting the header back button
  useEffect(() => {
    appShellContext.setHeaderLeft(
      <HeaderBackButton
        onClick={() => navigate(`/admin/organisations/${params.orgId}`, { replace: true, state: { tabIndex: 3 } })}
      ></HeaderBackButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect hook for filtering the table data. Triggers on search phrase changes.
  useEffect(() => {
    if (articleTemplates && articleTemplates.length > 0 && searchQuery.trim().length > 0) {
      const result = searchInArticleTemplates(articleTemplates, searchQuery);
      setFilteredArticleTemplates(result);
    } else {
      setFilteredArticleTemplates(undefined);
    }
  }, [articleTemplates, searchQuery]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'displayName', headerName: 'Namn', minWidth: 165, flex: 1 },
    { field: 'organisationName', headerName: 'Organisation', minWidth: 165, flex: 1 }
  ];

  const editTemplate = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!mapIcon || !menuIcon || !displayName) {
      toast.warn('Vänligen fyll i de obligatoriska uppgifterna');
      return;
    }
    if (!organisation || !originalTemplate) {
      toast.error('Something went wrong. please refresh the page!');
      return;
    }
    setLoading(true);
    try {
      const payload: ArticleTemplateUpdateView = {
        templateName,
        displayName,
        mapIcon,
        menuIcon,
        organisationId: Number(organisation.id),
        referralLink
      };
      const updatedTemplate = await updateArticleTemplate(originalTemplate.id, payload);
      const idsToDelete = originalTemplate.sourceTemplates.filter((item) => !sourceTemplates.includes(item));
      const idsToPut = sourceTemplates.filter((item) => !originalTemplate.sourceTemplates.includes(item));
      const updatedSources = await updateSourceTemplates(originalTemplate.id, idsToDelete, idsToPut);

      if (updatedTemplate && updatedSources) {
        toast.success(`Artikelmall med id:${updatedTemplate.id} tillagt`);
        setTimeout(() => {
          navigate(`/admin/organisations/${params.orgId}`, { replace: true, state: { tabIndex: 3 } });
        }, 500);
      }
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };
  const _onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
    if (!selectionModel || !details) {
      toast.error('Something went wrong');
    }
    const ids = selectionModel.map((i) => Number(i));
    setSourceTemplates([...ids]);
  };

  const _selectionModel: GridInputSelectionModel = articleTemplates
    .filter((template) => sourceTemplates.includes(template.id))
    .map((template) => template.id);

  const changeHappened =
    organisation &&
    originalTemplate &&
    (originalTemplate.displayName !== displayName.trim() ||
      originalTemplate.mapIcon !== mapIcon ||
      originalTemplate.menuIcon !== menuIcon ||
      originalTemplate.referralLink !== referralLink.trim() ||
      originalTemplate.templateName !== templateName.trim() ||
      JSON.stringify(originalTemplate.sourceTemplates).trim() !== JSON.stringify(sourceTemplates).trim());

  return (
    <Box className='organisationTabsMainContainer' sx={{ p: 1.5 }}>
      <Box>
        <Typography variant='h6'>Redigera artikelmallen för {organisation?.name}</Typography>
      </Box>
      <form onSubmit={editTemplate}>
        <Grid container justifyContent={'flex-start'} spacing={2} sx={{ flexGrow: 1, mt: 1 }}>
          <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
            <TextField
              required
              variant='outlined'
              value={organisation ? organisation.id : ''}
              label='Organisation id'
              fullWidth
              onFocus={() => toast.info('Du kan inte ändra organisationens id')}
            ></TextField>
          </Grid>
          <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
            <TextField
              required
              variant='outlined'
              value={organisation ? organisation.name : ''}
              label='Organisationsnamn'
              onFocus={() => toast.info('Du kan inte ändra organisationens namn')}
            ></TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              required
              variant='outlined'
              value={templateName}
              label='Artikelmallsnamn'
              onChange={(e) => setTemplateName(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <DriveFileRenameOutlineIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              value={referralLink}
              label='Referenslänk'
              onChange={(e) => setReferralLink(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      <p>Om fältet lämnas tomt kommer artikelmallen automatiskt hämta organisationens referenslänk.</p>
                    }
                  >
                    <InputAdornment position='start'>
                      <InfoIcon fontSize='small' color='info' />
                    </InputAdornment>
                  </Tooltip>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              required
              variant='outlined'
              value={displayName}
              label='Artikelnamn'
              onChange={(e) => setDisplayName(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <DriveFileRenameOutlineIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DnDFileInput
              onFileChange={(file) => setMenuIcon(file)}
              text={'Välj menyikon genom att ladda upp en SVG-fil'}
              defaultIcon={originalMenuIcon}
            ></DnDFileInput>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <DnDFileInput
              onFileChange={(file) => setMapIcon(file)}
              text={'Välj kartikon genom att ladda upp en SVG-fil'}
              defaultIcon={originalMapIcon}
            ></DnDFileInput>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Alert severity='info'>
              <Box>
                <Typography variant='caption'>
                  Vill du ange ett befintligt ursprung till denna artikelmall? Om så är fallet, välj mall(ar) från
                  listan nedan genom att kryssa i.{' '}
                </Typography>
                <Switch checked={switchState} onChange={() => setSwitchState((prev) => !prev)} color='secondary' />
              </Box>
            </Alert>
          </Grid>
          {switchState && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingBottom: 5 }}>
              <Box sx={{ height: '35rem' }}>
                <TextField
                  id='search-input'
                  label='Sök...'
                  inputRef={searchInputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        {searchQuery ? (
                          <IconButton
                            aria-label='clear'
                            onClick={() => {
                              setSearchQuery('');
                              searchInputRef.current && searchInputRef.current.blur();
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    )
                  }}
                  variant='standard'
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <DataGrid
                  rows={filteredArticleTemplates ?? articleTemplates}
                  columns={columns}
                  rowsPerPageOptions={[]}
                  loading={loading}
                  isCellEditable={() => false}
                  hideFooter={true}
                  rowHeight={48}
                  checkboxSelection
                  keepNonExistentRowsSelected
                  selectionModel={_selectionModel}
                  onSelectionModelChange={_onSelectionModelChange}
                />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={6} justifyContent={'center'} sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<UndoIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => originalTemplate && initValues(originalTemplate)}
              disabled={loading || !changeHappened}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Avbryt'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default EditArticleTemplate;
