import {
  ArticleReadView,
  OrganisationDescriptionCardReadView,
  OrganisationReadView,
  OrganisationSummaryView
} from '../../types/types';
import { useEffect, useLayoutEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  SxProps,
  Theme,
  Box,
  Divider,
  IconButton,
  Link as MUILink,
  Grid
} from '@mui/material';
import { getOrganisationMapInfo } from '../../services/Organisations';
import { getUrlFromLink, useWindowSize } from '../../utils/HelperFunctions';
import CancelIcon from '@mui/icons-material/Cancel';
import { InfoBox } from '@react-google-maps/api';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import { getDescriptionCardByOrgId } from '../../services/DescriptionCards';
import { generateMapLink } from '../adminPanel/editOrganisation/PreviewMapInfoBox';
import { toast } from 'react-toastify';
import InfoBoxSkeleton from './componentLoader/InfoBoxSkeleton';

const prepareReferralLink = (url: string) => {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `https://${url}`;
};
type Props = {
  organisationSummary?: OrganisationSummaryView;
  article?: ArticleReadView;
  onClose: () => void;
  isMobileDevice: boolean;
  parentOrganisationId?: number;
};
const MapInfoBox = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [organisationDescriptionCard, setOrganisationDescriptionCard] = useState<OrganisationDescriptionCardReadView>();
  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [parentOrganisation, setParentOrganisation] = useState<OrganisationReadView>();
  const [verifiedOrg, setVerifiedOrg] = useState<boolean>(false);
  const { windowSize } = useWindowSize();

  useLayoutEffect(() => {
    // Reset state variables synchronously
    setOrganisation(undefined);
    setOrganisationDescriptionCard(undefined);
    setParentOrganisation(undefined);
    setVerifiedOrg(false);
    setLoading(true);
  }, [props.article, props.organisationSummary, props.parentOrganisationId]);
  useEffect(() => {
    setLoading(true);
    if (props.organisationSummary || props.article) {
      (async () => {
        try {
          try {
            const _org = await getOrganisationMapInfo(
              `${
                props.organisationSummary
                  ? props.organisationSummary.id
                  : props.article
                  ? props.article.organisationId
                  : -1
              }`
            );
            if (_org) {
              setVerifiedOrg(_org.customerCardEnabled ?? false);
              setOrganisation(_org);
            }
          } catch (e) {
            console.log('Fetch main org failed:', e);
            toast.error('Det gick inte att hämta informationen!');
            setLoading(false);
            return;
          }
          if (props.parentOrganisationId) {
            const _parentOrg = await getOrganisationMapInfo(`${props.parentOrganisationId}`);
            setParentOrganisation(_parentOrg);
          }
          const _orgDescCard = await getDescriptionCardByOrgId(
            `${
              props.organisationSummary
                ? props.organisationSummary.id
                : props.article
                ? props.article.organisationId
                : 0
            }`
          );
          setOrganisationDescriptionCard(_orgDescCard);
        } catch (e) {
          console.log('Failed to fetch organisation!', e);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [props.article, props.organisationSummary, props.parentOrganisationId]);

  return loading ? (
    props.isMobileDevice ? (
      <Card
        sx={{
          ...styles.bottomSheetCard,
          width: windowSize[0]
        }}
      >
        <InfoBoxSkeleton isMobileDevice={props.isMobileDevice} />
      </Card>
    ) : (
      <InfoBox
        options={{
          enableEventPropagation: true,
          closeBoxURL: '',
          boxStyle: {
            boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
            backgroundColor: 'rgba(255,255,255,0)',
            width: `550px`
          }
        }}
        position={
          new google.maps.LatLng({
            lat: props.organisationSummary ? props.organisationSummary.lat : props.article ? props.article.lat : 0,
            lng: props.organisationSummary ? props.organisationSummary.lng : props.article ? props.article.lng : 0
          })
        }
      >
        <InfoBoxSkeleton isMobileDevice={props.isMobileDevice} />
      </InfoBox>
    )
  ) : !organisation ? null : props.isMobileDevice ? (
    verifiedOrg ? (
      <Card
        sx={{
          ...styles.bottomSheetCard,
          width: windowSize[0]
        }}
      >
        <CardContent>
          <InnerBox
            organisation={organisation}
            descriptionCard={organisationDescriptionCard}
            onClose={props.onClose}
            article={props.article}
            isMobileDevice={props.isMobileDevice}
            parentOrg={parentOrganisation}
          />
        </CardContent>
      </Card>
    ) : (
      <Card
        sx={{
          ...styles.bottomSheetCard,
          width: windowSize[0]
        }}
      >
        <CardContent>
          <InnerBoxFreemium
            organisation={organisation}
            descriptionCard={organisationDescriptionCard}
            onClose={props.onClose}
            article={props.article}
            isMobileDevice={props.isMobileDevice}
            parentOrg={parentOrganisation}
          />
        </CardContent>
      </Card>
    )
  ) : verifiedOrg ? (
    <InfoBox
      options={{
        enableEventPropagation: true,
        closeBoxURL: '',
        boxStyle: {
          boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
          backgroundColor: 'rgba(255,255,255,0)',
          width: `550px`
        }
      }}
      position={
        new google.maps.LatLng({
          lat: props.organisationSummary ? props.organisationSummary.lat : props.article ? props.article.lat : 0,
          lng: props.organisationSummary ? props.organisationSummary.lng : props.article ? props.article.lng : 0
        })
      }
    >
      <Card sx={styles.infoBoxCard}>
        <CardContent>
          <InnerBox
            organisation={organisation}
            descriptionCard={organisationDescriptionCard}
            onClose={props.onClose}
            article={props.article}
            isMobileDevice={props.isMobileDevice}
            parentOrg={parentOrganisation}
          />
        </CardContent>
      </Card>
    </InfoBox>
  ) : (
    <InfoBox
      options={{
        enableEventPropagation: true,
        closeBoxURL: '',
        boxStyle: {
          boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
          backgroundColor: 'rgba(255,255,255,0)',
          width: `550px`
        }
      }}
      position={
        new google.maps.LatLng({
          lat: props.organisationSummary ? props.organisationSummary.lat : props.article ? props.article.lat : 0,
          lng: props.organisationSummary ? props.organisationSummary.lng : props.article ? props.article.lng : 0
        })
      }
    >
      <Card sx={styles.infoBoxCard}>
        <CardContent>
          <InnerBoxFreemium
            organisation={organisation}
            descriptionCard={organisationDescriptionCard}
            onClose={props.onClose}
            article={props.article}
            isMobileDevice={props.isMobileDevice}
            parentOrg={parentOrganisation}
          />
        </CardContent>
      </Card>
    </InfoBox>
  );
};

/// _________________________________________________
type InfoBoxInnerBoxProps = {
  organisation: OrganisationReadView;
  descriptionCard: OrganisationDescriptionCardReadView | undefined;
  article?: ArticleReadView;
  onClose: () => void;
  isMobileDevice: boolean;
  parentOrg: OrganisationReadView | undefined;
};

const InnerBox = ({
  organisation,
  onClose,
  article,
  isMobileDevice,
  descriptionCard,
  parentOrg
}: InfoBoxInnerBoxProps) => {
  const [orgUiIcon, setOrgUiIcon] = useState<string | null>(null);
  const [orgName, setOrgName] = useState<string>('');
  const [description, setDescription] = useState<string | null>(null);

  useEffect(() => {
    const _orgUiIcon = organisation.customUiIcon;
    const _orgName =
      descriptionCard && descriptionCard.displayName
        ? descriptionCard.displayName
        : organisation && organisation.displayName
        ? organisation.displayName
        : organisation.name;
    const _description =
      descriptionCard && descriptionCard.description ? descriptionCard.description : organisation.description ?? null;

    setOrgUiIcon(_orgUiIcon);
    setOrgName(_orgName);
    setDescription(_description);
    return () => {
      setOrgUiIcon(null);
      setOrgName('');
      setDescription(null);
    };
  }, [descriptionCard, organisation]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        <Box sx={{ flex: 1, mr: 2 }}>
          <Box>
            {orgUiIcon && !descriptionCard?.displayName ? (
              <img
                src={orgUiIcon}
                alt='organisation-custom-icon'
                style={{ maxHeight: isMobileDevice ? '1.5rem' : '2rem' }}
              ></img>
            ) : (
              <Typography
                sx={styles.headerLogo}
                variant='h6'
                component='div'
                style={{ fontWeight: 'bold', fontFamily: 'serif' }}
              >
                {orgName}
              </Typography>
            )}
            <Box sx={{ position: 'absolute', top: 5, right: 5, textAlign: 'end', pb: 1 }}>
              <IconButton sx={{ pt: 0 }} onClick={() => onClose()}>
                <CancelIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ margin: '.5rem 0' }}></Divider>
          {article && (
            <>
              <Typography variant='subtitle2'>Artikel:</Typography>
              <Typography variant={isMobileDevice ? 'caption' : 'body2'}>{article.displayName}</Typography>
            </>
          )}
          {article && <Typography variant='subtitle2'>Företagsbeskrivning:</Typography>}
          {description ? (
            <Typography variant={'body2'}>{description}</Typography>
          ) : (
            <Typography align='left' variant={'body2'} textOverflow={'ellipsis'}>
              Ingen information tillgänglig.
            </Typography>
          )}
        </Box>

        <Box>
          <Button
            variant='contained'
            sx={{
              ...styles.groupButton,
              mt: 5,
              width: isMobileDevice ? '6rem' : '9rem',
              height: isMobileDevice ? '6rem' : '9rem'
            }}
            onClick={() =>
              descriptionCard && descriptionCard.bigButtonLinks && descriptionCard.bigButtonLinks.length > 0
                ? window.open(getUrlFromLink(descriptionCard.bigButtonLinks[0]).url)
                : toast.info('En karta har ännu inte länkats.')
            }
          >
            <img
              src={require('../../utils/img/multiMarker.png')}
              alt='Våra leverantörer'
              style={{ objectFit: 'contain', width: '75%', height: '75%' }}
            />

            <Typography
              sx={{ fontWeight: 'bold', color: 'white', mt: 1, fontSize: isMobileDevice ? '11px' : undefined }}
            >
              {descriptionCard && descriptionCard.buttonText ? descriptionCard.buttonText : ''}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box sx={{ flexDirection: 'row', alignItems: 'center', mt: 1.5 }}>
        {(descriptionCard?.link1 || organisation.referralLink) && (
          <Button
            variant='contained'
            sx={{
              ...styles.footerButtons,
              fontSize: isMobileDevice ? '11px' : '16px',
              p: isMobileDevice ? 1 : undefined
            }}
            onClick={() => {
              window.open(
                getUrlFromLink(
                  descriptionCard && descriptionCard.link1 ? descriptionCard.link1 : organisation.referralLink ?? ''
                ).url
              );
            }}
            startIcon={<HomeIcon />}
          >
            Hemsida
          </Button>
        )}

        <Button
          variant='contained'
          sx={{
            ...styles.footerButtons,
            fontSize: isMobileDevice ? '11px' : '16px',
            p: isMobileDevice ? 1 : undefined
          }}
          startIcon={<MapIcon />}
          onClick={() => {
            if (descriptionCard && descriptionCard.link2) window.open(getUrlFromLink(descriptionCard.link2!).url);
            window.open(generateMapLink(organisation.lat, organisation.lng));
          }}
        >
          Hitta Hit
        </Button>

        {descriptionCard && descriptionCard.link3 && (
          <Button
            variant='contained'
            sx={{
              ...styles.footerButtons,
              fontSize: isMobileDevice ? '11px' : '16px',
              p: isMobileDevice ? 1 : undefined,
              mt: descriptionCard.link3Text && descriptionCard.link3Text.length > 15 ? 1 : undefined
            }}
            onClick={() => window.open(getUrlFromLink(descriptionCard.link3!).url)}
          >
            {descriptionCard.link3Text ?? 'Återförsäljare'}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const InnerBoxFreemium = ({ organisation, onClose, article, isMobileDevice, parentOrg }: InfoBoxInnerBoxProps) => (
  <Box sx={{ flexGrow: 1 }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <Box sx={{ flex: 1, mr: 2 }}>
        {organisation.referralLink ? (
          <MUILink
            href={prepareReferralLink(organisation.referralLink)}
            target='_blank'
            color={'secondary'}
            sx={{ fontSize: '16px' }}
            rel='external'
          >
            {organisation.name}
          </MUILink>
        ) : (
          <Typography variant='body1' align='left'>
            {organisation.name}
          </Typography>
        )}
        <Divider sx={{ margin: '.5rem 0' }}></Divider>
        {article && (
          <>
            <Typography variant='subtitle2'>Artikel:</Typography>
            <Typography variant={isMobileDevice ? 'caption' : 'body2'}>{article.displayName}</Typography>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: window.innerWidth / 2
          }}
        >
          {organisation.keywords && organisation.keywords.length > 0 ? (
            <>
              {article && <Typography variant='subtitle2'>Företagsbeskrivning:</Typography>}
              <Grid container spacing={0}>
                {organisation.keywords!.map((keyword, index) => (
                  <Grid item key={`${index}-${keyword}`} xs={keyword.length > 16 ? 12 : 6}>
                    <Typography
                      align='left'
                      variant={isMobileDevice ? 'caption' : 'body2'}
                      noWrap
                      textOverflow={'ellipsis'}
                    >
                      {keyword}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <Grid item>
              {article && <Typography variant='subtitle2'>Företagsbeskrivning:</Typography>}
              <Typography align='left' variant={isMobileDevice ? 'caption' : 'body2'} textOverflow={'ellipsis'}>
                Ingen information tillgänglig.{' '}
                <a href='mailto:info@tractechnology.se?subject=Feedback' target='_blank' rel='noreferrer'>
                  Skicka gärna feedback här
                </a>{' '}
                om du har mer information.
              </Typography>
            </Grid>
          )}
        </Box>
        <Box sx={{ mt: 1 }}>
          <MUILink
            href={'https://tractechnology.se/aktivera/'}
            color={'secondary'}
            sx={{ fontSize: '16px' }}
            rel='noreferrer'
            target='_blank'
          >
            Äger du företaget? Aktivera dig här.
          </MUILink>
        </Box>
      </Box>

      <Box>
        <Box sx={{ textAlign: 'end', pb: 1 }}>
          <IconButton sx={{ pt: 0 }} onClick={() => onClose()}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            ...styles.groupButtonDisabled,
            width: isMobileDevice ? '7rem' : '9rem',
            height: isMobileDevice ? '7rem' : '9rem'
          }}
        >
          <img
            src={require('../../utils/img/multiMarker.png')}
            alt='Våra leverantörer'
            style={{ objectFit: 'fill', width: '75%', height: '75%' }}
          />
          {!isMobileDevice && (
            <Typography sx={{ fontWeight: 'bold', color: 'white', mt: 1 }}>Våra leverantörer</Typography>
          )}
        </Box>
      </Box>
    </Box>
    <Box sx={{ flexDirection: 'row', alignItems: 'center', mt: 1.5 }}>
      <Button
        variant='contained'
        sx={{
          ...styles.footerButtons,
          fontSize: isMobileDevice ? '11px' : '16px',
          p: isMobileDevice ? 1 : undefined
        }}
        disabled={!parentOrg || (parentOrg && !parentOrg.customerCardEnabled)}
        startIcon={<HomeIcon />}
        onClick={() => {
          if (organisation.referralLink) window.open(getUrlFromLink(organisation.referralLink).url);
        }}
      >
        Hemsida
      </Button>

      <Button
        variant='contained'
        sx={{ ...styles.footerButtons, fontSize: isMobileDevice ? '11px' : '16px', p: isMobileDevice ? 1 : undefined }}
        disabled
        startIcon={<MapIcon />}
      >
        Hitta Hit
      </Button>
      <Button
        variant='contained'
        sx={{ ...styles.footerButtons, fontSize: isMobileDevice ? '11px' : '16px', p: isMobileDevice ? 1 : undefined }}
        disabled
      >
        Återförsäljare
      </Button>
    </Box>
  </Box>
);

export default MapInfoBox;

const styles: { [key: string]: SxProps<Theme> } = {
  bottomSheetCard: {
    position: 'absolute',
    bottom: 0,
    zIndex: 999,
    maxHeight: '75%',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    overflow: 'scroll'
  },
  infoBoxCard: {
    borderRadius: '1rem',
    width: '550px'
  },
  headerLogo: { display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 },
  groupButton: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#04A9B5',
    p: '1rem 0',
    borderRadius: '1rem',
    justifyContent: 'center',
    alignItems: 'center',

    textTransform: 'none'
  },
  groupButtonDisabled: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#c9c9c9',
    p: 1,
    borderRadius: '1rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerButtons: {
    textTransform: 'none',
    color: 'white',
    bgcolor: '#04A9B5',
    mr: 1,

    borderRadius: '1rem',
    '&.Mui-disabled': {
      backgroundColor: '#c9c9c9',
      color: 'white',
      opacity: 1 // Override the default opacity
    }
  }
};
