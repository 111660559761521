import './AppShell.scss';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip from '@mui/material/Tooltip';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideMenu from './SideMenu';
import { useGlobalState } from '../../context/GlobalState';
import { useAppShellContext } from './AppShellContext';
import ShareModal from '../share/modal';
import { TbShare3 } from 'react-icons/tb';

import CookieConsent from 'react-cookie-consent';
import { SiGoogleanalytics } from 'react-icons/si';
import { GrPerformance } from 'react-icons/gr';
import { RiAdvertisementLine } from 'react-icons/ri';
import { VscTools } from 'react-icons/vsc';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { RiArrowDownSLine } from 'react-icons/ri';
import { sendToGoogleAnalytics } from '../../index';
import { useCookies } from 'react-cookie';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const AppShell = (props: { children?: any }) => {
  const appShellContext = useAppShellContext();
  const location = useLocation();
  const globalState = useGlobalState();
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [helpTooltipOpen, setHelpTooltipOpen] = useState<boolean>(false);
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const [shareUrl, setShareUrl] = useState<string>(window.location.href);

  const [showModalCookie, setShowModalCookie] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [cookies, setCookie] = useCookies(['consent']);

  const makeShareUrl = (url: string) => {
    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;

    // Remove 'zoom' and 'pinsize' from search parameters
    searchParams.delete('zoom');
    searchParams.delete('pinsize');

    // Rebuild the URL with the filtered search parameters
    const filteredParams = searchParams.toString();
    const queryString = filteredParams ? `?${filteredParams}` : '';

    return urlObj.origin + urlObj.pathname + queryString;
  };

  const toggleSideMenu = (b?: boolean) => {
    if (b) {
      setSideMenuOpen(b);
    } else {
      setSideMenuOpen(!sideMenuOpen);
    }
  };

  useEffect(() => {
    setShareUrl(window.location.href);
  }, [location.search]);

  useEffect(() => {
    const onFocus = () => setHasBeenFocused(true);

    document.addEventListener('focus', onFocus, true);
    document.addEventListener('touchstart', onFocus, true);
    document.addEventListener('mousedown', onFocus, true);
    return () => {
      document.removeEventListener('focus', onFocus, true);
      document.removeEventListener('touchstart', onFocus, true);
      document.removeEventListener('mousedown', onFocus, true);
    };
  }, []);

  const handleShareButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModalCookie = () => {
    setShowModalCookie(false);
  };

  useEffect(() => {
    globalState.resetTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const userConsent = cookies['consent'];
    if (userConsent === 'accepted') {
      // User previously accepted cookies
      setShowBanner(false); // Close the banner if consent is already accepted
      // initialize google analytics
      if (process.env.NODE_ENV === 'development') {
        console.log('User accepted the cookie consent and preferences already:', cookies);
      }
      if (process.env.NODE_ENV === 'production') {
        sendToGoogleAnalytics();
      }
    }
  }, [cookies]);

  const handleAccept = () => {
    if (process.env.NODE_ENV === 'development') {
      console.log('User accepted the cookie consent and preferences:', cookies);
    }
    if (process.env.NODE_ENV === 'production') {
      sendToGoogleAnalytics();
    }
    setCookie('consent', 'accepted', { path: '/' });
    setShowModalCookie(false);
    setShowBanner(false);
  };

  const handleDecline = () => {
    console.log('User declined the cookie consent');
    setCookie('consent', 'declined', { path: '/' });
    setShowModalCookie(false);
    setShowBanner(false);
  };

  const handleCustomize = () => {
    setShowModalCookie(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const initialSections = [
    {
      id: 1,
      title: 'Necessary',
      icon: <IoAlertCircleOutline />,
      icon2: <RiArrowDownSLine />,
      defaultText:
        'Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.',
      additionalText: 'No cookies to display.',
      expanded: false
    },
    {
      id: 2,
      title: 'Functional',
      icon: <VscTools />,
      icon2: <RiArrowDownSLine />,
      defaultText:
        'Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.Functional cookies help perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.',
      additionalText: 'No cookies to display.',
      expanded: false
    },
    {
      id: 3,
      title: 'Analytics',
      icon: <SiGoogleanalytics />,
      icon2: <RiArrowDownSLine />,
      defaultText:
        'Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.',
      additionalText: 'No cookies to display.',
      expanded: false
    },
    {
      id: 4,
      title: 'Performance',
      icon: <GrPerformance />,
      icon2: <RiArrowDownSLine />,
      defaultText:
        'Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.',
      additionalText: 'No cookies to display.',
      expanded: false
    },
    {
      id: 5,
      title: 'Advertisement',
      icon: <RiAdvertisementLine />,
      icon2: <RiArrowDownSLine />,
      defaultText:
        'Advertisement cookies are used to provide visitors with customized advertisements based on the pages you visited previously and to analyze the effectiveness of the ad campaigns.',
      additionalText: 'No cookies to display.',
      expanded: false
    }
  ];

  const [sections, setSections] = useState(initialSections);

  const toggleExpand2 = (id: any) => {
    const updatedSections = sections.map((section) =>
      section.id === id ? { ...section, expanded: !section.expanded } : section
    );
    setSections(updatedSections);
  };

  /**Receives the container ID  e.g. ink-qr-container  */

  return (
    <div className='appShellContainer'>
      <AppBar position='relative' className='appBarStyle'>
        <Toolbar>
          {appShellContext.headerLeft ?? (
            <Tooltip title='Open menu'>
              <IconButton
                onClick={() => {
                  toggleSideMenu(true);
                }}
                size='large'
                edge='end'
                color='inherit'
                aria-label='menu'
                sx={{ mr: 1.5, flexGrow: 0 }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title='Go to Home'>
            <IconButton
              color='inherit'
              aria-label='brand'
              sx={{ flexGrow: 1 }}
              onClick={() => {
                navigate('/', { replace: true });
              }}
            >
              <img src={globalState.currentTheme.logo} alt='brand' style={{ height: '1.5rem', maxHeight: '1.5rem' }} />
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={() => setHelpTooltipOpen((prev) => !prev)}
            size='large'
            edge='end'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 1.5, flexGrow: 0 }}
          >
            <RateReviewIcon />
          </IconButton>
          <Dialog open={helpTooltipOpen} onClose={() => setHelpTooltipOpen(false)}>
            <DialogTitle className='info-dialog-title' sx={{ backgroundColor: '#000927' }}>
              {'Rapportera fel!'}
            </DialogTitle>
            <DialogContent className='info-dialog-content' style={{ marginTop: '10px' }}>
              <Typography display={'block'}>
                Vi jobbar på att bli Sveriges ledande sökplattform för lokal mat och dryck. Målet är att ha 50 000
                företag i vår karta till slutet av året.
              </Typography>
              <Typography display={'block'}>Då är det lätt att det blir lite tokigt… </Typography>
              <br />
              <Typography display={'block'}>Har du sett något som inte stämmer?</Typography>
              <Typography display={'block'}>
                Hjälp oss genom att{' '}
                <a href='mailto:info@tractechnology.se?subject=Rapportera%20fel!'>skicka en e-post</a> med vad du har
                sett.
              </Typography>
              <br />
              <Typography display={'block'}>
                Läs mer om <a href='https://tractechnology.se/'>TracTechnology</a> och vår resa mot större öppenhet i
                livsmedelsbranschen.
              </Typography>
            </DialogContent>
            <DialogActions className='warning-dialog-content'>
              <Button onClick={() => setHelpTooltipOpen(false)} variant='contained'>
                Stäng
              </Button>
            </DialogActions>
          </Dialog>
          <button className='shareBtn' onClick={handleShareButtonClick}>
            <TbShare3 size={20} />
            Dela
          </button>
          {showModal && <ShareModal shareUrl={makeShareUrl(shareUrl)} onClose={handleCloseModal} />}

          <SideMenu anchor='left' open={sideMenuOpen} toggleMenu={toggleSideMenu} />
        </Toolbar>
      </AppBar>

      {showBanner && hasBeenFocused && (
        <CookieConsent
          location='bottom'
          buttonText=''
          declineButtonText=''
          cookieName='cookieConsent'
          enableDeclineButton
          onAccept={handleAccept}
          onDecline={handleDecline}
          style={{
            background: '#000a27',
            borderRadius: '10px',
            textAlign: 'center',
            color: 'white',
            border: 'none',
            opacity: 0.85
          }}
          buttonStyle={{
            display: 'none'
          }}
          declineButtonStyle={{
            display: 'none'
          }}
        >
          <div className={`cookie-banner${window.self !== window.top ? ' cookie-banner-iframe' : ''}`}>
            <span>
              <h3>We value your privacy</h3>
              We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our
              traffic. By clicking "Accept All", you consent to our use of cookies.
            </span>
            <div className='cookie-buttons'>
              <Button onClick={handleAccept}>Accept All</Button>
              <Button onClick={handleDecline}>Reject All</Button>
              <Button className='customize-button' onClick={handleCustomize}>
                Customize
              </Button>
            </div>
          </div>
        </CookieConsent>
      )}
      <div className={`cookie-modal ${showModalCookie ? 'active' : ''}`} onClick={handleCloseModalCookie}>
        <div className='cookie-modal-content' onClick={(e) => e.stopPropagation()}>
          <div className='modal-header'>
            <h3>Customize Consent Preferences</h3>
            <button className='close-button' onClick={handleCloseModalCookie}>
              X
            </button>
          </div>
          <hr></hr>
          <div>
            <p className='text'>
              We use cookies to help you navigate efficiently and perform certain functions. You will find detailed
              information about all cookies under each consent category below.
            </p>
            <p className='text'>
              The cookies that are categorized as "Necessary" are stored on your browser as they are essential for
              enabling the basic functionalities of the site.
            </p>
            {expanded ? (
              <>
                <p className='text'>
                  We also use third-party cookies that help us analyze how you use this website, store your preferences,
                  and provide the content and advertisements that are relevant to you. These cookies will only be stored
                  in your browser with your prior consent.
                </p>
                <p className='text'>
                  You can choose to enable or disable some or all of these cookies but disabling some of them may affect
                  your browsing experience.
                </p>
                <button className='expand' onClick={toggleExpand}>
                  Show Less
                </button>
              </>
            ) : (
              <button className='expand' onClick={toggleExpand}>
                Show More
              </button>
            )}
          </div>
          <svg height='1' width='100%'>
            <line x1='0' y1='0' x2='100%' y2='0' style={{ stroke: 'white', strokeWidth: '1' }} />
          </svg>
          <div>
            {sections.map((section, index) => (
              <div key={section.id}>
                <span onClick={() => toggleExpand2(section.id)} style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '20px',
                      padding: '10px'
                    }}
                  >
                    {section.icon}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: 'bold'
                    }}
                  >
                    {section.title}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                      fontSize: '20px',
                      padding: '5px'
                    }}
                  >
                    {section.icon2}
                  </div>
                </span>
                <p className='text'>{section.defaultText}</p>
                {section.expanded && <div className='extraText'>{section.additionalText}</div>}
                {index !== sections.length - 1 && ( // Render the line for all sections except the last one
                  <svg height='1' width='100%'>
                    <line x1='0' y1='0' x2='100%' y2='0' style={{ stroke: 'white', strokeWidth: '1' }} />
                  </svg>
                )}
              </div>
            ))}
          </div>
          <div className='modal-buttons'>
            <Button className='customize-button' onClick={handleDecline}>
              Reject All
            </Button>
            <Button className='customize-button' onClick={handleAccept}>
              Save My Preferences
            </Button>
            <Button className='customize-button' onClick={handleAccept}>
              Accept All
            </Button>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default AppShell;
