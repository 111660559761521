import { QRCodeSVG } from 'qrcode.react';
import { memo, useEffect, useState } from 'react';
import { useGlobalState } from '../../../context/GlobalState';
import { arraysAreEqual, isValidCustomUrlPath, prepareOrgNameForLink } from '../../../utils/HelperFunctions';
import defaultFrame from '../../../utils/img/ttQRFrame.svg';
import { toast } from 'react-toastify';
import { getArticleById } from '../../../services/Article';
import { deleteUrl, patchUrl } from '../../../services/URLs';
import { ArticleReadView, ExternalLink, OrganisationReadView, UrlReadView, UrlUpdateView } from '../../../types/types';
import { AxiosError } from 'axios';
import { HttpStatusCode } from '../../../http/http';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FreeTextListInput from '../../common/FreeTextListInput';
import ExternalLinkListInput from './ExtrnalLinkListInput';

type UrlItemProps = {
  urlObj: UrlReadView;
  orgObj: OrganisationReadView;
  articles: ArticleReadView[];
  forceReload: () => void;
};
const UrlItem = memo((props: UrlItemProps) => {
  const globalState = useGlobalState();

  const [url, setUrl] = useState(props.urlObj.url.split('/').pop() ?? '');
  const [epc, setEpc] = useState<string>(props.urlObj.epc);
  const [alternateId, setAlternateId] = useState<string | null>(props.urlObj.alternateId);
  const [loading, setLoading] = useState(false);
  const [fullLink, setFullLink] = useState<string>(
    `${window.location.origin}/map/${prepareOrgNameForLink(props.orgObj.name)}/${url}`
  );
  const [fullQrCodeUrl, setFullQrCodeUrl] = useState<string>();
  const [urlArticleIds, setUrlArticleIds] = useState<number[]>(props.articles.map((art) => art.id));
  const [externalLinks, setExternalLinks] = useState<ExternalLink[]>(props.urlObj.externalLinks);

  useEffect(() => {
    setFullLink(`${window.location.origin}/map/${prepareOrgNameForLink(props.orgObj.name)}/${url}`);
  }, [url, props.orgObj.name]);

  useEffect(() => {
    const canvas = document.getElementById(`canvas_${url}`) as HTMLCanvasElement;
    const context = canvas.getContext('2d');

    context?.clearRect(0, 0, canvas.width, canvas.height);
    // draw background on canvas,
    const bg = document.createElement('img');
    bg.src = defaultFrame;
    const qrDataURL = makeQrCodeUrl(`qr_${url}`);
    bg.onload = () => {
      // Set canvas dimensions based on background image NOTE: This will need to be tuned if we change the image or qr code size
      const scaleFactor = 0.37;
      const scaledWidth = bg.width * scaleFactor;
      const scaledHeight = bg.height * scaleFactor;
      canvas.width = scaledWidth;
      canvas.height = scaledHeight;

      context?.drawImage(bg, 0, 0, scaledWidth, scaledHeight);

      const qrImg = new Image();
      qrImg.src = qrDataURL;

      qrImg.onload = () => {
        const qrX = scaledWidth / 2 - qrImg.width / 2;
        const qrY = 98;
        context?.drawImage(qrImg, qrX, qrY, qrImg.width, qrImg.height);
        setFullQrCodeUrl(canvas.toDataURL());
      };
    };
  }, [url]);

  const resetInputs = () => {
    setUrl(props.urlObj.url.split('/').pop() ?? '');
    setEpc(props.urlObj.epc);
    setAlternateId(props.urlObj.alternateId);
    setUrlArticleIds(props.articles.map((art) => art.id));
    setExternalLinks(props.urlObj.externalLinks);
  };

  const editUrl = async () => {
    if (!isValidCustomUrlPath(url)) {
      toast.warning(' Namnet på länken är fel');
      return;
    }
    if (urlArticleIds.length === 0) {
      toast.warning('Vänligen ange EPC/Artikel-ID');
      return;
    }
    setLoading(true);
    try {
      let errorOccured = false;
      for (const articleId of urlArticleIds) {
        try {
          const article = await getArticleById(articleId);
          if (article.organisationId !== props.orgObj.id) {
            toast.error(`Artikeln med epc ${articleId} är inte för den här organisationen`);
            setUrlArticleIds(urlArticleIds.filter((id) => id !== articleId));
            errorOccured = true;
          }
        } catch (e) {
          setUrlArticleIds(urlArticleIds.filter((id) => id !== articleId));
          toast.error(`Artikeln med epc ${articleId} finns inte.`);
          errorOccured = true;
        }
      }
      if (errorOccured) {
        return;
      }
      const link = `/map/${prepareOrgNameForLink(props.orgObj.name)}/${url}`;
      const _data = await patchUrl(Number(props.orgObj.id), props.urlObj.id, {
        url: link,
        epc,
        articleIds: urlArticleIds,
        alternateId: alternateId && alternateId !== '' ? alternateId : null,
        externalLinks: externalLinks
      } as UrlUpdateView);
      toast.success('Länken uppdaterad: ' + _data.url);
      props.forceReload();
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipBoard = () => {
    const link = `${window.location.origin}/map/${prepareOrgNameForLink(props.orgObj.name)}/${url}`;
    navigator.clipboard.writeText(link);
  };

  const removeTheLink = async () => {
    setLoading(true);
    try {
      const status = await deleteUrl(props.urlObj.id);
      if ((status && status === HttpStatusCode.Ok) || status === HttpStatusCode.NoContent) {
        toast.success('Länken togs bort ');
      }
      props.forceReload();
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const makeQrCodeUrl = (imgId: string) => {
    const qrHtml = document.getElementById(imgId);
    if (qrHtml) {
      // Convert the SVG element to a data URL
      const svgData = new XMLSerializer().serializeToString(qrHtml);
      const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
      return URL.createObjectURL(svgBlob);
    } else return '';
  };

  const printQrCode = () => {
    const qrCode = document.getElementById(`full_img_${url}`);
    if (qrCode) {
      const win = window.open();
      if (win) {
        const qrClone = qrCode.cloneNode() as HTMLImageElement;
        win.document.body.appendChild(qrClone);
        qrClone.onload = () => {
          win.print();
        };
      }
    }
  };

  const alternateIdsAreEqual = (alt1: string | null, alt2: string | null) => {
    return alt1 === alt2 || (alt1 === null && alt2 === '') || (alt1 === '' && alt2 === null);
  };

  const hasChanges =
    epc !== props.urlObj.epc ||
    url !== props.urlObj.url.split('/').pop() ||
    !alternateIdsAreEqual(alternateId, props.urlObj.alternateId) ||
    !arraysAreEqual(
      urlArticleIds,
      props.articles.map((art) => art.id)
    ) ||
    !arraysAreEqual(
      externalLinks,
      props.urlObj.externalLinks,
      (link1, link2) => link1.displayText === link2.displayText && link1.externalUrl === link2.externalUrl
    );

  return (
    <Box sx={{ mt: 3 }}>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container spacing={1} sx={{ mt: 1, alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <TextField
                fullWidth
                label={'Namn'}
                variant='outlined'
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <Tooltip title={`${window.location.origin}/map/${prepareOrgNameForLink(props.orgObj.name)}/`}>
                      <InputAdornment position='start'>
                        <LinkIcon />
                      </InputAdornment>
                    </Tooltip>
                  )
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Tooltip title='Formatet är "alternate-XXXXXXXXXXXXXX"'>
                <TextField
                  fullWidth
                  label={'alternate-id'}
                  variant='outlined'
                  value={alternateId ?? ''}
                  onChange={(e) => setAlternateId(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <QrCode2Icon />
                      </InputAdornment>
                    )
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <ButtonGroup fullWidth>
                {hasChanges && (
                  <>
                    <Tooltip title='Spara ändringarna i länken'>
                      <Button
                        variant='contained'
                        color='secondary'
                        disabled={loading || (url.trim().length === 0 && epc.trim().length === 0)}
                        onClick={() => {
                          editUrl();
                        }}
                      >
                        {loading ? <CircularProgress size={'1rem'} /> : <SaveIcon />}
                      </Button>
                    </Tooltip>
                    <Tooltip title='ångra ändringarna'>
                      <Button
                        variant='contained'
                        color='warning'
                        disabled={loading || (url.trim().length === 0 && epc.trim().length === 0)}
                        onClick={() => {
                          resetInputs();
                        }}
                      >
                        {loading ? <CircularProgress size={'1rem'} /> : <UndoIcon />}
                      </Button>
                    </Tooltip>
                  </>
                )}
                <Tooltip title='kopiera länken till urklipp'>
                  <Button variant='contained' color='success' onClick={() => copyToClipBoard()}>
                    <ContentCopyIcon />
                  </Button>
                </Tooltip>
                <Tooltip title='Ta bort länken'>
                  <Button variant='contained' color='error' onClick={() => removeTheLink()}>
                    <DeleteIcon />
                  </Button>
                </Tooltip>
                <Tooltip title='generate qr code for link'>
                  <Button variant='contained' color='primary' onClick={() => printQrCode()}>
                    <QrCode2Icon />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FreeTextListInput
                onChange={(inputs) => setUrlArticleIds(inputs.map(Number))}
                initialValues={urlArticleIds.map(String)}
                label='EPCs'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <ExternalLinkListInput externalLinks={externalLinks} onChange={setExternalLinks} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <div hidden>
        <QRCodeSVG id={`qr_${url}`} value={fullLink ?? ''} />
        <canvas id={`canvas_${url}`} />
        <img id={`full_img_${url}`} src={fullQrCodeUrl} alt='qr code' />
      </div>
    </Box>
  );
});
export default UrlItem;
