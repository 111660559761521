//import { useState } from 'react';
import './ScannerScreen.scss';
import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createSearchParams, useNavigate } from 'react-router-dom';
import QrScanner from 'qr-scanner';
import { ScanMode } from '../../types/types';
import { useGlobalState } from '../../context/GlobalState';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Scanner from './Scanner';
import { toast } from 'react-toastify';
import { isCustomUrl, retrieveEpc } from '../../utils/HelperFunctions';
import { useAppShellContext } from '../shell/AppShellContext';
import { useEffect, useRef } from 'react';
import HeaderBackButton from '../common/HeaderBackButton';

const ScannerScreen = () => {
  const globalState = useGlobalState();
  const navigate = useNavigate();
  const appShellContext = useAppShellContext();
  const scannerContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (globalState.scanMode === undefined) {
      toast.error('Something went wrong. Try again');
      navigate('/', { replace: true });
      if (globalState.scanMode === ScanMode.TRANSFORM && globalState.menuMode === undefined) {
        toast.error('Something went wrong. Select item from menu again');
        navigate('/', { replace: true });
      }
    }
    appShellContext.setHeaderLeft(
      <HeaderBackButton
        onClick={() => {
          globalState.setScannedItems([]);
          globalState.setMenuMode(undefined);
          globalState.setScanMode(undefined);
          navigate('/', { replace: true });
        }}
      />
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onDecode: (result: QrScanner.ScanResult) => void = (result) => {
    console.log('Scann result:', result.data);
    const { scannedItemsRef, setScannedItems, scanModeRef } = globalState;
    const epc = retrieveEpc(result.data);
    if (epc) {
      if (scanModeRef.current === ScanMode.SINGLE) {
        if (isCustomUrl(epc)) {
          if (epc.startsWith('http') || epc.startsWith('https')) {
            const path = new URL(epc).pathname;
            navigate(path);
          } else {
            navigate(epc);
          }
        } else {
          navigate({ pathname: '/', search: createSearchParams({ epc }).toString() }, { replace: true });
        }
      } else {
        if (!scannedItemsRef.current.includes(epc)) {
          toast.success('Ny QR-kod har lagts till', { icon: <QrCode2Icon /> });
          setScannedItems([...scannedItemsRef.current, epc]);
        } else {
          const latItem =
            scannedItemsRef.current.length > 0 ? scannedItemsRef.current[scannedItemsRef.current.length - 1] : 'N/A';
          if (latItem !== epc) {
            toast.info('QR-kod redan tillagd!', { icon: <QrCode2Icon /> });
          }
        }
      }
    } else {
      toast.warn('Ogiltig URL-adress för QR-kod !');
    }
  };

  const _handleClick = () => {
    if (globalState.scannedItems.length === 0) {
      navigate('transform/alternate');
    } else {
      navigate('transform');
    }
  };

  return (
    <Box className='scanner-screen-main-container'>
      <Typography variant='caption' sx={{ color: grey[100], margin: '0 1.5rem 1rem 1.5rem' }} align='center'>
        {globalState.scanMode === ScanMode.SINGLE
          ? 'Håll QR-läsaren över objektet för att spåra produkten.'
          : globalState.scanMode === ScanMode.TRANSFORM
          ? 'Håll QR-läsaren över objekten för att skanna ingående artiklar. Om du vill skapa artikeln direkt klicka på "Skapa artikel direkt" nedan'
          : ''}
      </Typography>
      <div className='scanner-container' ref={scannerContainerRef}>
        {scannerContainerRef.current && (
          <Scanner onDecode={_onDecode} containerHeight={scannerContainerRef.current.getBoundingClientRect().height} />
        )}
      </div>

      {globalState.scanMode === ScanMode.TRANSFORM && (
        <Button fullWidth variant='contained' color='secondary' className='footer' onClick={() => _handleClick()}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='button' sx={{ textTransform: 'none' }}>
              {globalState.scannedItems.length > 0 ? 'Klar' : 'Skapa artikel direkt'}
            </Typography>
          </Box>
          {globalState.scannedItems.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant='button' sx={{ fontWeight: 'bold', marginRight: 1 }}>
                {globalState.scannedItems.length}
              </Typography>
              <QrCode2Icon />
            </Box>
          )}
        </Button>
      )}
    </Box>
  );
};

export default ScannerScreen;
