import { Box, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppShellContext } from '../shell/AppShellContext';
import { useEffect, useState } from 'react';
import HeaderBackButton from '../common/HeaderBackButton';

import { useTheme } from '@mui/material/styles';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/AccountTree';
import { useWindowSize } from '../../utils/HelperFunctions';
import SystemCategories from './SystemCategories';
import SystemGroups from './SystemGroups';

type Props = {};
type LocationState = { tabIndex: number };
const OrganisationTabs = (props: Props) => {
  const appShellContext = useAppShellContext();
  const navigate = useNavigate();
  const location = useLocation();
  const tabIndex = location.state ? (location.state as LocationState).tabIndex : null;
  const theme = useTheme();
  const { isMobileDevice } = useWindowSize();

  const [tabValue, setTabValue] = useState(tabIndex ?? 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    appShellContext.setHeaderLeft(<HeaderBackButton onClick={() => navigate('/admin/', { replace: true })} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className='organisationTabsMainContainer'>
      <Box sx={{ borderBottom: 0.5, borderColor: 'divider', boxShadow: '0px 2px 12px 0px  grey' }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='secondary'
          variant='fullWidth'
          aria-label='Settings sections'
        >
          <Tab
            icon={<CategoryIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Kategorier'}
            {...a11yProps(0)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
          <Tab
            icon={<GroupIcon />}
            iconPosition='start'
            label={isMobileDevice ? '' : 'Tillhörigheter'}
            {...a11yProps(0)}
            sx={{ textTransform: 'none', minHeight: '45px', height: '45px' }}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} dir={theme.direction}>
        <SystemCategories />
      </TabPanel>
      <TabPanel value={tabValue} index={1} dir={theme.direction}>
        <SystemGroups />
      </TabPanel>
    </Box>
  );
};

export default OrganisationTabs;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}
