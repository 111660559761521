import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography
} from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import NumbersIcon from '@mui/icons-material/Numbers';
import PlaceIcon from '@mui/icons-material/Place';
import LinkIcon from '@mui/icons-material/Link';
import GenericMap from '../common/GenericMap';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import './AdminPanelScreen.scss';
import { createOrganisation } from '../../services/Organisations';
import { toast } from 'react-toastify';
import { useGlobalState } from '../../context/GlobalState';
import { AxiosError } from 'axios';
import { useAppShellContext } from '../shell/AppShellContext';
import { useNavigate } from 'react-router-dom';
import HeaderBackButton from '../common/HeaderBackButton';
import FreeTextListInput from '../common/FreeTextListInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEmail, testPhoneNumber } from '../../utils/HelperFunctions';

const CreateOrganisation = () => {
  const globalState = useGlobalState();
  const appShellContext = useAppShellContext();
  const navigate = useNavigate();
  const [marker, setMarker] = useState<google.maps.LatLngLiteral>();
  const [name, setName] = useState<string>();
  const [identificationNumber, setIdentificationNumber] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [streetAddress, setStreetAddress] = useState<string>();
  const [city, setCity] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [referralLink, setReferralLink] = useState<string | null>(null);
  const [fetchingGeoposition, setFetchingGeoposition] = useState<boolean>(false);
  const [geopositionIsFetched, setGeopositionIsFetched] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [contactName, setContactName] = useState<string | null>(null);
  const [contactEmail, setContactEmail] = useState<string | null>(null);
  const [contactPhoneNumber, setContactPhoneNumber] = useState<string | null>(null);
  const [region, setRegion] = useState<string | null>(null);
  const [kommun, setKommun] = useState<string | null>(null);
  const [vatNumber, setVatNumber] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState<string | null>(null);

  const isFormValid =
    name !== undefined &&
    marker !== undefined &&
    identificationNumber !== undefined &&
    streetAddress !== undefined &&
    city !== undefined &&
    country !== undefined &&
    postalCode !== undefined &&
    name?.trim() !== '' &&
    identificationNumber.trim() !== '' &&
    marker.lat.toString().trim() !== '' &&
    marker.lng.toString().trim() !== '' &&
    streetAddress.trim() !== '' &&
    city.trim() !== '' &&
    country.trim() !== '' &&
    postalCode.trim() !== '';

  const addOrganisation = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!isFormValid) {
      toast.error('Please fill the required fields');
      return;
    }
    try {
      setLoading(true);
      const data = await createOrganisation({
        name,
        lat: marker.lat,
        lng: marker.lng,
        identificationNumber,
        disabled: false,
        customUiEnabled: false,
        customUiColor: null,
        customUiIcon: null,
        streetAddress: streetAddress,
        city: city,
        country: country,
        postalCode: postalCode,
        kommun: kommun,
        region: region,
        referralLink: referralLink,
        homeScreenEnabled: true,
        keywords: keywords,
        contactName: contactName,
        contactEmail: contactEmail,
        contactPhoneNumber: contactPhoneNumber,
        vatNumber: vatNumber,
        displayName: displayName
      });
      toast.success(`Organisation ${name} with ID: ${data.id} created!`);
      setName(undefined);
      setMarker(undefined);
      navigate('/admin/organisations', { replace: true });
    } catch (e) {
      const error = e as AxiosError<{ code: string; message: string }>;
      toast.error(error.response?.data.message);
      globalState.handleResponseError(e as AxiosError<{ code: string; message: string }>);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    appShellContext.setHeaderLeft(
      <HeaderBackButton onClick={() => navigate('/admin/organisations', { replace: true })} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMarkerFromAddress = async (address: string) => {
    setFetchingGeoposition(true);
    try {
      const geocoder = new google.maps.Geocoder();
      const result = await geocoder.geocode({ address: address });
      const location = result.results?.[0].geometry.location;
      setMarker({ lat: location.lat(), lng: location.lng() });
      setGeopositionIsFetched(true);
    } catch (e) {
      toast.error('Ett fel inträffade, vänligen välj geoposition manuellt', { autoClose: 7000 });
    } finally {
      setFetchingGeoposition(false);
    }
  };

  const allAddressComponentsPresent =
    streetAddress !== undefined &&
    city !== undefined &&
    country !== undefined &&
    postalCode !== undefined &&
    streetAddress.trim() !== '' &&
    city.trim() !== '' &&
    country.trim() !== '' &&
    postalCode.trim() !== '';

  return (
    <Box className='createOrganisationMainContainer'>
      <Box>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Ny organisation
        </Typography>
      </Box>
      <form onSubmit={(e) => addOrganisation(e)}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Organisationsuppgifter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='organisation-name'>Namn</InputLabel>
                  <OutlinedInput
                    id='organisation-name'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Namn'
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth required>
                  <InputLabel htmlFor='organisation-number'>Organisationsnummer</InputLabel>
                  <OutlinedInput
                    id='organisation-number'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <NumbersIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Organisationsnummer'
                    onChange={(e) => setIdentificationNumber(e.target.value)}
                    value={identificationNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Tooltip title='Ange det namn du vill att din organisation ska visas med. Om du lämnar fältet tomt kommer organisationsnamnet att visas.'>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='display-name'>Visningsnamn</InputLabel>
                    <OutlinedInput
                      id='display-name'
                      type='text'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton edge='end'>
                            <DriveFileRenameOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Visningsnamn'
                      onChange={(e) => setDisplayName(e.target.value)}
                      value={displayName}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='vat-number'>VAT-nummer</InputLabel>
                  <OutlinedInput
                    id='vat-number'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <NumbersIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='VAT-nummer'
                    onChange={(e) => setVatNumber(e.target.value)}
                    value={vatNumber}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='referral-link'>Referenslänk</InputLabel>
                  <OutlinedInput
                    id='referral-link'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <LinkIcon />
                      </InputAdornment>
                    }
                    label='Referenslänk'
                    onChange={(e) => setReferralLink(e.target.value)}
                    value={referralLink ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FreeTextListInput
                  limit={10}
                  onChange={setKeywords}
                  initialValues={keywords ?? []}
                  label={'Sökord/Produkter'}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Kontaktuppgifter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='contactName'>Kontaktnamn</InputLabel>
                  <OutlinedInput
                    id='contactName'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Kontaktnamn'
                    onChange={(e) => setContactName(e.target.value)}
                    value={contactName ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='contactEmail'>E-postadress</InputLabel>
                  <OutlinedInput
                    id='contactEmail'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='E-postadress'
                    error={contactEmail !== null && contactEmail.trim() !== '' && !isEmail(contactEmail)}
                    onChange={(e) => setContactEmail(e.target.value)}
                    value={contactEmail ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='contactPhoneNumber'>Telefonnummer</InputLabel>
                  <OutlinedInput
                    id='contactPhoneNumber'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Telefonnummer'
                    onChange={(e) => setContactPhoneNumber(e.target.value)}
                    error={
                      contactPhoneNumber !== null &&
                      contactPhoneNumber.trim() !== '' &&
                      !testPhoneNumber(contactPhoneNumber)
                    }
                    value={contactPhoneNumber ?? ''}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Adressuppgifter</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='street-address'>Gatuadress</InputLabel>
                  <OutlinedInput
                    id='street-address'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Gatuadress'
                    onChange={(e) => setStreetAddress(e.target.value)}
                    value={streetAddress ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='city'>Ort</InputLabel>
                  <OutlinedInput
                    id='city'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Ort'
                    onChange={(e) => setCity(e.target.value)}
                    value={city ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='kommun'>Kommun</InputLabel>
                  <OutlinedInput
                    id='kommun'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Kommun'
                    onChange={(e) => setKommun(e.target.value)}
                    value={kommun ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='region'>Region</InputLabel>
                  <OutlinedInput
                    id='region'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Region'
                    onChange={(e) => setRegion(e.target.value)}
                    value={region ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='postcode'>Postnummer</InputLabel>
                  <OutlinedInput
                    id='postcode'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Postnummer'
                    onChange={(e) => setPostalCode(e.target.value)}
                    value={postalCode ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='country'>Land</InputLabel>
                  <OutlinedInput
                    id='country'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Land'
                    onChange={(e) => setCountry(e.target.value)}
                    value={country ?? ''}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='geo-position'>Geoposition</InputLabel>
                  <OutlinedInput
                    id='geo-position'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <PlaceIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Geoposition'
                    value={marker ? `${marker.lat.toString()} , ${marker.lng.toString()}` : ''}
                  />
                </FormControl>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<PlaceIcon />}
                  fullWidth
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    setMarkerFromAddress(`${streetAddress} ${city} ${country} ${postalCode}`);
                  }}
                  disabled={fetchingGeoposition || !allAddressComponentsPresent || geopositionIsFetched}
                >
                  {fetchingGeoposition ? <CircularProgress size={'1.5rem'} /> : 'Lägg till geoposition från address'}
                </Button>
                <Typography variant='caption'>Välj geoposition i kartan</Typography>
                <GenericMap
                  mapContainerStyle={{ width: '100%', height: '325px' }}
                  onClick={(e) => {
                    if (e && e.latLng?.lat() && e.latLng?.lng()) {
                      setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    }
                  }}
                  marker={marker}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading || !isFormValid}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateOrganisation;
