import { Box, IconButton, InputAdornment, List, ListItem, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import PlusIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/RemoveCircle';

type Props = { onChange: (inputs: string[]) => void; initialValues: string[]; limit?: number; label: string };
const FreeTextListInput = (props: Props) => {
  const [items, setItems] = useState<string[]>(props.initialValues);
  const [itemToAdd, setItemToAdd] = useState<string>('');
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

  useEffect(() => {
    setItems(props.initialValues);
  }, [props.initialValues]);

  useEffect(() => {
    if (shouldUpdate) {
      props.onChange(items);
      setShouldUpdate(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, shouldUpdate]);

  const addItem = useCallback(
    () => {
      setItems([...items, itemToAdd.trim()]);
      setItemToAdd('');
      setShouldUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, itemToAdd]
  );

  const removeItem = useCallback(
    (index: number) => {
      const updated = [...items];
      updated.splice(index, 1);
      setItems(updated);
      setShouldUpdate(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <Box>
      <TextField
        fullWidth
        label={props.label}
        value={itemToAdd}
        disabled={props.limit !== undefined && items.length >= props.limit}
        onChange={(e) => setItemToAdd(e.target.value)}
        InputProps={{
          startAdornment:
            props.limit !== undefined ? (
              <InputAdornment position='start'>{`${items?.length ?? 0}/${props.limit}`}</InputAdornment>
            ) : undefined,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                disabled={itemToAdd.trim() === '' || items?.includes(itemToAdd.trim())}
                onClick={() => addItem()}
              >
                <PlusIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <List>
        {items.map((item, index) => {
          return (
            <ListItem
              key={`${item}-${index}`}
              secondaryAction={
                <IconButton onClick={() => removeItem(index)}>
                  <DeleteIcon />
                </IconButton>
              }
            >
              {item}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default FreeTextListInput;
