import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
  Tooltip
} from '@mui/material';
import { DataGrid, GridCellEditCommitParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { GroupCreateView, GroupReadView } from '../../types/types';
import { CancelToken } from '../../http/http';
import { AxiosError, Canceler, isCancel } from 'axios';
import { useGlobalState } from '../../context/GlobalState';
import HelpIcon from '@mui/icons-material/Help';
import SaveIcon from '@mui/icons-material/Save';
import DnDFileInput from '../common/DnDFileInput';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { toast } from 'react-toastify';
import { ChromePicker, ColorResult } from 'react-color';
import CircleIcon from '@mui/icons-material/Circle';
import { createGroup, deleteGroup, getGroups, updateGroup } from '../../services/Groups';

const SystemCategories = () => {
  const [groupList, setGroupList] = useState<GroupReadView[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const globalState = useGlobalState();
  const [reload, setReload] = useState(false);
  const [uploadingIcon, setUploadingIcon] = useState<number>();
  const [uploadingLogo, setUploadingLogo] = useState<number>();
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [updatedGroups, setUpdatedGroups] = useState<number[]>([]);
  const [deletingGroup, setDeletingGroup] = useState<number>();
  const [addingNew, setAddingNew] = useState<GroupCreateView>();
  const [newGroupIcon, setNewGroupIcon] = useState<string | null>(null);
  const [newGroupLogo, setNewGroupLogo] = useState<string | null>(null);
  const [themeColorPickerAnchor, setThemeColorPickerAnchor] = useState<HTMLElement | null>(null);
  const [pickingThemeColor, setPickingThemeColor] = useState<number>();
  const [selectedThemeColor, setSelectedThemeColor] = useState<string>();

  const addUpdatedGroup = useCallback(
    (id: number) => {
      if (!updatedGroups.includes(id)) {
        updatedGroups.push(id);
      }
    },
    [updatedGroups]
  );

  /**
   * Add a category to the list. Updates old one if present.
   */
  const addGroupToList = (newGroup: GroupReadView) => {
    let added = false;
    const updatedList = groupList.map((group) => {
      if (group.id === newGroup.id) {
        added = true;
        return newGroup;
      } else return group;
    });
    if (!added) {
      updatedList.push(newGroup);
    }
    setGroupList(updatedList);
  };

  const revertChanges = async () => {
    setUpdatedGroups([]);
    const { records } = await getGroups();
    setGroupList(records);
  };

  const saveChanges = async () => {
    if (updatedGroups.length === 0) return;
    setLoading(true);
    // call update
    updatedGroups
      .filter((id) => id !== -1)
      .map((id) => groupList.find((group) => group.id === id))
      .forEach(async (updatedCategory) => {
        if (updatedCategory) {
          const { id, ...data } = updatedCategory;
          await updateGroup(id, data);
        }
      });
    setUpdatedGroups([]);
    toast.success('Ändringar sparades');
  };

  const themeColorColumn: GridColDef = {
    field: 'themeColor',
    headerName: 'Tillhörighetsfärg',
    align: 'center',
    sortable: false,
    hideable: false,
    flex: 0.2,
    renderCell: (params) => (
      <Tooltip title='VÄLJ FÄRG'>
        <Button
          startIcon={<CircleIcon sx={{ color: groupList.find((cat) => cat.id === params.id)?.themeColor }} />}
          onClick={(e) => handleThemeColorPicker(params.id as number, e.currentTarget)}
        >
          {`${groupList.find((cat) => cat.id === params.id)?.themeColor}`}
        </Button>
      </Tooltip>
    )
  };

  const iconColumn: GridColDef = {
    field: 'icon',
    headerName: 'Tillhörighetikon',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Tooltip title='Click to edit' onClick={() => setUploadingIcon(params.row.id)}>
          <img src={params.row.icon} alt='group map icon' style={{ maxHeight: '90%', maxWidth: '90%' }} />
        </Tooltip>
      );
    }
  };

  const logoColumn: GridColDef = {
    field: 'logo',
    headerName: 'Logotyp',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    flex: 0.2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Tooltip title='Click to edit' onClick={() => setUploadingLogo(params.row.id)}>
          <img src={params.row.logo} alt='group logo' style={{ maxHeight: '90%', maxWidth: '90%' }} />
        </Tooltip>
      );
    }
  };

  const rowControlsColumn: GridColDef = {
    field: 'controls',
    headerName: '',
    sortable: false,
    flex: 0.15,
    align: 'center',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <ButtonGroup>
          <Tooltip title='Ta bort tillhörigheten'>
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                setDeletingGroup(params.id as number);
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
          <Tooltip title='Kopiera länken till urklipp'>
            <Button variant='contained' color='success' onClick={() => copyToClipBoard(params.row.shortName)}>
              <ContentCopyIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      );
    }
  };

  const nameColumn = { field: 'name', headerName: 'Tillhörighetnamn', sortable: false, editable: true, flex: 0.4 };

  const shortNameColumn = { field: 'shortName', headerName: 'Länknamn', sortable: false, editable: true, flex: 0.4 };

  const handleThemeColorPicker = useCallback(
    (id: number, anchor: HTMLElement) => {
      const group = groupList.find((cat) => cat.id === id);
      // toggle color picking for the given element.
      setSelectedThemeColor(group?.themeColor);
      setPickingThemeColor((prev) => (prev === id ? undefined : id));
      setThemeColorPickerAnchor(anchor);
    },
    [groupList]
  );

  const closeThemeColorPicker = useCallback(() => {
    setPickingThemeColor(undefined);
    setThemeColorPickerAnchor(null);
  }, []);

  const saveThemeColor = useCallback(() => {
    if (pickingThemeColor !== undefined && selectedThemeColor !== undefined) {
      addUpdatedGroup(pickingThemeColor);
      setGroupList((prev) =>
        prev.map((group) => {
          if (group.id === pickingThemeColor) {
            group.themeColor = selectedThemeColor;
          }
          return group;
        })
      );
    }
    closeThemeColorPicker();
  }, [pickingThemeColor, addUpdatedGroup, closeThemeColorPicker, selectedThemeColor]);

  useEffect(() => {
    let mounted: boolean = true;
    let cancelRequest: Canceler;
    (async () => {
      try {
        setLoading(true);

        const { records } = await getGroups({
          cancelToken: new CancelToken((executer) => (cancelRequest = executer))
        });
        if (mounted) {
          setGroupList(records);
        }
      } catch (e) {
        if (!isCancel(e)) globalState.handleResponseError(e as AxiosError);
      } finally {
        if (mounted) setLoading(false);
        setUpdatedGroups([]);
      }
      return () => {
        if (cancelRequest) cancelRequest();
        mounted = false;
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const fillGroupField = (group: GroupReadView, params: GridCellEditCommitParams): boolean => {
    switch (params.field) {
      case 'name': {
        if (params.value === group.name) return false;
        group.name = params.value;
        break;
      }
      case 'shortName': {
        if (params.value === group.name) return false;
        group.shortName = params.value;
        break;
      }
      case 'icon': {
        if (params.value === group.icon) return false;
        group.icon = params.value;
        break;
      }
      case 'logo': {
        if (params.value === group.logo) return false;
        group.logo = params.value;
        break;
      }
      case 'themeColor': {
        if (params.value === group.themeColor) return false;
        group.themeColor = params.value;
        break;
      }
    }
    return true;
  };

  const isValid = (group: GroupCreateView): boolean => {
    return (
      group.icon !== undefined &&
      group.icon !== '' &&
      group.name !== undefined &&
      group.name !== '' &&
      group.shortName !== undefined &&
      group.shortName !== '' &&
      group.themeColor !== undefined
    );
  };

  const allValid = !groupList.map(isValid).includes(false);

  const cellValid = (field: string, value: any) => {
    switch (field) {
      case 'name': {
        return value && value.trim() !== '';
      }
      case 'shortName': {
        return value && value.trim() !== '';
      }
      case 'icon': {
        return value && value.trim() !== '';
      }
      case 'logo': {
        return value && value.trim() !== '';
      }
      case 'themeColor': {
        return value !== undefined;
      }
      default:
        return true;
    }
  };

  const copyToClipBoard = (linkName: string) => {
    const link = `${window.location.origin}/group/${linkName}`;
    navigator.clipboard.writeText(link);
  };

  return (
    <Box className='categoryDataGridContainer'>
      <DataGrid
        sx={{
          '& .RaDatagrid-clickableRow': { cursor: 'pointer' },
          width: '100%'
        }}
        rows={groupList ?? []}
        columns={[nameColumn, shortNameColumn, themeColorColumn, iconColumn, logoColumn, rowControlsColumn]}
        loading={loading}
        getRowId={(group: GroupReadView) => {
          return group.id;
        }}
        rowsPerPageOptions={[25, 50, 100]}
        disableSelectionOnClick
        keepNonExistentRowsSelected
        getCellClassName={(params) => {
          return cellValid(params.field, params.value) ? '' : 'categoryCellInvalid';
        }}
        onCellEditCommit={(params) => {
          if (!cellValid(params.field, params.value)) {
            return;
          }
          const group = groupList.find((group) => group.id === Number(params.id));
          if (group) {
            const didChange = fillGroupField(group, params);
            if (!didChange) {
              return;
            }
            const updatedList = groupList.map((cat) => {
              if (cat.id === group.id) {
                return group;
              } else {
                return cat;
              }
            });
            addUpdatedGroup(group.id);
            setGroupList(updatedList);
          }
        }}
      />
      <Dialog open={deletingGroup !== undefined} onClose={() => setDeletingGroup(undefined)}>
        <DialogTitle id='delete-category-title'>
          <DialogContentText id='alert-dialog-description'>
            Vill du ta bort tillhörigheten?
            <span style={{ fontWeight: 'bold' }}>{groupList.find((cat) => cat.id === deletingGroup)?.name}</span>
          </DialogContentText>
        </DialogTitle>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={() => setDeletingGroup(undefined)}
          >
            Avbryt
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<DeleteIcon />}
            onClick={async () => {
              if (deletingGroup) {
                await deleteGroup(deletingGroup);
                setGroupList(groupList.filter((cat) => cat.id !== deletingGroup));
                setDeletingGroup(undefined);
              }
            }}
          >
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
      {pickingThemeColor && (
        <Popover
          open={pickingThemeColor !== undefined}
          anchorEl={themeColorPickerAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={closeThemeColorPicker}
        >
          <ChromePicker
            disableAlpha={true}
            color={selectedThemeColor}
            onChange={(result: ColorResult) => {
              setSelectedThemeColor(result.hex);
            }}
          />
          <ButtonGroup fullWidth>
            <Button startIcon={<SaveIcon />} onClick={saveThemeColor}>
              {'Spara'}
            </Button>
            <Button startIcon={<ClearIcon />} onClick={closeThemeColorPicker}>
              {'Avbryt'}
            </Button>
          </ButtonGroup>
        </Popover>
      )}
      {addingNew && (
        <Dialog open={addingNew !== undefined} onClose={() => setAddingNew(undefined)}>
          <DialogTitle id='new-category-title'>{'Skapa ny tillhörighet'}</DialogTitle>
          <DialogContent>
            <Grid container justifyContent={'flex-start'} spacing={3} padding={2} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='group-name'>Tillhörighetnamn</InputLabel>
                  <OutlinedInput
                    id='group-name'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <DriveFileRenameOutlineIcon />
                      </InputAdornment>
                    }
                    label='Tillhörighetnamn'
                    onChange={(e) => setAddingNew({ ...addingNew, name: e.target.value })}
                    value={addingNew.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='group-short-name'>
                    {
                      'Länknamn' // TODO translate}
                    }
                  </InputLabel>
                  <OutlinedInput
                    id='group-short-name'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <Tooltip
                          title='Länken för att kunna öppna tillhörigheten. Till exempel narvaror.se/group/namn' // TODO translate
                          enterDelay={300}
                          placement='bottom-end'
                        >
                          <HelpIcon />
                        </Tooltip>
                      </InputAdornment>
                    }
                    label='Kortnamn (for URL)'
                    onChange={(e) => setAddingNew({ ...addingNew, shortName: e.target.value })}
                    value={addingNew.shortName}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DnDFileInput
                  onFileChange={setNewGroupIcon}
                  text='Ladda upp tillhörighetikon'
                  accept={{ 'image/*': ['.jpg', '.jpeg', '.svg', '.png'] }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DnDFileInput
                  onFileChange={setNewGroupLogo}
                  text='Ladda upp logotyp'
                  accept={{ 'image/*': ['.jpg', '.jpeg', '.svg', '.png'] }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button
              color='info'
              variant='contained'
              startIcon={<SaveIcon />}
              disabled={
                addingNew === undefined ||
                addingNew.name === undefined ||
                addingNew.shortName === undefined ||
                newGroupIcon === null ||
                newGroupLogo === null
              }
              onClick={async () => {
                try {
                  const group = { ...addingNew };
                  group.icon = newGroupIcon ?? '';
                  group.logo = newGroupLogo ?? '';
                  if (isValid(group)) {
                    const result = await createGroup(group);
                    if (result) {
                      addGroupToList(result);
                    }
                  }
                } catch (e) {
                  toast.error('Ett fel inträffade');
                } finally {
                  setAddingNew(undefined);
                  setNewGroupIcon(null);
                  setNewGroupLogo(null);
                }
              }}
            >
              SPARA
            </Button>
            <Button
              color='error'
              variant='contained'
              startIcon={<ClearIcon />}
              onClick={async () => {
                setNewGroupIcon(null);
                setNewGroupLogo(null);
                setAddingNew(undefined);
              }}
            >
              AVBRYT
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={uploadingIcon !== undefined} onClose={() => setUploadingIcon(undefined)}>
        <DialogTitle id='upload-icon-title'>{'Upload icon'}</DialogTitle>
        <DialogContent>
          <DnDFileInput
            onFileChange={(file) => setUploadedFile(file)}
            text='Välj ett tillhörighetikon'
            accept={{ 'image/*': ['.jpg', '.jpeg', '.svg', '.png'] }}
          ></DnDFileInput>
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<SaveIcon />}
            onClick={async () => {
              if (groupList && uploadedFile && uploadingIcon) {
                const group = groupList.find((group) => group.id === uploadingIcon);
                if (group) {
                  addGroupToList({ ...group, icon: uploadedFile });
                }
                addUpdatedGroup(uploadingIcon);
                setUploadedFile(null);
                setUploadingIcon(undefined);
              }
            }}
          >
            UPPDATERA
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={async () => {
              setUploadedFile(null);
              setUploadingIcon(undefined);
            }}
          >
            Ångra
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={uploadingLogo !== undefined} onClose={() => setUploadingLogo(undefined)}>
        <DialogTitle id='upload-logo-title'>{'Upload logo'}</DialogTitle>
        <DialogContent>
          <DnDFileInput
            onFileChange={(file) => setUploadedFile(file)}
            text='Välj ett logotyp'
            accept={{ 'image/*': ['.jpg', '.jpeg', '.svg', '.png'] }}
          ></DnDFileInput>
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<SaveIcon />}
            onClick={async () => {
              if (groupList && uploadedFile && uploadingLogo) {
                const group = groupList.find((group) => group.id === uploadingLogo);
                if (group) {
                  addGroupToList({ ...group, logo: uploadedFile });
                }
                addUpdatedGroup(uploadingLogo);
                setUploadedFile(null);
                setUploadingLogo(undefined);
              }
            }}
          >
            UPPDATERA
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={async () => {
              setUploadedFile(null);
              setUploadingIcon(undefined);
              setUploadingLogo(undefined);
            }}
          >
            Ångra
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
        <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<AddIcon />}
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={async () =>
              setAddingNew({
                name: '',
                shortName: '',
                icon: '',
                logo: '',
                themeColor: '#000000'
              })
            }
            disabled={loading}
          >
            {loading ? <CircularProgress size={'1.5rem'} /> : 'Skapa ny tillhörighet'}
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<SaveIcon />}
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={async () =>
              saveChanges()
                .then(() => {
                  toast.success('Ändringar sparades');
                  setReload((prev) => !prev);
                })
                .catch((reason) => {
                  toast.error('Ett fel inträffade');
                })
            }
            disabled={loading || updatedGroups.length === 0 || !allValid}
          >
            {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
          <Button
            color='error'
            variant='contained'
            startIcon={<UndoIcon />}
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={revertChanges}
            disabled={loading || updatedGroups.length === 0}
          >
            {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SystemCategories;
