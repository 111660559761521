import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import './AdminPanelScreen.scss';
import { toast } from 'react-toastify';
import { useGlobalState } from '../../context/GlobalState';
import { AxiosError } from 'axios';
import { useAppShellContext } from '../shell/AppShellContext';
import { useNavigate } from 'react-router-dom';
import HeaderBackButton from '../common/HeaderBackButton';
import { createUser } from '../../services/Users';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import { isEmail } from '../../utils/HelperFunctions';

const CreateUser = () => {
  const globalState = useGlobalState();
  const appShellContext = useAppShellContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const addNewUser = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!email || !password || email.trim() === '' || password.trim() === '') {
      toast.error('Vänligen fyll i uppgifterna');
      return;
    }
    if (!isEmail(email)) {
      toast.error('E-post är inte giltig');
      return;
    }
    try {
      setLoading(true);
      const data = await createUser({ email, password });
      toast.success(`Användaren ${email} med id:${data.id} har skapats!`);
      navigate('/admin/users', { replace: true });
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    appShellContext.setHeaderLeft(<HeaderBackButton onClick={() => navigate('/admin/users', { replace: true })} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className='createOrganisationMainContainer'>
      <Box>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Ny användare
        </Typography>
      </Box>
      <form onSubmit={(e) => addNewUser(e)}>
        <Grid container justifyContent={'flex-start'} spacing={3} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl variant='outlined' required fullWidth>
              <InputLabel htmlFor='user-email-input'>E-post</InputLabel>
              <OutlinedInput
                id='user-email-input'
                type='email'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end'>
                      <EmailIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='E-post'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl variant='outlined' fullWidth required>
              <InputLabel htmlFor='user-password-input'>Lösenord</InputLabel>
              <OutlinedInput
                id='user-password-input'
                type='text'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton edge='end'>
                      <KeyIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label='Lösenord'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              variant='contained'
              color='secondary'
              startIcon={<SaveIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              type='submit'
              disabled={loading}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <Button
              color='error'
              variant='contained'
              startIcon={<CloseIcon />}
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setEmail(undefined);
                setPassword(undefined);
                navigate('/admin/users', { replace: true });
              }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={'1.5rem'} /> : 'Avbryt'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateUser;
