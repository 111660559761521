import { Divider, Grid, Skeleton } from '@mui/material';

const InfoBoxSkeleton = (props: { isMobileDevice: boolean }) => {
  return (
    <Grid container spacing={2} sx={{ bgcolor: 'white', padding: '1rem' }}>
      <Grid item xs={12}>
        <Skeleton variant='text' sx={{ fontSize: '2rem' }} />
      </Grid>
      <Divider />
      <Grid item xs={8}>
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton
          variant='rectangular'
          width={props.isMobileDevice ? '6rem' : '9rem'}
          height={props.isMobileDevice ? '6rem' : '9rem'}
        />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='rounded' width={'80%'} height={'2rem'} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='rounded' width={'80%'} height={'2rem'} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant='rounded' width={'80%'} height={'2rem'} />
      </Grid>
    </Grid>
  );
};

export default InfoBoxSkeleton;
