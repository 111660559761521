import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
  Tooltip
} from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridCellEditCommitParams,
  GridColDef,
  GridInputSelectionModel,
  GridRenderCellParams,
  GridSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { ClassifierCreateView, ClassifierReadView } from '../../types/types';
import { createClassifier, deleteClassifier, getClassifiers, updateClassifier } from '../../services/Classifier';
import { CancelToken } from '../../http/http';
import { AxiosError, Canceler, isCancel } from 'axios';
import { useGlobalState } from '../../context/GlobalState';
import { UploadFile } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import DnDFileInput from '../common/DnDFileInput';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { toast } from 'react-toastify';
import { reorder } from '../../utils/HelperFunctions';
import { ChromePicker, ColorResult } from 'react-color';
import CircleIcon from '@mui/icons-material/Circle';

const SystemCategories = () => {
  const [categoryList, setCategoryList] = useState<ClassifierReadView[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const globalState = useGlobalState();
  const [reload, setReload] = useState(false);
  const [uploadingIcon, setUploadingIcon] = useState<number>();
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [updatedCategories, setUpdatedCategories] = useState<number[]>([]);
  const [deletingCategory, setDeletingCategory] = useState<number>();
  const [addingNew, setAddingNew] = useState<ClassifierCreateView>();
  const [newCategoryIcon, setNewCategoryIcon] = useState<string | null>(null);
  const [colorPickerAnchor, setColorPickerAnchor] = useState<HTMLElement | null>(null);
  const [pickingColor, setPickingColor] = useState<number>();
  const [selectedColor, setSelectedColor] = useState<string>();

  const addUpdatedCategory = useCallback(
    (id: number) => {
      if (!updatedCategories.includes(id)) {
        updatedCategories.push(id);
      }
    },
    [updatedCategories]
  );

  /**
   * Add a category to the list. Updates old one if present.
   */
  const addCategoryToList = (newCategory: ClassifierReadView) => {
    let added = false;
    const updatedList = categoryList.map((category) => {
      if (category.id === newCategory.id) {
        added = true;
        return newCategory;
      } else return category;
    });
    if (!added) {
      updatedList.push(newCategory);
    }
    setCategoryList(updatedList);
  };

  const revertChanges = async () => {
    setUpdatedCategories([]);
    const { records } = await getClassifiers();
    setCategoryList(records.filter((classifier) => classifier.type !== 'GROUP'));
  };

  const saveChanges = async () => {
    if (updatedCategories.length === 0) return;
    setLoading(true);
    // call update
    updatedCategories
      .map((id) => categoryList.find((category) => category.id === id))
      .forEach(async (updatedCategory) => {
        if (updatedCategory) {
          const { id, ...data } = updatedCategory;
          await updateClassifier(id, data);
        }
      });
    setUpdatedCategories([]);
    toast.success('Ändringar sparades');
  };

  const getItemIndex = useCallback(
    (id: number) => {
      return categoryList.findIndex((cat) => cat.id === id);
    },
    [categoryList]
  );

  const updateOrder = useCallback(
    (newList: ClassifierReadView[]) => {
      for (let i = 0; i < newList.length; i++) {
        newList[i].priority = i;
        if (categoryList[i].id !== newList[i].id) {
          addUpdatedCategory(newList[i].id);
        }
      }
      setCategoryList(newList);
    },
    [categoryList, addUpdatedCategory]
  );

  const moveItemUp = useCallback(
    (id: number) => {
      const index = getItemIndex(id);
      updateOrder(reorder(categoryList, index, index - 1));
    },
    [categoryList, getItemIndex, updateOrder]
  );

  const moveItemDown = useCallback(
    (id: number) => {
      const index = getItemIndex(id);
      updateOrder(reorder(categoryList, index, index + 1));
    },
    [categoryList, getItemIndex, updateOrder]
  );

  const renderIconCell = (params: GridRenderCellParams) => {
    return <img src={params.row.icon} alt='category icon' style={{ maxHeight: '90%', maxWidth: '90%' }} />;
  };

  const renderEditIconCell = (params: GridRenderCellParams) => {
    return (
      <IconButton onClick={() => setUploadingIcon(params.row.id)}>
        <UploadFile />
      </IconButton>
    );
  };

  const uploadIconColumn: GridColDef = {
    field: 'upload',
    headerName: '',
    align: 'center',
    sortable: false,
    hideable: false,
    renderCell: renderEditIconCell
  };

  const iconColorColumn: GridColDef = {
    field: 'color',
    headerName: 'Kategorifärg',
    align: 'center',
    sortable: false,
    hideable: false,
    flex: 0.2,
    renderCell: (params) => (
      <Tooltip title='VÄLJ FÄRG'>
        <Button
          startIcon={<CircleIcon sx={{ color: categoryList.find((cat) => cat.id === params.id)?.color }} />}
          onClick={(e) => handleColorPicker(params.id as number, e.currentTarget)}
        >
          {`${categoryList.find((cat) => cat.id === params.id)?.color}`}
        </Button>
      </Tooltip>
    )
  };

  const iconColumn: GridColDef = {
    field: 'icon',
    headerName: 'Kategorikon',
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: renderIconCell
  };

  const deleteActionColumn: GridColDef = {
    field: 'deleteAction',
    headerName: '',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <IconButton
          onClick={() => {
            setDeletingCategory(params.id as number);
          }}
        >
          <DeleteIcon />
        </IconButton>
      );
    }
  };

  const nameColumn = { field: 'name', headerName: 'Kategorinamn', sortable: false, editable: true, flex: 1 };

  const reorderColumn: GridColDef = {
    field: 'reorderAction',
    headerName: '',
    sortable: false,
    editable: false,
    align: 'center',
    renderCell: (params) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            onClick={() => {
              if (getItemIndex(params.row.id) !== 0) moveItemUp(params.row.id);
            }}
            style={{ marginBottom: '-10px' }}
          >
            <ArrowDropUpIcon />
          </div>
          <div
            onClick={() => {
              if (getItemIndex(params.row.id) < categoryList.length - 1) moveItemDown(params.row.id);
            }}
          >
            <ArrowDropDownIcon />
          </div>
        </div>
      );
    }
  };

  const handleColorPicker = useCallback(
    (id: number, anchor: HTMLElement) => {
      // toggle color picking for the given element.
      setSelectedColor(categoryList.find((cat) => cat.id === id)?.color);
      setPickingColor((prev) => (prev === id ? undefined : id));
      setColorPickerAnchor(anchor);
    },
    [categoryList]
  );

  const closeColorPicker = useCallback(() => {
    setPickingColor(undefined);
    setColorPickerAnchor(null);
    setSelectedColor(undefined);
  }, []);

  const saveColor = useCallback(() => {
    if (pickingColor !== undefined && selectedColor !== undefined) {
      addUpdatedCategory(pickingColor);
      setCategoryList((prev) =>
        prev.map((cat) => {
          if (cat.id === pickingColor) {
            cat.color = selectedColor;
          }
          return cat;
        })
      );
    }
    closeColorPicker();
  }, [pickingColor, addUpdatedCategory, closeColorPicker, selectedColor]);

  useEffect(() => {
    let mounted: boolean = true;
    let cancelRequest: Canceler;
    (async () => {
      try {
        setLoading(true);

        const { records } = await getClassifiers({
          cancelToken: new CancelToken((executer) => (cancelRequest = executer))
        });
        if (mounted) {
          setCategoryList(records.filter((classifier) => classifier.type !== 'GROUP'));
        }
      } catch (e) {
        if (!isCancel(e)) globalState.handleResponseError(e as AxiosError);
      } finally {
        if (mounted) setLoading(false);
        setUpdatedCategories([]);
      }
      return () => {
        if (cancelRequest) cancelRequest();
        mounted = false;
      };
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const fillCategoryField = (category: ClassifierReadView, params: GridCellEditCommitParams): boolean => {
    switch (params.field) {
      case 'name': {
        if (params.value === category.name) return false;
        category.name = params.value;
        break;
      }
      case 'icon': {
        if (params.value === category.icon) return false;
        category.icon = params.value;
        break;
      }
      case 'type': {
        if (params.value === category.type) return false;
        category.type = params.value;
        break;
      }
      case 'priority': {
        if (params.value === category.priority) return false;
        category.priority = params.value;
        break;
      }
      case 'color': {
        if (params.value === category.color) return false;
        category.color = params.value;
        break;
      }
    }
    return true;
  };

  const isValid = (category: ClassifierReadView): boolean => {
    return (
      category.icon !== undefined &&
      category.icon !== '' &&
      category.name !== undefined &&
      category.name !== '' &&
      category.type !== undefined &&
      category.priority !== undefined &&
      category.color !== undefined
    );
  };

  const allValid = !categoryList.map(isValid).includes(false);

  const cellValid = (field: string, value: any) => {
    switch (field) {
      case 'name': {
        return value && value.trim() !== '';
      }
      case 'icon': {
        return value && value.trim() !== '';
      }
      case 'main': {
        return value !== undefined;
      }
      case 'priority': {
        return value;
      }
      case 'color': {
        return value !== undefined;
      }
      default:
        return true;
    }
  };

  const _selectionModel: GridInputSelectionModel = categoryList
    .filter((category) => category.type === 'CATEGORY')
    .map((category) => category.id);

  const _onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
    if (!selectionModel || !details) {
      toast.error('Something went wrong');
    }
    const ids = selectionModel.map((i) => Number(i));
    const updatedList = categoryList.map((category) => {
      if (ids.includes(category.id)) {
        if (category.type === 'SUB_CATEGORY') {
          addUpdatedCategory(category.id);
          category.type = 'CATEGORY';
        }
      } else {
        if (category.type === 'CATEGORY') {
          addUpdatedCategory(category.id);
          category.type = 'SUB_CATEGORY';
        }
      }
      return category;
    });
    setCategoryList(updatedList);
  };

  const isCategoryValid = (category: ClassifierCreateView) => {
    return category.icon && category.name && category.icon.trim() !== '' && category.name.trim() !== '';
  };

  return (
    <Box className='categoryDataGridContainer'>
      <DataGrid
        sx={{
          '& .RaDatagrid-clickableRow': { cursor: 'pointer' },
          width: '100%'
        }}
        rows={categoryList ?? []}
        columns={[
          {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            type: 'string',
            renderHeader: (params) => {
              return <b>Huvudkategori</b>;
            },
            flex: 0.1
          },
          nameColumn,
          iconColorColumn,
          iconColumn,
          uploadIconColumn,
          reorderColumn,
          deleteActionColumn
        ]}
        loading={loading}
        getRowId={(category: ClassifierReadView) => {
          return category.id;
        }}
        rowsPerPageOptions={[25, 50, 100]}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={_selectionModel}
        onSelectionModelChange={_onSelectionModelChange}
        keepNonExistentRowsSelected
        getCellClassName={(params) => {
          return cellValid(params.field, params.value) ? '' : 'categoryCellInvalid';
        }}
        onCellEditCommit={(params) => {
          if (!cellValid(params.field, params.value)) {
            return;
          }
          const category = categoryList.find((category) => category.id === Number(params.id));
          if (category) {
            const didChange = fillCategoryField(category, params);
            if (!didChange) {
              return;
            }
            const updatedList = categoryList.map((cat) => {
              if (cat.id === category.id) {
                return category;
              } else {
                return cat;
              }
            });
            addUpdatedCategory(category.id);
            setCategoryList(updatedList);
          }
        }}
      />
      <Dialog open={deletingCategory !== undefined} onClose={() => setDeletingCategory(undefined)}>
        <DialogTitle id='delete-category-title'>
          <DialogContentText id='alert-dialog-description'>
            Vill du ta bort kategorin
            <span style={{ fontWeight: 'bold' }}>{categoryList.find((cat) => cat.id === deletingCategory)?.name}</span>
          </DialogContentText>
        </DialogTitle>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={() => setDeletingCategory(undefined)}
          >
            Avbryt
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<DeleteIcon />}
            onClick={async () => {
              if (deletingCategory) {
                await deleteClassifier(deletingCategory);
                setCategoryList(categoryList.filter((cat) => cat.id !== deletingCategory));
                setDeletingCategory(undefined);
              }
            }}
          >
            Ta bort
          </Button>
        </DialogActions>
      </Dialog>
      ;
      {pickingColor && (
        <Popover
          open={pickingColor !== undefined}
          anchorEl={colorPickerAnchor}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={closeColorPicker}
        >
          <ChromePicker
            disableAlpha={true}
            color={selectedColor}
            onChange={(result: ColorResult) => {
              setSelectedColor(result.hex);
            }}
          />
          <ButtonGroup fullWidth>
            <Button startIcon={<SaveIcon />} onClick={saveColor}>
              {'Spara'}
            </Button>
            <Button startIcon={<ClearIcon />} onClick={closeColorPicker}>
              {'Avbryt'}
            </Button>
          </ButtonGroup>
        </Popover>
      )}
      {addingNew && (
        <Dialog open={addingNew !== undefined} onClose={() => setAddingNew(undefined)}>
          <DialogTitle id='new-category-title'>{'Skapa ny kategori'}</DialogTitle>
          <DialogContent>
            <Grid container justifyContent={'flex-start'} spacing={3} padding={2} sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl variant='outlined' required fullWidth>
                  <InputLabel htmlFor='category-name'>Kategorinamn</InputLabel>
                  <OutlinedInput
                    id='category-name'
                    type='text'
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton edge='end'>
                          <DriveFileRenameOutlineIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label='Kategorinamn'
                    onChange={(e) => setAddingNew({ ...addingNew, name: e.target.value })}
                    value={addingNew.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DnDFileInput
                  onFileChange={setNewCategoryIcon}
                  text='Ladda upp kategorikon'
                  accept={{ 'image/*': ['.jpg', '.jpeg', '.svg', '.png'] }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
            <Button
              color='info'
              variant='contained'
              startIcon={<SaveIcon />}
              onClick={async () => {
                try {
                  const category = { ...addingNew };
                  category.icon = newCategoryIcon ?? '';
                  category.priority = categoryList.length;
                  category.type = 'SUB_CATEGORY';
                  if (isCategoryValid(category)) {
                    const result = await createClassifier(category);
                    if (result) {
                      addCategoryToList(result);
                    }
                  }
                } catch (e) {
                  toast.error('Ett fel inträffade');
                } finally {
                  setAddingNew(undefined);
                }
              }}
            >
              SPARA
            </Button>
            <Button
              color='error'
              variant='contained'
              startIcon={<ClearIcon />}
              onClick={async () => {
                setNewCategoryIcon(null);
                setAddingNew(undefined);
              }}
            >
              AVBRYT
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={uploadingIcon !== undefined} onClose={() => setUploadingIcon(undefined)}>
        <DialogTitle id='upload-icon-title'>{'Upload icon'}</DialogTitle>
        <DialogContent>
          <DnDFileInput
            onFileChange={(file) => setUploadedFile(file)}
            text='Välj ett kategorikon'
            accept={{ 'image/*': ['.jpg', '.jpeg', '.svg', '.png'] }}
          ></DnDFileInput>
        </DialogContent>
        <DialogActions sx={{ flex: 1, justifyContent: 'space-around' }}>
          <Button
            color='info'
            variant='contained'
            startIcon={<SaveIcon />}
            onClick={async () => {
              if (categoryList && uploadedFile && uploadingIcon) {
                const category = categoryList.find((cat) => cat.id === uploadingIcon);
                if (category) {
                  addCategoryToList({ ...category, icon: uploadedFile });
                }
                addUpdatedCategory(uploadingIcon);
                setUploadedFile(null);
                setUploadingIcon(undefined);
              }
            }}
          >
            UPPDATERA
          </Button>
          <Button
            color='error'
            variant='contained'
            startIcon={<ClearIcon />}
            onClick={async () => {
              setUploadedFile(null);
              setUploadingIcon(undefined);
            }}
          >
            Ångra
          </Button>
        </DialogActions>
      </Dialog>{' '}
      <Grid container justifyContent={'center'} spacing={3} sx={{ flexGrow: 1, mt: '3rem' }}>
        <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<AddIcon />}
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={async () =>
              setAddingNew({
                name: '',
                icon: '',
                priority: -1,
                type: 'SUB_CATEGORY',
                color: '#000000'
              })
            }
            disabled={loading}
          >
            {loading ? <CircularProgress size={'1.5rem'} /> : 'Skapa ny kategori'}
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<SaveIcon />}
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={async () => saveChanges().then(() => setReload((prev) => !prev))}
            disabled={loading || updatedCategories.length === 0 || !allValid}
          >
            {loading ? <CircularProgress size={'1.5rem'} /> : 'Spara'}
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
          <Button
            color='error'
            variant='contained'
            startIcon={<UndoIcon />}
            fullWidth
            sx={{ textTransform: 'none' }}
            onClick={revertChanges}
            disabled={loading || updatedCategories.length === 0}
          >
            {loading ? <CircularProgress size={'1.5rem'} /> : 'Ångra'}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SystemCategories;
