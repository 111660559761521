import { OrganisationDescriptionCardReadView, OrganisationReadView } from '../../../types/types';
import {
  Card,
  CardContent,
  Typography,
  Button,
  SxProps,
  Theme,
  CircularProgress,
  Box,
  Divider,
  IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import { toast } from 'react-toastify';
import { useWindowSize } from '../../../utils/HelperFunctions';
import React from 'react';

type Props = {
  organisation?: OrganisationReadView;
  descriptionCard: OrganisationDescriptionCardReadView;
  onClose: () => void;
  isMobileDevice: boolean;
  loading?: boolean;
};
const PreviewMapInfoBox = (props: Props) => {
  const { windowSize } = useWindowSize();

  return props.loading || !props.organisation ? null : props.isMobileDevice ? (
    <Card
      sx={{
        ...styles.bottomSheetCard,
        width: windowSize[0]
      }}
    >
      <CardContent>
        {props.loading ? (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : !props.loading && props.organisation ? (
          <InnerBox
            organisation={props.organisation}
            descriptionCard={props.descriptionCard}
            onClose={props.onClose}
            isMobileDevice={props.isMobileDevice}
          />
        ) : null}
      </CardContent>
    </Card>
  ) : (
    <Box
      sx={{
        backgroundColor: 'rgba(255,255,255,0)',
        width: `100%`,
        maxWidth: '100%'
      }}
    >
      <Card sx={styles.infoBoxCard}>
        <CardContent>
          {props.loading ? (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : !props.loading && props.organisation ? (
            <InnerBox
              organisation={props.organisation}
              descriptionCard={props.descriptionCard}
              onClose={props.onClose}
              isMobileDevice={props.isMobileDevice}
            />
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
};

/// _________________________________________________
type InfoBoxInnerBoxProps = {
  organisation: OrganisationReadView;
  descriptionCard: OrganisationDescriptionCardReadView;
  onClose: () => void;
  isMobileDevice: boolean;
};

const onGroupButtonClicked = (card: OrganisationDescriptionCardReadView) => {
  if (!card.bigButtonLinks || card.bigButtonLinks.length === 0) {
    toast.info('En karta har ännu inte länkats.');
  } else {
    window.open(card.bigButtonLinks[0]);
  }
};

type DescriptionCardHeaderProps = {
  organisation: OrganisationReadView;
  descriptionCard: OrganisationDescriptionCardReadView;
  isMobileDevice: boolean;
};

const DescriptionCardHeader: React.FC<DescriptionCardHeaderProps> = ({
  organisation,
  descriptionCard,
  isMobileDevice
}: DescriptionCardHeaderProps) => {
  return descriptionCard.displayName ? (
    <Typography sx={styles.headerLogo} variant='h6' component='div' style={{ fontWeight: 'bold', fontFamily: 'serif' }}>
      {descriptionCard.displayName}
    </Typography>
  ) : organisation.customUiIcon ? (
    <img
      src={organisation.customUiIcon}
      alt='organisation-custom-icon'
      style={{ maxHeight: isMobileDevice ? '1.5rem' : '2rem' }}
    />
  ) : (
    <Typography sx={styles.headerLogo} variant='h6' component='div' style={{ fontWeight: 'bold', fontFamily: 'serif' }}>
      {organisation.displayName}
    </Typography>
  );
};

export const generateMapLink = (latitude: number | null, longitude: number | null) => {
  // Format the coordinates
  const coordinates = `${latitude},${longitude}`;

  // Check for the device type
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window);
  const isAndroid = /Android/.test(navigator.userAgent);

  // Generate the appropriate map link based on the device
  if (isIOS) {
    // Apple Maps link for iOS
    return `maps://?q=${coordinates}`;
  } else if (isAndroid) {
    // Google Maps link for Android
    return `geo:${coordinates}`;
  } else {
    // Google Maps URL for desktop browsers
    return `https://maps.google.com/?q=${coordinates}`;
  }
};

const InnerBox = ({ organisation, descriptionCard, onClose, isMobileDevice }: InfoBoxInnerBoxProps) => (
  <Box sx={{ flexGrow: 1 }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <Box sx={{ flex: 1, mr: 2 }}>
        <Box>
          <DescriptionCardHeader
            organisation={organisation}
            descriptionCard={descriptionCard}
            isMobileDevice={isMobileDevice}
          />
        </Box>
        <Divider sx={{ margin: '.5rem 0' }}></Divider>
        <Typography variant={isMobileDevice ? 'caption' : 'body2'}>
          {descriptionCard.description || organisation.description || 'Ingen information tillgänglig.'}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ textAlign: 'end', pb: 1 }}>
          <IconButton sx={{ pt: 0 }} onClick={() => onClose()}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Button
          variant='contained'
          sx={{
            ...styles.groupButton,
            width: isMobileDevice ? '6rem' : '9rem',
            height: isMobileDevice ? '6rem' : '9rem'
          }}
          onClick={() => onGroupButtonClicked(descriptionCard)}
        >
          <img
            src={require('../../../utils/img/multiMarker.png')}
            alt={descriptionCard.buttonText || 'Våra leverantörer'}
            style={{ objectFit: 'contain', width: '75%', height: '75%' }}
          />
          {!isMobileDevice && (
            <Typography sx={{ fontWeight: 'bold', color: 'white', mt: 1 }}>{descriptionCard.buttonText}</Typography>
          )}
        </Button>
      </Box>
    </Box>
    <Box sx={{ flexDirection: 'row', alignItems: 'center', mt: 1.5 }}>
      {organisation.referralLink && (
        <Button
          variant='contained'
          sx={{ ...styles.footerButtons, fontSize: isMobileDevice ? '12px' : '16px' }}
          onClick={() => {
            window.open(organisation.referralLink!);
          }}
          startIcon={<HomeIcon />}
        >
          Hemsida
        </Button>
      )}
      <Button
        variant='contained'
        sx={{ ...styles.footerButtons, fontSize: isMobileDevice ? '12px' : '16px' }}
        startIcon={<MapIcon />}
        onClick={() => window.open(descriptionCard.link2 || generateMapLink(organisation.lat, organisation.lng))}
      >
        Hitta Hit
      </Button>
      {descriptionCard.link3 && descriptionCard.link3Text && (
        <Button
          variant='contained'
          sx={{ ...styles.footerButtons, fontSize: isMobileDevice ? '12px' : '16px' }}
          onClick={() => window.open(descriptionCard.link3)}
        >
          {descriptionCard.link3Text}
        </Button>
      )}
    </Box>
  </Box>
);

export default PreviewMapInfoBox;

const styles: { [key: string]: SxProps<Theme> } = {
  bottomSheetCard: {
    position: 'absolute',
    bottom: 0,
    zIndex: 999,
    maxHeight: '75%',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    overflow: 'scroll'
  },
  infoBoxCard: {
    borderRadius: '1rem',
    width: '550px',
    borderColor: 'grey',
    borderWidth: '5px'
  },
  headerLogo: { display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 },
  groupButton: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#04A9B5',
    p: 1,
    borderRadius: '1rem',
    justifyContent: 'center',
    alignItems: 'center',

    textTransform: 'none'
  },
  groupButtonDisabled: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#c9c9c9',
    p: 1,
    borderRadius: '1rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerButtons: {
    textTransform: 'none',
    color: 'white',
    bgcolor: '#04A9B5',
    mr: 1,

    borderRadius: '1rem',
    '&.Mui-disabled': {
      backgroundColor: '#c9c9c9',
      color: 'white',
      opacity: 1 // Override the default opacity
    }
  }
};
