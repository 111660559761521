import ReactGA from 'react-ga4';

const sendEvent = (eventName: string, options: any) => {
  if (process.env.NODE_ENV === 'production') ReactGA.event(eventName, options);
  if (process.env.NODE_ENV === 'development') console.log(`Sending ${eventName} event: ${JSON.stringify(options)}`);
};

export interface SelectContentOptions {
  content_type:
    | 'article_epc'
    | 'article_alternate_id'
    | 'article_custom_url'
    | 'homescreen'
    | 'organisation_url'
    | 'organisation_search'
    | 'main category select'
    | 'sub_category_select'
    | 'organisation_geotag';
  content_id: string;
}
export const selectContent = (options: SelectContentOptions) => {
  sendEvent('select_content', options);
};

export const searchEvent = (search: string) => {
  sendEvent('search', { search: search });
};
