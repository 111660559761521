import { AxiosRequestConfig } from 'axios';
import { ENDPOINTS } from '../http/endpoints';
import { http } from '../http/http';
import { AlternateIdReadView } from '../types/types';

export const getAlternateId = async (
  alternateId: string,
  config?: AxiosRequestConfig
): Promise<AlternateIdReadView> => {
  const { data } = await http.get<AlternateIdReadView>(`${ENDPOINTS.alternateId}/${alternateId}`, { ...config });
  return data;
};
