import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUrlsByOrganisationId, createUrl } from '../../../services/URLs';
import { useGlobalState } from '../../../context/GlobalState';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ArticleReadView, ExternalLink, OrganisationReadView, UrlCreateView, UrlReadView } from '../../../types/types';
import { getOrganisationById } from '../../../services/Organisations';
import { isValidCustomUrlPath, prepareOrgNameForLink } from '../../../utils/HelperFunctions';
import LinkIcon from '@mui/icons-material/Link';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getArticleById } from '../../../services/Article';
import InfoIcon from '@mui/icons-material/Info';
import { QRCodeSVG } from 'qrcode.react';
import defaultFrame from '../../../utils/img/ttQRFrame.svg';
import UrlItem from './UrlItem';
import FreeTextListInput from '../../common/FreeTextListInput';
import ExternalLinkListInput from './ExtrnalLinkListInput';

const setQrUrl = (qrObjId: string, canvasId: string, setCode: (dataUrl: string) => void): void => {
  const qrHtml = document.getElementById(qrObjId);
  if (!qrHtml) return;
  // Convert the SVG element to a data URL
  const svgData = new XMLSerializer().serializeToString(qrHtml);
  const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
  const context = canvas.getContext('2d');

  context?.clearRect(0, 0, canvas.width, canvas.height);
  // draw background on canvas,
  const bg = document.createElement('img');
  bg.src = defaultFrame;
  const qrDataURL = URL.createObjectURL(svgBlob);
  bg.onload = () => {
    // Set canvas dimensions based on background image NOTE: This will need to be tuned if we change the image or qr code size
    const scaleFactor = 0.37;
    const scaledWidth = bg.width * scaleFactor;
    const scaledHeight = bg.height * scaleFactor;
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;

    context?.drawImage(bg, 0, 0, scaledWidth, scaledHeight);

    const qrImg = new Image();
    qrImg.src = qrDataURL;

    qrImg.onload = () => {
      const qrX = scaledWidth / 2 - qrImg.width / 2;
      const qrY = 98;
      context?.drawImage(qrImg, qrX, qrY, qrImg.width, qrImg.height);
      setCode(canvas.toDataURL());
    };
  };
};

const OrganisationLinks = () => {
  const params = useParams<{ orgId: string }>();
  const globalState = useGlobalState();
  const [loading, setLoading] = useState<boolean>(false);
  const [organisation, setOrganisation] = useState<OrganisationReadView>();
  const [urls, setUrls] = useState<UrlReadView[]>([]);
  const [url, setUrl] = useState<string>('');
  const [epc, setEpc] = useState<string>('');
  const [alternateId, setAlternateId] = useState<string | null>(null);
  const [reload, setReload] = useState(false);
  const [orgQrCodeUrl, setOrgQrCodeUrl] = useState<string>();
  const [urlArticlesMap, setUrlArticlesMap] = useState<Map<number, ArticleReadView[]>>();
  const [urlArticleIds, setUrlArticleIds] = useState<number[]>([]);
  const [urlExternalLinks, setUrlExternalLinks] = useState<ExternalLink[]>([]);

  useEffect(() => {
    if (!params.orgId) {
      toast.error('Something went wrong. Please refresh the page!');
      return;
    }
    (async () => {
      try {
        const _organisation = await getOrganisationById(params.orgId!);
        setOrganisation(_organisation);
        const _data = await getUrlsByOrganisationId(Number(params.orgId));
        const urlArticleMap = new Map<number, ArticleReadView[]>();
        for (const url of _data) {
          const articles = await Promise.all(url.articleIds.map(async (id) => await getArticleById(id)));
          urlArticleMap.set(url.id, articles);
        }
        setUrlArticlesMap(urlArticleMap);
        setQrUrl('qr_org', 'canvas_org', setOrgQrCodeUrl);
        setUrls([..._data]);
      } catch (e) {
        globalState.handleResponseError(e as AxiosError);
      }
    })();
  }, [globalState, params.orgId, reload]);

  const clearInputs = () => {
    setUrl('');
    setEpc('');
    setAlternateId('');
    setUrlArticleIds([]);
    setUrlExternalLinks([]);
  };

  const addTheUrl = async () => {
    if (!isValidCustomUrlPath(url)) {
      toast.warning(' Namnet på länken är fel');
      return;
    }
    if (urlArticleIds.length === 0 && alternateId?.trim().length === 0) {
      toast.warning('Vänligen ange EPC/Artikel-ID');
      return;
    }
    setLoading(true);
    try {
      let errorOccured = false;
      for (const articleId of urlArticleIds) {
        try {
          const article = await getArticleById(articleId);
          if (article.organisationId !== organisation?.id) {
            toast.error(`Artikeln med epc ${articleId} är inte för den här organisationen`);
            setUrlArticleIds(urlArticleIds.filter((id) => id !== articleId));
            errorOccured = true;
          }
        } catch (e) {
          setUrlArticleIds(urlArticleIds.filter((id) => id !== articleId));
          toast.error(`Artikeln med epc ${articleId} finns inte.`);
          errorOccured = true;
        }
      }
      if (errorOccured) {
        return;
      }
      const link = `/map/${prepareOrgNameForLink(organisation?.name ?? 'err')}/${url}`;
      const _data = await createUrl(Number(organisation?.id), {
        url: link,
        epc,
        articleIds: urlArticleIds,
        alternateId: alternateId && alternateId !== '' ? alternateId : null,
        externalLinks: urlExternalLinks
      } as UrlCreateView);
      toast.success('Länken uppdaterad: ' + _data.url);
      setReload((prev) => !prev);
      setUrl('');
      setEpc('');
      setUrlArticleIds([]);
      setUrlExternalLinks([]);
    } catch (e) {
      globalState.handleResponseError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 1.5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant='h6'>Hantera anpassade länkar för {organisation?.name}</Typography>
        <ButtonGroup>
          <Tooltip title='kopiera organisationens länken till urklipp'>
            <Button
              variant='contained'
              color='success'
              onClick={() =>
                navigator.clipboard.writeText(
                  `${window.location.origin}/${prepareOrgNameForLink(organisation?.name ?? '')}`
                )
              }
            >
              <ContentCopyIcon />
            </Button>
          </Tooltip>
          <Tooltip title='generate qr code for link'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                const qrCode = document.getElementById(`full_img_org`);
                if (qrCode) {
                  const win = window.open();
                  if (win) {
                    const qrClone = qrCode.cloneNode() as HTMLImageElement;
                    win.document.body.appendChild(qrClone);
                    qrClone.onload = () => {
                      win.print();
                    };
                  }
                }
              }}
            >
              <QrCode2Icon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Box>

      {organisation && (
        <Box sx={{ mt: 1, mb: 1 }}>
          <Alert severity='info'>
            Alla länkar har{' '}
            <span style={{ fontStyle: 'italic', color: 'blue' }}>{`${
              window.location.origin
            }/map/${prepareOrgNameForLink(organisation.name)}/[namnet]`}</span>{' '}
            i början och du kan inte ändra det. Du ska bara ange slut<b>namnet</b>
          </Alert>

          <Divider sx={{ mb: 2, mt: 1 }}></Divider>
          <Typography variant='caption'>skapa en ny länk här</Typography>
          <Accordion>
            <AccordionSummary>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <TextField
                    fullWidth
                    label={'Namn'}
                    variant='outlined'
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <Tooltip title={`${window.location.origin}/map/${prepareOrgNameForLink(organisation.name)}/`}>
                          <InputAdornment position='start'>
                            <LinkIcon />
                          </InputAdornment>
                        </Tooltip>
                      ),
                      endAdornment: (
                        <Tooltip
                          title={
                            <p>
                              namn måste följa länkregler{' '}
                              <ul>
                                <li>endast små bokstäver</li>
                                <li>inget blanksteg</li>
                                <li>inget ä,å,ö</li>
                                <li>endast streck - för separation</li>
                              </ul>
                            </p>
                          }
                        >
                          <InputAdornment position='start'>
                            <InfoIcon fontSize='small' color='info' />
                          </InputAdornment>
                        </Tooltip>
                      )
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Tooltip title='Formatet är "alternate-XXXXXXXXXXXXXX"' placement='top'>
                    <TextField
                      fullWidth
                      label={'alternate-id'}
                      variant='outlined'
                      value={alternateId ?? ''}
                      onChange={(e) => setAlternateId(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <QrCode2Icon />
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                  <ButtonGroup fullWidth sx={{ height: '100%' }}>
                    <Button
                      variant='contained'
                      color='secondary'
                      endIcon={loading ? <CircularProgress size={'1rem'} /> : <SaveIcon />}
                      disabled={
                        loading || (url.trim().length === 0 && epc.trim().length === 0) || organisation.disabled
                      }
                      onClick={() => {
                        addTheUrl();
                      }}
                    >
                      skapa
                    </Button>
                    <Button
                      variant='contained'
                      color='error'
                      endIcon={loading ? <CircularProgress size={'1rem'} /> : <UndoIcon />}
                      disabled={
                        loading || (url.trim().length === 0 && epc.trim().length === 0) || organisation.disabled
                      }
                      onClick={() => {
                        clearInputs();
                      }}
                    >
                      klar
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FreeTextListInput
                    onChange={(inputs) => setUrlArticleIds(inputs.map(Number))}
                    initialValues={urlArticleIds.map(String)}
                    label='EPCs'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ExternalLinkListInput externalLinks={urlExternalLinks} onChange={setUrlExternalLinks} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ mt: 2 }} />
          <div hidden aria-hidden>
            <QRCodeSVG id={`qr_org`} value={`${window.location.origin}/${prepareOrgNameForLink(organisation.name)}/`} />
            <canvas id={`canvas_org`} />
            <img id={`full_img_org`} src={orgQrCodeUrl} alt='qr code' />
          </div>
        </Box>
      )}
      {organisation &&
        urls.map((url) => {
          return (
            <UrlItem
              key={`${url.id}${url.epc}`}
              urlObj={url}
              orgObj={organisation}
              articles={urlArticlesMap?.get(url.id) ?? []}
              forceReload={() => setReload((prev) => !prev)}
            />
          );
        })}
    </Box>
  );
};
export default OrganisationLinks;
