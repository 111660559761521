import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import { isMobileDevice } from '../../../utils/HelperFunctions';

const ComponentLoader = (props: { text?: string; bgColor?: string; bgImg?: string }) => (
  <Box
    sx={{
      ...componentLoaderStyles.fullCentered,
      ...(props.bgColor && { backgroundColor: props.bgColor }),
      ...(props.bgImg && {
        backgroundImage: `url(${props.bgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      })
    }}
  >
    <Box
      sx={{
        padding: '50%'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '100%',
          backgroundColor: 'white',
          padding: '1.5rem',
          bottom: isMobileDevice() ? '28%' : '25%',
          left: '50%',
          transform: 'translateX(-50%)',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress color='secondary' />
        <Typography color={'secondary'}>{props.text ?? 'Loading...'}</Typography>
      </Box>
    </Box>
  </Box>
);

export default ComponentLoader;

export const componentLoaderStyles: { [key: string]: SxProps<Theme> } = {
  fullCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#000927'
  }
};
